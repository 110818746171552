import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from '../global-service';

@Component({
  selector: 'app-admin-approval',
  templateUrl: './admin-approval.component.html',
  styleUrls: ['./admin-approval.component.css'],
})
export class AdminApprovalComponent implements OnInit {
  Roles: any;
  selectedRole: any;
  adminName: any;

  constructor(private router: Router, public global: GlobalService,
  ) {
    this.selectedRole = 'archer';

    this.Roles = [
      { label: 'Archer', value: 'archer' },
      { label: 'Team Officials', value: 'officials' },
      { label: 'Coach', value: 'coach' },
      { label: 'Technical Officials', value: 'judges' },
    ];

  }

  ngOnInit(): void {
    if (this.router.url.indexOf('officials') !== -1) {
      this.selectedRole = 'officials';
    } else if (this.router.url.indexOf('coach') !== -1) {
      this.selectedRole = 'coach';
    } else if (this.router.url.indexOf('archer') !== -1) {
      this.selectedRole = 'archer';
    } else if (this.router.url.indexOf('judges') !== -1) {
      this.selectedRole = 'judges';
    }
    const info = JSON.parse(localStorage.getItem('userInfo'));
    this.adminName = info.memberAssociation.name;
  }

  setRole(event) {
    this.router.navigate(['/admin-approval/' + event.value]);
  }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('userInfo');
    this.router.navigate(['/login']);
    localStorage.removeItem(
      'onceOpenken'
    );
  }
}
