import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// import { window } from 'rxjs/operators';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'Archer';
  constructor(private router: Router) {}
  onActivate(e, outlet) {
    window.scrollTo(0, 0);
  }

  ngOnInit() {
    if (!localStorage.getItem('token')) {
      //this.router.navigate(['/']);
    }

  }
}
