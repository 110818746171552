import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  ElementRef,
  HostListener,
} from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  FormArray,
} from "@angular/forms";
import { Router } from "@angular/router";

import { HttpClient } from "@angular/common/http";
import { GlobalService } from "../../global-service";
import { MessageService } from "primeng/api";

@Component({
  selector: "app-judges-register",
  templateUrl: "./judges-register.component.html",
  styleUrls: [
    "./judges-register.component.css",
    "../../../assets/theme/form.css",
  ],
})
export class JudgesRegisterComponent implements OnInit {
  judgeFormGroup: FormGroup;
  genderList: any;
  eventList: any;
  submitted = false;
  NIS: any = [];
  employed: any = [];
  employmentDetails: any = [];
  certificateLevel: any = [];
  errorData = false;
  imageUpload: boolean = false;
  signatureFile: any = "";
  signatureNewFile: any = "";
  signatureFormat: any;

  disableButton: boolean = false;
  StateList: any = [];

  ResumeFile: any = "";
  Resume: boolean = false;
  ResumeFormat: any;
  termsModel: boolean = false;
  checked: boolean = false;
  memberAssociation: any = [];
  eyeShow: boolean = false;
  otherBox: boolean = false;
  logSpinner: boolean = false;

  firstValidation: boolean = false;

  value: number = 0;
  signature: boolean = false;

  aadharNewFile: any = "";
  aadharFormat: any;
  aadhar: boolean = false;

  @ViewChild("stickyMenu") menuElement: ElementRef;
  sticky: boolean = false;
  elementPosition: any;

  mainValidation: boolean = false;
  mainMessage: any;
  applicantData: any;
  emailVerificationFormGroup:FormGroup;
  emailVerifyModel: boolean = false;
  codeSubmited:boolean = false;
  isEmailVerified:boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    public global: GlobalService,
    private messageService: MessageService,
    private http: HttpClient
  ) {
    this.genderList = [
      { label: "Select", value: "" },
      { label: "Male", value: "Male" },
      { label: "Female", value: "Female" },
    ];
    this.eventList = [
      { label: "Select", value: "" },
      { label: "Recurve", value: "Recurve" },
      { label: "Compound", value: "Compound" },
      { label: "Para", value: "Para" },
    ];

    this.NIS = [
      {
        label: "NIS",
        value: "NIS",
      },
      { label: "Certificate Course", value: "Certificate Course" },
      { label: "Non-NIS", value: "Non-NIS" },
    ];
    this.employed = [
      { label: "Select", value: "" },
      { label: "Yes", value: "Employed" },
      { label: "No", value: "Unemployed" },
      { label: "Self Employed", value: "Self Employed" },
    ];

    this.employmentDetails = [
      { label: "Select", value: "" },
      { label: "Central Govt.", value: "Central Govt." },
      { label: "State Govt.", value: "State Govt." },
      {
        label: "Any other Govt. Department",
        value: "Any other Govt. Department",
      },
      { label: "Private Coaching", value: "Private Coaching" },
      { label: "Own Academy", value: "Own Academy" },
      { label: "Others", value: "Others" },
    ];
    this.certificateLevel = [
      { label: "Select", value: "" },
      { label: "International Judge", value: "International Judge" },
      {
        label: "International Judge Candidate",
        value: "International Judge Candidate",
      },
      { label: "Continental Judge", value: "Continental Judge" },
      {
        label: "National Technical Official",
        value: "National Technical Official",
      },
      { label: "State technical official", value: "State technical official" },
      {
        label: "District technical official",
        value: "District technical official",
      },
    ];
  }

  ngOnInit(): void {
    this.termsModel = true;
    this.emailVerificationForm();
    this.judgeForm();
    this.getStateList();
    this.getMemberAssociation();
    this.addEducation();
    this.addProfession();

    const token = localStorage.getItem("token");
    if (token) {
      const userData: any = JSON.parse(localStorage.getItem("userInfo"));
      const type = userData.accountType;
      console.log("token exist.....");
      this.getArcherDetails(type);
    }
  }
  getArcherDetails(type: any) {
    const url = this.global.basePath + "user/getdeatil?accountType=" + type;

    this.global.GetRequest(url).subscribe((res) => {
      const data = res[0].json.data;
      this.applicantData = data;
      console.log(
        "TCL: CoachRegisterComponent -> getArcherDetails -> this.applicantData",
        this.applicantData
      );
      this.setFormData();
    });
  }

  setFormData() {
    this.isEmailVerified = true;
    this.judgeFormGroup.get("fullName").setValue(this.applicantData.fullName);
    // this.judgeFormGroup.get('memberAssociation').setValue(this.applicantData.memberAssociation._id);
    this.judgeFormGroup.get("phone").setValue(this.applicantData.phone);
    this.judgeFormGroup.get("email").setValue(this.applicantData.email);
    this.judgeFormGroup.get("password").setValue("null");
    this.judgeFormGroup.controls["fullName"].disable();
    // this.judgeFormGroup.controls['memberAssociation'].disable();
    this.judgeFormGroup.controls["phone"].disable();
    this.judgeFormGroup.controls["email"].disable();
    this.judgeFormGroup.controls["password"].disable();
  }

  ngAfterViewInit() {
    this.elementPosition = 100;
  }

  @HostListener("window:scroll", ["$event"])
  handleScroll() {
    const windowScroll = window.pageYOffset;
    if (windowScroll >= this.elementPosition) {
      this.sticky = true;
    } else {
      this.sticky = false;
    }
  }

  togglePassword() {
    this.eyeShow = !this.eyeShow;
  }
  getMemberAssociation() {
    const url = this.global.basePath + "common/stateunion";
    this.global.GetRequest(url).subscribe((res) => {
      const array = res[0].json.data;
      for (const element of array) {
        this.memberAssociation.push({
          label: element.name,
          value: element._id,
        });
      }
    });
  }
  getStateList() {
    const url = this.global.basePath + "common/state";
    this.global.GetRequest(url).subscribe((res) => {
      const array = res[0].json.data;
      for (const element of array) {
        this.StateList.push({ label: element.name, value: element.name });
      }
    });
  }

  judgeForm() {
    this.judgeFormGroup = this.formBuilder.group({
      fullName: ["", Validators.required],
      gender: ["", Validators.required],
      // eventName: ['', Validators.required],
      dob: ["", Validators.required],
      phone: [
        "",
        [
          Validators.required,
          Validators.min(1000000000),
          Validators.max(9999999999),
          Validators.pattern("^[0-9]*$"),
        ],
      ],
      email: [
        "",
        [
          Validators.required,
          Validators.pattern(
            /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
          ),
        ],
      ],
      // education: ['', Validators.required],
      employementStatus: ["", Validators.required],
      memberAssociation: ["", [Validators.required]],
      employmentDetails: [""],
      postingPlace: [""],
      passingYear: ["", Validators.required],
      certificateLevel: ["", Validators.required],
      password: ["", [Validators.required]],
      profileImage: ["", [Validators.required]],
      resumeLink: ["", [Validators.required]],

      educationQualification: this.formBuilder.array([]),
      professionalQualification: this.formBuilder.array([]),
      signatureLink: ["", [Validators.required]],

      aadharCard: ["", [Validators.required]],
      aadharImage: ["", [Validators.required]],
    });
  }
  get f() {
    return this.judgeFormGroup.controls;
  }
  openBox() {
    this.submitted = true;
    this.judgeFormGroup.controls["aadharImage"].setValue(this.aadharNewFile);

    if (
      this.judgeFormGroup.controls.fullName.value === "" ||
      this.judgeFormGroup.controls.gender.value === "" ||
      this.judgeFormGroup.controls.memberAssociation.value === "" ||
      this.judgeFormGroup.controls.dob.value === "" ||
      this.judgeFormGroup.controls.phone.value === "" ||
      this.judgeFormGroup.controls.email.value === "" ||
      this.judgeFormGroup.controls.password.value === "" ||
      this.judgeFormGroup.controls.aadharCard.value === "" ||
      this.judgeFormGroup.controls.aadharImage.value === ""
    ) {
      // this.firstValidation = true;
    } else {
      const token = localStorage.getItem("token");
      if (!token) {
        this.checkForUserValidation();
      } else {
        this.otherBox = true;
      }
    }
  }

  checkForUserValidation() {
    const data = {
      email: this.judgeFormGroup.controls.email.value,
      phone: this.judgeFormGroup.controls.phone.value,
      aadharCard: this.judgeFormGroup.controls.aadharCard.value,
      accountType: "Judge",
    };
    const url1 = this.global.base_path_api() + "user/checkemail";
    this.global.PostRequestUnauthorised(url1, data).subscribe(
      (res) => {
        const response = res[0].json.payload;
        this.mainValidation = false;
        this.otherBox = true;
        this.value = 50;
        this.submitted = false;
      },
      (err) => {
        if (err) {
          this.mainValidation = true;
          this.mainMessage = err.error.msg;
        }
      }
    );
  }

  // education and Professional
  educational(): FormArray {
    return this.judgeFormGroup.get("educationQualification") as FormArray;
  }
  newEducation(): FormGroup {
    return this.formBuilder.group({
      name: "",
    });
  }
  addEducation() {
    this.educational().push(this.newEducation());
  }
  deleteEducation(index) {
    const educational = this.judgeFormGroup.controls
      .educationQualification as FormArray;
    educational.removeAt(index);
  }

  professional(): FormArray {
    return this.judgeFormGroup.get("professionalQualification") as FormArray;
  }
  newProfession(): FormGroup {
    return this.formBuilder.group({
      name: "",
    });
  }
  addProfession() {
    this.professional().push(this.newEducation());
  }
  deleteProfession(index) {
    const professional = this.judgeFormGroup.controls
      .professionalQualification as FormArray;
    professional.removeAt(index);
  }
  // education and Professional

  onSubmit() {
    let url1: any;

    console.log("clicked", this.judgeFormGroup.value);
    this.submitted = true;
    if (
      this.judgeFormGroup.controls["employementStatus"].value === "Yes" ||
      this.judgeFormGroup.controls["employementStatus"].value ===
        "Self Employed"
    ) {
      this.judgeFormGroup.controls["employmentDetails"].setValidators(
        Validators.required
      );
    }

    this.judgeFormGroup.controls["profileImage"].setValue(this.signatureFile);
    this.judgeFormGroup.controls["resumeLink"].setValue(this.ResumeFile);
    this.judgeFormGroup.controls["signatureLink"].setValue(
      this.signatureNewFile
    );
    this.judgeFormGroup.controls["aadharImage"].setValue(this.aadharNewFile);

    if (this.judgeFormGroup.invalid) {
      return;
    } else {
      this.value = 100;
      // this.termsModel = true;
      // this.disableButton = true;
      this.logSpinner = true;
      const data = this.judgeFormGroup.getRawValue();

      const token = localStorage.getItem("token");
      if (token) {
        url1 =
          this.global.base_path_api() +
          "user/judgeregister?oldId=" +
          this.applicantData._id;
      } else {
        url1 = this.global.base_path_api() + "user/judgeregister?oldId=" + null;
      }

      this.global.PostRequestUnauthorised(url1, data).subscribe(
        (res) => {
          const response = res[0].json.payload;
          this.logSpinner = false;
          this.judgeFormGroup.reset();
          this.submitted = false;
          // this.disableButton = false;
          this.imageUpload = false;
          this.signatureFile = "";
          this.Resume = false;
          this.ResumeFile = "";
          // this.termsModel = false;
          if (localStorage.getItem("token")) {
            if (this.applicantData.accountType === "Team Officials") {
              this.router.navigate(["/id-card/officials"]);
            } else if (this.applicantData.accountType === "Judge") {
              this.router.navigate(["/id-card/judges"]);
            } else if (this.applicantData.accountType === "Coach") {
              this.router.navigate(["/id-card/coach"]);
            } else if (this.applicantData.accountType === "Player") {
              this.router.navigate(["/id-card/archer"]);
            }
          } else {
            this.router.navigate(["/status"]);
          }
        },
        (err) => {
          if (err) {
            console.log("inerr");
            console.log(
              "JudgesRegisterComponent -> submitRegister -> err",
              err
            );
            this.errorData = true;
            this.logSpinner = false;
            this.messageService.add({
              severity: "error",
              summary: "Message",
              detail: err.error.msg,
              sticky: true,
            });
            this.submitted = false;
          }
        }
      );
    }
  }

  fileChange(files: any) {
    console.log("TeamOfficialRegissterComponent -> fileChange -> files", files);
    const file = files[0];
    const url = this.global.base_path_api() + "common/upload";
    if (file) {
      const formData = new FormData();
      formData.append("img", file);
      const xhr = new XMLHttpRequest();
      xhr.open("POST", url, true);
      var self = this;
      xhr.onload = function () {
        if (xhr.status == 200) {
          var resp = JSON.parse(xhr.response);
          self.signatureFile = resp.name;

          if (resp.name !== "") {
            self.imageUpload = true;
          } else {
            alert(resp.message);
            self.imageUpload = false;
            return;
          }
        } else {
          console.log("there is err");
        }
      };

      xhr.send(formData);
    }
  }

  UploadResume(files: any) {
    console.log(
      "TeamOfficialRegisterComponsdsdsdfsfsdfent -> fileChange -> files",
      files
    );
    const file = files[0];
    const url = this.global.base_path_api() + "common/upload";
    if (file) {
      const formData = new FormData();
      formData.append("img", file);
      const xhr = new XMLHttpRequest();
      xhr.open("POST", url, true);
      const self = this;
      xhr.onload = function () {
        if (xhr.status == 200) {
          const resp = JSON.parse(xhr.response);
          self.ResumeFile = resp.name;
          self.ResumeFormat = resp.fileType;

          if (resp.name !== "") {
            self.Resume = true;
          } else {
            alert(resp.message);
            self.Resume = false;
            return;
          }
        }
      };

      xhr.send(formData);
    }
  }

  UploadSignature(files: any) {
    const file = files[0];
    const url = this.global.base_path_api() + "common/upload";
    if (file) {
      const formData = new FormData();
      formData.append("img", file);
      const xhr = new XMLHttpRequest();
      xhr.open("POST", url, true);
      const self = this;
      xhr.onload = function () {
        if (xhr.status === 200) {
          const resp = JSON.parse(xhr.response);
          self.signatureNewFile = resp.name;
          if (resp.name !== "") {
            self.signature = true;
            self.signatureFormat = resp.fileType;
          } else {
            alert(resp.message);
            self.signature = false;
            return;
          }
        }
      };

      xhr.send(formData);
    }
  }

  UploadAadhar(files: any) {
    const file = files[0];
    const url = this.global.base_path_api() + "common/upload";
    if (file) {
      const formData = new FormData();
      formData.append("img", file);
      const xhr = new XMLHttpRequest();
      xhr.open("POST", url, true);
      const self = this;
      xhr.onload = function () {
        if (xhr.status === 200) {
          const resp = JSON.parse(xhr.response);
          self.aadharNewFile = resp.name;
          if (resp.name !== "") {
            self.aadhar = true;
            self.aadharFormat = resp.fileType;
          } else {
            alert(resp.message);
            self.aadhar = false;
            return;
          }
        }
      };

      xhr.send(formData);
    }
  }

  checkIfAccepted(event) {
    this.checked = event.checked;
  }

  submitRegister() {
    this.termsModel = false;
  }

  toRoles() {
    if (localStorage.getItem("token")) {
      if (this.applicantData.accountType === "Team Officials") {
        this.router.navigate(["/id-card/officials"]);
      } else if (this.applicantData.accountType === "Judge") {
        this.router.navigate(["/id-card/judges"]);
      } else if (this.applicantData.accountType === "Coach") {
        this.router.navigate(["/id-card/coach"]);
      } else if (this.applicantData.accountType === "Player") {
        this.router.navigate(["/id-card/archer"]);
      }
    } else {
      this.router.navigate(["/role-select"]);
    }
  }
  toHome(){
    this.router.navigateByUrl("/");
    window.scroll(0,0);
  }
  
  openVerifyModel(){
    const emailControl = this.judgeFormGroup.get('email');
    emailControl?.markAsTouched();
    emailControl?.updateValueAndValidity();

    if (emailControl?.valid) {
      let data = { 'email': this.judgeFormGroup.controls["email"].value}
      const url = this.global.basePath + "user/otp-send-for-emailverification";
        this.global.PostRequestUnauthorised(url, data).subscribe(
          (res) => {
            this.emailVerifyModel = true;
            this.messageService.add({
              severity: "success",
              summary: "Message",
              detail: res[0].json.msg,
              sticky: false,
            });
          },
          (err) => {
            this.messageService.add({
              severity: "error",
              summary: "Message",
              detail: err.error.msg,
              sticky: false,
            });
          }
        );
    } else {
      console.log('Email is invalid');
    }
  }

  closeVerifyModel(){
    this.emailVerifyModel = false;
    this.codeSubmited = false;
    this.emailVerificationFormGroup.reset();
  }

  emailVerificationForm() {
    this.emailVerificationFormGroup = this.formBuilder.group({
      verificationCode: [
        '',
        [
          Validators.required,
          Validators.pattern('^\\d{6}$') // Pattern for exactly 6 digits
        ]
      ]
    });
  }

  get v() {
    return this.emailVerificationFormGroup.controls;
  }

  onVerifyCode(){
    this.codeSubmited = true;
    if (this.emailVerificationFormGroup.valid) {
      let data = { 'otp': this.emailVerificationFormGroup.controls["verificationCode"].value,
        'email': this.judgeFormGroup.controls["email"].value
      }
      const url = this.global.basePath + "user/email-otp-verify";
        this.global.PostRequestUnauthorised(url, data).subscribe(
          (res) => {
            this.isEmailVerified = true;
            this.emailVerifyModel = false;
            this.messageService.add({
              severity: "success",
              summary: "Message",
              detail: res[0].json.msg,
              sticky: false,
            });
          },
          (err) => {
            this.messageService.add({
              severity: "error",
              summary: "Message",
              detail: err.error.msg,
              sticky: false,
            });
          }
        );
    }
  }

  isEmailInvalid(): boolean {
    const emailControl = this.judgeFormGroup.get('email');
    return emailControl?.touched && emailControl?.invalid;
  }
}
