import { Component, OnInit ,ElementRef, ViewChild} from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { GlobalService } from "../global-service";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  FormArray,
  AbstractControl, ValidationErrors
} from "@angular/forms";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import * as moment from 'moment';
@Component({
  selector: 'app-campaign-details',
  templateUrl: './campaign-details.component.html',
  styleUrls: ['./campaign-details.component.css']
})
export class CampaignDetailsComponent implements OnInit {
  @ViewChild('content') content!: ElementRef;
  data: any = [];
  detailData: any;
  addEntryModel: boolean = false;
  viewEntryModel: boolean = false;
  tournamentId: any;
  tournamentList: any;
  tournamentObj: any;
  stateName: any;
  archerList: any;
  archerListObj: any = [];
  ApprovedApplicants: any = [];
  selectedArcherObj: any;
  addEntryFormGroup: FormGroup;
  archerId: any
  playingCategory: any;
  successMsg: boolean = false;
  listOfEntries: any;
  allEntries: any;
  arrangeName: any;
  selectedCategoryCount: any = [];
  allFinalEntries: any;
  transportTypeObj: any = [];
  addTransportFormGroup: FormGroup;
  type: any
  vehicleNumber: any
  arrivalPlace: any
  arrivalDateAndTime: any
  departurePlace: any
  departureDateAndTime: any
  transportationList: any
  showTicketField: boolean = false;
  labelName: string;
  viewCategoryName: string;
  summaryDetails: any;
  coachesListObject: any;
  tempCoachesData: any;
  coachList: boolean = false;
  categoryObj: any;
  coachId: any;
  listOfCoachEntries: any
  listOfTeamOfficialData: any;
  isArcher: boolean = false;
  noRecordFound: boolean = false;
  tempTeamOfficialData: any
  teamOfficialList: boolean = false;
  submitted = false;
  submit = false;
  buttonFlag = false;
  categoriesArray: any;
  loading: boolean = false;
  selectedCategory: string = '';
  limitMsg: boolean = false;
  errorMsg: string = '';
  associationFullName:string = '';
  onlyPdfContent:boolean = false;
  constructor(
    public global: GlobalService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder

  ) // private messageService: MessageService
  {
    this.archerListObj = [

    ];

    this.transportTypeObj = [
      { label: "By Road", value: "By Road" },
      { label: "By Train", value: "By Train" },
      { label: "By Air", value: "By Air" },
    ]

    this.coachesListObject = []
    this.categoryObj = [
      { label: "Compound", value: "Compound" },
      { label: "Indian Round", value: "Indian Round" },
      { label: "Recurve", value: "Recurve" },
      { label: "W1", value: "W1" },
    ]
  }

  ngOnInit(): void {

    this.tournamentId = this.route.snapshot.paramMap.get("id");
    this.addTransportFormGroup;
    this.getTournamentList()
    this.getArcherList()
    this.tournamentForm();
    this.transportForm();
    this.getTransportationList();
    this.getTournamentSummary();
    this.getAssociationFullName();
    setTimeout(() => {
      this.getTotalCategoryValueSum();
      this.getTotalFinalEntries();
      this.getListCoaches()
      this.getListOfficials();
    }, 1000);
  }

  tournamentForm() {
    this.addEntryFormGroup = this.formBuilder.group({
      archerId: [""],
      tournamentId: [""],
      memberAssociation: [""],
      playingCategory: [""],
      coachId: [""],
      coachCategory: [""],
      entryAs: [""],
      gender: [""]
    });
  }

  openTournamentDetails(playingCategory) {
    this.selectedCategory = "";
    this.submit = false;
    this.loading = true;
    if (playingCategory == 'CompoundMen' || playingCategory == 'IndianRoundMen' || playingCategory == 'RecurveMen' || playingCategory == 'W1Men') {
      this.teamOfficialList = false;
      this.coachList = false;
      this.isArcher = true;
      this.archerListObj = [];
      var men = this.ApprovedApplicants.filter(attr => attr.gender === 'Male');
      this.selectedArcherObj = {};
      for (const element of men) {
        this.archerListObj.push({
          label: element.memberId + '-' + element.fullName,
          value: element._id,
        });
      }

      if (playingCategory == 'W1Men') {
        this.selectedCategory = 'Para';
      } else {
        this.selectedCategory = playingCategory.replace('Men', '');
        let modifiedString = this.selectedCategory.replace(/([a-z])([A-Z])/g, "$1 $2");
        this.selectedCategory = modifiedString;
      }
      this.clearAndSetValidationForArcher();

    }
    if (playingCategory == 'CompoundWomen' || playingCategory == 'IndianRoundWomen' || playingCategory == 'RecurveWomen' || playingCategory == 'W1Women') {
      this.teamOfficialList = false;
      this.coachList = false;
      this.isArcher = true;
      var women = this.ApprovedApplicants.filter(attr => attr.gender === 'Female');
      this.archerListObj = [];
      this.selectedArcherObj = {};
      for (const element of women) {
        this.archerListObj.push({
          label: element.memberId + '-' + element.fullName,
          value: element._id,
        });
      }
      if (playingCategory == 'W1Women') {
        this.selectedCategory = 'Para';
      } else {
        this.selectedCategory = playingCategory.replace('Women', '');
        let modifiedString = this.selectedCategory.replace(/([a-z])([A-Z])/g, "$1 $2");
        this.selectedCategory = modifiedString;
      }
      this.clearAndSetValidationForArcher();
    }
    if (playingCategory == 'Coaches') {
      this.isArcher = false;
      this.teamOfficialList = false;
      this.coachList = true;
      this.archerListObj = [];
      this.selectedArcherObj = {};
      for (const element of this.tempCoachesData) {
        this.archerListObj.push({
          label: element.memberId + '-' + element.fullName,
          value: element._id,
        });
      }
      this.selectedCategory = playingCategory;
      this.clearAndSetValidationForCoachAndOfficial()
    }
    if (playingCategory == 'OtherTeamOfficial') {
      this.isArcher = false
      this.coachList = false;
      this.teamOfficialList = true;
      this.archerListObj = [];
      this.selectedArcherObj = {};
      for (const element of this.tempTeamOfficialData) {
        this.archerListObj.push({
          label: element.memberId + '-' + element.fullName,
          value: element._id,
        });
      }
      this.selectedCategory = playingCategory;
      this.clearAndSetValidationForCoachAndOfficial()
    }
    this.addEntryFormGroup.get('archerId').updateValueAndValidity();
    this.addEntryFormGroup.get('coachId').updateValueAndValidity();
    this.addEntryFormGroup.get('coachCategory').updateValueAndValidity();
    this.loading = false;
    this.addEntryModel = true;
  }

  clearAndSetValidationForCoachAndOfficial() {
    this.addEntryFormGroup.reset();
    this.addEntryFormGroup.get('archerId').clearValidators();
    this.addEntryFormGroup.get('coachId').clearValidators();
    this.addEntryFormGroup.get('coachCategory').clearValidators();
    this.addEntryFormGroup.controls['coachId'].setValidators([Validators.required]);
    this.addEntryFormGroup.controls['coachCategory'].setValidators([Validators.required]);
  }

  clearAndSetValidationForArcher() {
    this.addEntryFormGroup.reset();
    this.addEntryFormGroup.get('coachId').clearValidators();
    this.addEntryFormGroup.get('coachCategory').clearValidators();
    this.addEntryFormGroup.controls['archerId'].setValidators([Validators.required]);
  }

  closeTournamentDetails() {
    this.addEntryModel = false;
    this.archerListObj = []
  }

  get f() {
    return this.addEntryFormGroup.controls;
  }

  onSubmit() {
    this.submit = true;
    if (this.addEntryFormGroup.invalid) {
      return;
    } else {
      if (this.coachList || this.teamOfficialList) {
        this.addEntryFormGroup.get('archerId').disable();
      } else {
        this.addEntryFormGroup.get('coachId').disable();
      }
      this.addEntryFormGroup.controls["tournamentId"].setValue(this.tournamentId);

      if (this.coachList || this.teamOfficialList) {
        this.addEntryFormGroup.controls["memberAssociation"].setValue(this.selectedArcherObj.memberAssociation);
      } else {
        this.addEntryFormGroup.controls["memberAssociation"].setValue(this.selectedArcherObj.memberAssociation);
        this.addEntryFormGroup.controls["gender"].setValue(this.selectedArcherObj.gender);
      }
      if (this.coachList || this.teamOfficialList) {
        this.addEntryFormGroup.controls["playingCategory"].setValue(this.selectedArcherObj.playingCategory);
      } else {
        this.addEntryFormGroup.controls["playingCategory"].setValue(this.selectedCategory);
      }
      if (this.coachList || this.teamOfficialList) {
        this.addEntryFormGroup.controls["entryAs"].setValue(this.selectedArcherObj.accountType)
      }
      else {
        null;
      }
      let url = this.global.basePath + "tournament/addentryofarcher";

      this.global.PostRequest(url, this.addEntryFormGroup.value).subscribe(
        (res) => {
          this.addEntryFormGroup.reset();
          this.addEntryModel = false;
          setTimeout(() => {
            this.getTournamentList();
            this.getTournamentSummary();
            this.ApprovedApplicants = [];
            this.getArcherList();
            this.tempCoachesData = [];
            this.getListCoaches();
            this.tempTeamOfficialData = [];
            this.getListOfficials();
          }, 1000);
        },
        (err) => {
          if (err) {
            this.addEntryModel = true;
            this.selectedArcherObj = []
            this.limitMsg = true;
            this.errorMsg = err.error.msg
            setTimeout(() => {
              this.limitMsg = false;
            }, 3000);
            console.log('errrr', err.error.msg)
          }
        }
      );


      this.addEntryFormGroup.get('archerId').enable();
      this.addEntryFormGroup.get('coachId').enable();
    }

  }

  getTournamentList() {
    const url =
      this.global.basePath +
      "tournament/listtournaments"
    this.global.GetRequest(url).subscribe((res: any) => {
      const data = res[0].json.data;
      this.tournamentList = data;

      var final = [];
      data.forEach(function (value, key) {
        final[value._id] = [];
        for (let categoryName in value?.categoryWiseCount) {
          if (value.categories[categoryName] != 0 && value.categories[categoryName] != null) {
            var tempObject = {};
            tempObject['key'] = categoryName;
            tempObject['value'] = value?.categoryWiseCount[categoryName];
            // this.selectedCategoryCount.push(tempObject);
            final[value._id].push(tempObject);
          }
        } 
      });
      this.selectedCategoryCount = final[this.tournamentId];
      this.tournamentObj = data.find(o => o._id == this.tournamentId);
      final[this.tournamentId].forEach(element => {
        element.maximum = this.tournamentObj.categories[element.key]
      });
      this.categoriesArray = [];
      this.categoriesArray = final[this.tournamentId];
      let sum = 0;
      let obj = this.tournamentObj?.categoryWiseCount
      for (let k in obj) {
        sum += +obj[k]
      }
      this.allFinalEntries = sum
    });
  }

  openViewDetails(tournamentId, categoryName) {
    this.noRecordFound = false;
    this.isArcher = false;
    this.coachList = false
    this.teamOfficialList = false;
    this.viewCategoryName = this.formatCategoryKey(categoryName);
    this.listOfEntries = [];
    var name = "";
    var gender = "Male";
    if (categoryName == 'CompoundMen' || categoryName == 'CompoundWomen') {
      name = 'Compound';
      if (categoryName == 'CompoundWomen') {
        gender = 'Female'
      }
    }
    if (categoryName == 'IndianRoundMen' || categoryName == 'IndianRoundWomen') {
      name = 'IndianRound';
      if (categoryName == 'IndianRoundWomen') {
        gender = 'Female'
      }
    }
    if (categoryName == 'RecurveMen' || categoryName == 'RecurveWomen') {
      name = 'Recurve';
      if (categoryName == 'RecurveWomen') {
        gender = 'Female'
      }
    }
    if (categoryName == 'W1Men' || categoryName == 'W1Women') {
      name = 'Para';
      if (categoryName == 'W1Women') {
        gender = 'Female'
      }
    }
    let url;
    if (categoryName == 'Coaches' || categoryName == 'OtherTeamOfficial') {

      url =
        this.global.basePath +
        "tournament/listofentries?tournamentId=" + tournamentId;
    }
    else {
      url =
        this.global.basePath +
        "tournament/listofentries?playingCategory=" + name + '&tournamentId=' + tournamentId + '&gender=' + gender;
    }
    this.listOfEntries = null;

    this.noRecordFound = false;
    this.global.GetRequest(url).subscribe((res: any) => {
      let filteredUsers;
      if (categoryName == 'Coaches') {
        this.isArcher = false;
        this.teamOfficialList = false;
        const data = res[0].json.data;
        this.listOfCoachEntries = data.filter((coach) => {
          return coach.coachId.accountType == 'Coach';
        });
        if (this.listOfCoachEntries.length != 0) {
          this.coachList = true
          this.noRecordFound = true;
        }
      }
      else if (categoryName == 'OtherTeamOfficial') {
        this.isArcher = false;
        this.coachList = false
        const data = res[0].json.data;
        this.listOfTeamOfficialData = data.filter((teamOfficial) => {
          return teamOfficial.coachId.accountType == 'Team Officials';
        });
        if (this.listOfTeamOfficialData.length != 0) {
          this.teamOfficialList = true;
          this.noRecordFound = true;
        }
      }
      else {
        this.noRecordFound = true;
        this.coachList = false
        this.teamOfficialList = false;
        this.isArcher = true;
        const data = res[0].json.data;
        filteredUsers = data.filter((user) => {
          return user.archerId.gender === gender;
        });
      }


      this.listOfEntries = filteredUsers;

    });
    this.viewEntryModel = true;
  }

  closeViewDetails() {
    this.viewEntryModel = false;
  }

  getArcherList() {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let memberAssociation = userInfo.memberAssociation._id;
    const url =
      this.global.basePath +
      "tournament/archerinfo?tournamentId=" + this.tournamentId +'&memberAssociation=' + memberAssociation
      ;
    this.global.GetRequest(url).subscribe((res: any) => {
      const array = res[0].json;
      this.ApprovedApplicants = array.approved;
    });
  }

  selectedArcher(event: any, type) {
    if (type == 'coach') {
      this.selectedArcherObj = this.tempCoachesData.find(o => o._id == event.value)
    }
    if (type == 'archer') {
      this.selectedArcherObj = this.ApprovedApplicants.find(o => o._id == event.value)
    }
    if (type == 'teamOfficial') {
      this.selectedArcherObj = this.tempTeamOfficialData.find(o => o._id == event.value)
    }

  }

  getTotalCategoryValueSum() {
    let sum = 0;
    let obj = this.tournamentObj?.categories
    for (let k in obj) {
      sum += +obj[k]
    }
    this.allEntries = sum
  }


  formatCategoryKey(key: string): string {
    return key.replace(/([A-Z])/g, ' $1').trim();

  }

  selectedCategoryValue(key: string): string {
    var response = this.selectedCategoryCount.find(o => o.key == key);
    return response?.value;
  }

  getTotalFinalEntries() {
    let sum = 0;
    let obj = this.tournamentObj?.categoryWiseCount
    for (let k in obj) {
      sum += +obj[k]
    }
    this.allFinalEntries = sum
  }

  transportForm() {
    this.addTransportFormGroup = this.formBuilder.group({
      type: ["", Validators.required],
      vehicleNumber: [""],
      arrivalPlace: ["", Validators.required],
      arrivalDateAndTime: ["", Validators.required],
      departurePlace: ["", Validators.required],
      departureDateAndTime: ["", Validators.required],
      tournamentId: [""],
      playingCategory: ["", Validators.required],
      numberOfPersons: ["", Validators.required]
    });
  }
  get v() {
    return this.addTransportFormGroup.controls;
  }
  transportOnSubmit() {
    this.submitted = true;
    if (this.addTransportFormGroup.invalid) {
      return;
    } else {
      this.buttonFlag = true;
      let url = this.global.basePath + "tournament/addtransportationdetails";
      this.global.PostRequest(url, { ...this.addTransportFormGroup.value, tournamentId: this.tournamentId }).subscribe(
        (res) => {
          this.addTransportFormGroup.reset();
          setTimeout(() => {
            this.getTransportationList();
            this.getTournamentSummary();
          }, 1000);
        },
        (err) => {
          if (err) {
            console.log('errrr', err)
          }
        }
      );
      this.buttonFlag = false;
      this.submitted = false
    }
  }

  getTransportationList() {
    const url =
      this.global.basePath +
      "tournament/listoftransportation/" + this.tournamentId
    this.transportationList = null
    this.global.GetRequest(url).subscribe((res: any) => {
      const data = res[0].json.data;
      this.transportationList = data;
    });
  }

  onTransportTypeChange(event: any) {
    if (event.value == 'By Train' || event.value == 'By Air') {
      this.showTicketField = true
      if (event.value == 'By Train') {
        this.labelName = 'Train Details'
      }
      if (event.value == 'By Air') {
        this.labelName = 'Flight Details'
      }
    } else {
      this.showTicketField = false
    }
  }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('userInfo');
    this.router.navigate(['/login']);
    localStorage.removeItem(
      'onceOpenken'
    );
  }

  getTournamentSummary() {
    const url =
      this.global.basePath +
      "tournament/summaryoftournament/" + this.tournamentId
    this.summaryDetails = null
    this.global.GetRequest(url).subscribe((res: any) => {
      const data = res[0].json.data;
      this.summaryDetails = data;
    });
  }

  getListCoaches() {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let memberAssociation = userInfo.memberAssociation._id;
    const url =
      this.global.basePath +
      "tournament/coachinfo?tournamentId=" + this.tournamentId + '&memberAssociation=' + memberAssociation;
    this.coachesListObject = []
    this.global.GetRequest(url).subscribe((res: any) => {
      this.tempCoachesData = res[0].json.approved;
    });
  }

  getListOfficials() {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let memberAssociation = userInfo.memberAssociation._id;
    const url =
      this.global.basePath +
      "tournament/teamofficialinfo?tournamentId=" + this.tournamentId+ '&memberAssociation=' + memberAssociation;
    this.global.GetRequest(url).subscribe((res: any) => {
      this.tempTeamOfficialData = res[0].json.approved;
    });
  }

  onDeleteClick(entryId) {
    console.log('entryId', entryId)
    let url = this.global.basePath + "tournament/deleteentry/" + entryId;
    this.global.DeleteRequest(url).subscribe((res: any) => {
      this.closeViewDetails()
      this.getTournamentList()
      this.getListCoaches()
      this.getListOfficials()
      this.getArcherList()
      this.getTournamentSummary()
    });
  }

  downloadPDF() {
    this.loading = true;
    this.onlyPdfContent = true;
    setTimeout(() => {
    const doc = new jsPDF();
    const content = this.content.nativeElement;
    html2canvas(content).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      doc.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
      doc.save('Summary.pdf');
    });
      this.onlyPdfContent = false;
      this.loading = false;
    }, 1000);
  }

  onDeleteTransport(transportationId) {
    let url = this.global.basePath + "tournament/deletetransportation/" + transportationId;
    this.global.DeleteRequest(url).subscribe((res: any) => {
      this.getTransportationList()
      this.getTournamentSummary()
    });
  }

  getAssociationFullName(){
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let state = userInfo.memberAssociation.state;
    let name = userInfo.memberAssociation.name;
    this.associationFullName = state + '-'+ name;
  }

  checkDate(deadlineDate){
    let deadline = moment(deadlineDate);
    let today=moment();
    if (deadline.diff(today, "days") >= 0) {
      return true;
    }
  }

  backToList(){
    this.router.navigateByUrl("/tournament");
  }
}
