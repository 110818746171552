import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GlobalService } from './global-service';
import { LoginComponent } from './login/login.component';
import { ArcherRegisterComponent } from './register/archer-register/archer-register.component';
import { CoachRegisterComponent } from './register/coach-register/coach-register.component';
import { FansRegisterComponent } from './register/fans-register/fans-register.component';
import { JudgesRegisterComponent } from './register/judges-register/judges-register.component';
import { TeamOfficialRegisterComponent } from './register/team-official-register/team-official-register.component';
import { RoleSelectComponent } from './role-select/role-select.component';
import { SharedModule } from './shared/shared.module';
import { AdminApprovalComponent } from './admin-approval/admin-approval.component';
import { ArcherDetailComponent } from './archer-detail/archer-detail.component';
import { CoachDetailComponent } from './coach-detail/coach-detail.component';
import { JudgeDetailComponent } from './judge-detail/judge-detail.component';
import { FanDetailComponent } from './fan-detail/fan-detail.component';
import { TeamOfficialDetailComponent } from './team-official-detail/team-official-detail.component';
import { MessageService } from 'primeng/api';
import { StatusComponent } from './status/status.component';
import { IdCardComponent } from './id-card/id-card.component';
import { FanStatusComponent } from './fan-status/fan-status.component';
import { CampaignComponent } from './campaign/campaign.component';
import { CampaignDetailsComponent } from './campaign-details/campaign-details.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ArcherRegisterComponent,
    CoachRegisterComponent,
    FansRegisterComponent,
    JudgesRegisterComponent,
    TeamOfficialRegisterComponent,
    RoleSelectComponent,
    AdminApprovalComponent,
    ArcherDetailComponent,
    CoachDetailComponent,
    JudgeDetailComponent,
    FanDetailComponent,
    TeamOfficialDetailComponent,
    StatusComponent,
    IdCardComponent,
    FanStatusComponent,
    CampaignComponent,
    CampaignDetailsComponent

  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule.forRoot(),
  ],
  providers: [GlobalService, MessageService],
  bootstrap: [AppComponent],
})
export class AppModule { }
