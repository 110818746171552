<div class="Light-nav">
    <div class="logo-wrapper">
        <img src="assets/icons/Logo.JPG" alt=''>
    </div>
    <div class="Light-nav-content">
        <div class="Light-support-wrap">
            <h2 class="Light-nav-heading">Archery association of India</h2>
            <img class="Light-head-support" src="assets/icons/target.svg" alt=''>
        </div>
        <p class="Light-nav-text" style="margin-top: 20px;">Archery Association of India came into existence in 1973,
            with the primary objective to organize, encourage, and promote the game of Archery in the country by
            providing proper training facilities to Indian Archers.
        </p>
    </div>

</div>

<div class="user-status-register">
    <h1>Your Registration Form is Submitted <br> Successfully! </h1>
    <p>Yor  can now login with your email and password to process further
         <br> Thanks!</p>
    <button class="authButton status-but" (click)="toLogin()">Login </button>
</div>