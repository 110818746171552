import { Component, OnInit } from '@angular/core';
import { GlobalService } from "../global-service";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-campaign',
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.css']
})
export class CampaignComponent implements OnInit {

  campaignData = []
  constructor(public global: GlobalService,
    private router: Router) { }

  ngOnInit(): void {
    this.getCampaign()
  }

  getCampaign() {
    const url =
      this.global.basePath +
      "tournament/listtournaments"
    this.global.GetRequest(url).subscribe((res: any) => {
      const data = res[0].json.data;
      this.campaignData = data;

    });
  }

  tournamentDetails(id: any) {
    this.router.navigateByUrl("tournament-details/" + id);
  }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('userInfo');
    this.router.navigate(['/login']);
    localStorage.removeItem(
      'onceOpenken'
    );
  }
}
