import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { GlobalService } from "../global-service";
import { MessageService } from "primeng/api";

@Component({
  selector: 'app-fan-detail',
  templateUrl: './fan-detail.component.html',
  styleUrls: ['./fan-detail.component.css',
  "../../assets/theme/detail.css"]
})
export class FanDetailComponent implements OnInit {
  loader: boolean = false;
  applicantId: any;
  applicantData: any;
  reasonText: any = "";
  rejectModel: boolean = false;
  noReasonFound: boolean = false;
  rejectUser: any;
  imageShow: any;
  imageModel: boolean = false;
  constructor(
    private router: Router,
    public global: GlobalService,
    private route: ActivatedRoute,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.getFanDetails();
  }
  getFanDetails() {
    this.loader = true;
    const url = this.global.basePath + "user/getdeatil";
    this.global.GetRequest(url).subscribe((res) => {
      const data = res[0].json.data;
      this.applicantData = data;
      console.log("ArcherDetailComponent -> getArcherDetails -> data", data);

      this.loader = false;
    });
  }
  closeImageModel() {
    this.imageModel = false;
  }
  openImageModel(image) {
    this.imageModel = true;
    this.imageShow = this.global?.basePathImage + image;
  }
  logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("userInfo");
    this.router.navigate(["/login"]);
  }

}
