import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { RoleSelectComponent } from './role-select/role-select.component';
import { ArcherRegisterComponent } from './register/archer-register/archer-register.component';
import { CoachRegisterComponent } from './register/coach-register/coach-register.component';
import { FansRegisterComponent } from './register/fans-register/fans-register.component';
import { JudgesRegisterComponent } from './register/judges-register/judges-register.component';
import { TeamOfficialRegisterComponent } from './register/team-official-register/team-official-register.component';

import { ArcherDetailComponent } from './archer-detail/archer-detail.component';
import { CoachDetailComponent } from './coach-detail/coach-detail.component';
import { JudgeDetailComponent } from './judge-detail/judge-detail.component';
import { TeamOfficialDetailComponent } from './team-official-detail/team-official-detail.component';
import { StatusComponent } from './status/status.component';
import { IdCardComponent } from './id-card/id-card.component';
import { FanStatusComponent } from './fan-status/fan-status.component';
import { FanDetailComponent } from './fan-detail/fan-detail.component';
import { CampaignComponent } from './campaign/campaign.component';
import { CampaignDetailsComponent } from './campaign-details/campaign-details.component';


const routes: Routes = [

  {
    path: '',
    loadChildren: () =>
      import('./navbar/navbar.module').then(
        (m) => m.NavbarModule
      ),
  },

  // { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },

  { path: 'status', component: StatusComponent },
  { path: 'fan-status', component: FanStatusComponent },

  { path: 'archer-register', component: ArcherRegisterComponent },
  { path: 'coach-register', component: CoachRegisterComponent },
  { path: 'coach-register', component: CoachRegisterComponent },
  { path: 'fans-register', component: FansRegisterComponent },

  { path: 'judges-register', component: JudgesRegisterComponent },
  { path: 'team-official-register', component: TeamOfficialRegisterComponent },
  { path: 'role-select', component: RoleSelectComponent },
  { path: 'archer-detail/:id', component: ArcherDetailComponent },
  { path: 'coach-detail/:id', component: CoachDetailComponent },
  { path: 'judge-detail/:id', component: JudgeDetailComponent },
  { path: 'team-official-detail/:id', component: TeamOfficialDetailComponent },
  { path: 'fan-detail/:id', component: FanDetailComponent },


  {
    path: 'admin-approval',
    loadChildren: () =>
      import('./admin-approval/admin-approval.module').then(
        (m) => m.AdminModule
      ),
  },

  {
    path: 'id-card',
    loadChildren: () =>
      import('./id-card/id-card.module').then((m) => m.IdCardModule),
  },
  { path: 'tournament', component: CampaignComponent },
  { path: 'tournament-details/:id', component: CampaignDetailsComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
