
<p-toast
  [baseZIndex]="900000"
  [preventOpenDuplicates]="false"
  [style]="{ marginTop: '80px' }"
></p-toast>

<div class="Light-nav">
  <div class="logo-wrapper">
    <img src="assets/icons/Logo.JPG" alt="" />
  </div>
  <div class="Light-nav-content">
    <div class="Light-support-wrap">
      <h2 class="Light-nav-heading">Archery association of India</h2>
      <img class="Light-head-support" src="assets/icons/target.svg" alt="" />
    </div>
    <p class="Light-nav-text" style="margin-top: 20px;">
      Archery Association of India came into existence in 1973, with the primary
      objective to organize, encourage, and promote the game of Archery in the
      country by providing proper training facilities to Indian Archers.
    </p>
  </div>
</div>

<div class="user-info-area">
  <div class="flex">
    <h2 Class="detail-page-head">Fan Details</h2>
    <button (click)="logout()" class="logout" style="margin: 0 0 0 auto;background: white;">Logout</button>
  </div>
  <div class="ui-g">
    <div class="ui-g-3 ui-lg-3 ui-md-3 ui-sm-12">
      <div class="user-info-card">
        <div *ngIf="applicantData?.profileImage" class="user-info-card-img">
          <img
            *ngIf="
              applicantData?.profileImage !== '' ||
              applicantData?.profileImage !== null
            "
            class="profile-img"
            [src]="global?.basePathImage + applicantData?.profileImage"
            alt=""
          />

          <img
            *ngIf="
              applicantData?.profileImage == '' ||
              applicantData?.profileImage == null
            "
            class="profile-img"
            src="assets/icons/archer.svg"
            alt=""
          />
        </div>
        <div class="user-colored-content">
          <div class="name-content">
            <h1 class="user-profile-title">{{ applicantData?.fullName }}</h1>
          </div>
          <p *ngIf="applicantData?.memberId" class="user-profile-text">Member Id: {{ applicantData?.memberId }}</p>
          <p class="user-profile-text">Email: {{ applicantData?.email }}</p>
          <p class="user-profile-text">Phone: {{ applicantData?.phone }}</p>
          <p class="user-profile-text">
            Dob: {{ applicantData?.dob | date: "mediumDate" }}
          </p>
        
        </div>
      </div>
    
    </div>
    <div class="ui-g-9 ui-lg-9 ui-md-9 ui-sm-12">
      <div class="profile-banner">
        <p>{{ applicantData?.fullName }}</p>
      </div>

   

      <div class="profile-content ui-g">
        <div *ngIf="applicantData?.resumeLink !== ''" class="ui-g-12">
          <div class="user-basic-card">
            <div class="flex">
              <div style="margin-left: 20px;width: 100%;">
                <h1 class="user-profile-title">Uploaded Documents</h1>
                <div class="ui-g">
                

                  <div
                    *ngIf="applicantData?.signatureLink !== ''"
                    class="ui-g-4 ui-lg-4 ui-md-6 ui-sm-12"
                  >
                    <p class="upload-Img-link">Signature</p>
                    <img
                    (click)="openImageModel(applicantData?.signatureLink)"
                      *ngIf="
                        applicantData?.signatureLink.includes('svg') ||
                        applicantData?.signatureLink.includes('png') ||
                        applicantData?.signatureLink.includes('jpg') ||
                        applicantData?.signatureLink.includes('JPG') ||
                        applicantData?.signatureLink.includes('jpeg')

                      "
                      class="upload-image-h"
                      [src]="
                        global?.basePathImage + applicantData?.signatureLink
                      "
                      alt=""
                    />
                    <div
                      *ngIf="
                        applicantData?.signatureLink.includes('pdf') ||
                        applicantData?.signatureLink.includes('doc')
                      "
                    >
                      <img
                        class="upload-image-h"
                        src="assets/icons/docIcon.svg"
                        alt=""
                      />
                      <a
                        class="Upload-Links"
                        target="_blank"
                        href="http://api.indianarchery.info/{{
                          applicantData?.signatureLink
                        }}"
                        download
                      >
                        http://api.indianarchery.info/{{
                          applicantData?.signatureLink
                        }}
                      </a>
                    </div>
                  </div>

                  <div
                    *ngIf="applicantData?.aadharImage !== ''"
                    class="ui-g-4 ui-lg-4 ui-md-6 ui-sm-12"
                  >
                    <p class="upload-Img-link">Aadhar card</p>
                    <img
                    (click)="openImageModel(applicantData?.aadharImage)"

                      *ngIf="
                        applicantData?.aadharImage.includes('svg') ||
                        applicantData?.aadharImage.includes('png') ||
                        applicantData?.aadharImage.includes('jpg') ||
                        applicantData?.aadharImage.includes('JPG') ||
                        applicantData?.aadharImage.includes('jpeg')
                      "
                      class="upload-image-h"
                      [src]="global?.basePathImage + applicantData?.aadharImage"
                      alt=""
                    />
                    <div
                      *ngIf="
                        applicantData?.aadharImage.includes('pdf') ||
                        applicantData?.aadharImage.includes('doc')
                      "
                    >
                      <img
                        class="upload-image-h"
                        src="assets/icons/docIcon.svg"
                        alt=""
                      />
                      <a
                        class="Upload-Links"
                        target="_blank"
                        href="http://api.indianarchery.info/{{
                          applicantData?.aadharImage
                        }}"
                        download
                      >
                        http://api.indianarchery.info/{{ applicantData?.aadharImage }}
                      </a>
                    </div>
                    <p class="card-num">
                      Aadhar card number: {{ applicantData?.aadharCard }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<p-dialog
  header="User"
  [(visible)]="imageModel"
  [dismissableMask]="true"
  [modal]="true"
  styleClass="mydialog"
  [responsive]="true"
  [minY]="70"
  [maximizable]="true"
  [baseZIndex]="10000"
>
  <div class="custom-popup">
    <div class="image-container">
      <img class="big-show-image" [src]="imageShow" alt="" />
    </div>
  </div>
</p-dialog>