<p-toast
  [baseZIndex]="900000"
  [preventOpenDuplicates]="false"
  [style]="{ marginTop: '80px' }"
></p-toast>

<div class="Light-nav">
  <div class="logo-wrapper">
    <img src="assets/icons/Logo.JPG" alt="" />
  </div>
  <div class="Light-nav-content">
    <div class="Light-support-wrap">
      <h2 class="Light-nav-heading">Archery association of India</h2>
      <img class="Light-head-support" src="assets/icons/target.svg" alt="" />
    </div>
    <p class="Light-nav-text" style="margin-top: 20px">
      Archery Association of India came into existence in 1973, with the primary
      objective to organize, encourage, and promote the game of Archery in the
      country by providing proper training facilities to Indian Archers.
    </p>
  </div>
</div>

<div class="user-info-area">
  <div class="flex">
    <img
      class="backArrow"
      (click)="backToApproval()"
      src="assets/icons/backArrow.svg"
    />
    <h2 Class="detail-page-head">Judge Details</h2>
  </div>
  <div class="ui-g">
    <div class="ui-g-3 ui-lg-3 ui-md-3 ui-sm-12">
      <div class="user-info-card">
        <div class="user-info-card-img">
          <img
            *ngIf="
              applicantData?.profileImage !== '' ||
              applicantData?.profileImage !== null
            "
            class="profile-img"
            [src]="global?.basePathImage + applicantData?.profileImage"
            alt=""
          />

          <img
            *ngIf="
              applicantData?.profileImage == '' ||
              applicantData?.profileImage == null
            "
            class="profile-img"
            src="assets/icons/archer.svg"
            alt=""
          />
        </div>
        <div class="user-colored-content">
          <div class="name-content">
            <h1 class="user-profile-title">{{ applicantData?.fullName }}</h1>
            <p class="user-profile-text">Gender: {{ applicantData?.gender }}</p>
          </div>
          <p *ngIf="applicantData?.memberId" class="user-profile-text">Member Id: {{ applicantData?.memberId }}</p>
          <p class="user-profile-text">Email: {{ applicantData?.email }}</p>
          <p class="user-profile-text">Phone: {{ applicantData?.phone }}</p>
          <p class="user-profile-text">
            Dob: {{ applicantData?.dob | date: "mediumDate" }}
          </p>
          <!-- <p class="user-profile-text">Event: {{ applicantData?.eventName }}</p> -->
        </div>
      </div>
      <!-- <div class="user-basic-card">
        <h1 class="user-profile-title">Education Details</h1>
        <p class="user-basic-card-text">
          <span class="lit-head">Education:</span>
          {{ applicantData?.education }}
        </p>
        <p class="user-basic-card-text">
          <span class="lit-head">Certificate:</span>
          {{ applicantData?.certificateLevel }}
        </p>
      </div> -->
    </div>
    <div class="ui-g-9 ui-lg-9 ui-md-9 ui-sm-12">
      <div class="profile-banner">
        <p>{{ applicantData?.fullName }}</p>
        <div
          *ngIf="!applicantData?.isActive && !applicantData?.isReject"
          class="button-wrap"
        >
          <button class="authButton" (click)="accept()">Accept</button>
          <button
            class="authButton"
            style="margin-left: 20px"
            (click)="rejectOpenModel()"
          >
            Reject
          </button>
        </div>
        <!-- <button
          *ngIf="applicantData?.isActive && !applicantData?.isReject"
          class="authButton"
          (click)="rejectOpenModel()"
        >
          Reject
        </button> -->
        <button
          *ngIf="!applicantData?.isActive && applicantData?.isReject"
          class="authButton"
          (click)="reApprove()"
        >
          ReApprove
        </button>
      </div>

      <div class="profile-content ui-g">
        <div class="ui-g-6 ui-lg-6 ui-md-12 ui-sm-12">
          <div class="user-basic-card educational-card">
            <div class="flex">
              <img
                class="education-logo"
                src="assets/icons/target1.svg"
                alt=""
              />
              <div style="margin-left: 20px">
                <h1 class="user-profile-title">Details</h1>
                <!-- <p class="user-basic-card-text">
                  <span class="lit-head">Event:</span>
                  {{ applicantData?.eventName }}
                </p> -->
                <!-- <p class="user-basic-card-text">
                  <span class="lit-head">Awards:</span>
                  {{ applicantData?.nationalAwards }}
                </p> -->
                <p class="user-basic-card-text">
                  <span class="lit-head">Employment:</span>
                  {{ applicantData?.employmentDetails }}
                </p>
                <p class="user-basic-card-text">
                  <span class="lit-head">Posting:</span>
                  {{ applicantData?.postingPlace }}
                </p>
                <p class="user-basic-card-text">
                  <span class="lit-head">Posting place:</span>
                  {{ applicantData?.postingPlace }}
                </p>
                <p class="user-basic-card-text">
                  <span class="lit-head">Certificate Level:</span>
                  {{ applicantData?.certificateLevel }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="ui-g-6 ui-lg-6 ui-md-12 ui-sm-12">
          <div *ngIf="applicantData?.resumeLink !== ''" class="user-basic-card">
            <div class="flex">
              <div style="margin-left: 20px; width: 100%">
                <h1 class="user-profile-title">Uploaded Documents</h1>
                <div class="ui-g">
                  <div class="ui-g-4 ui-lg-4 ui-md-6 ui-sm-12">
                    <p class="upload-Img-link">Resume Doc</p>
                    <img
                      (click)="openImageModel(applicantData?.resumeLink)"
                      *ngIf="
                        applicantData?.resumeLink.includes('svg') ||
                        applicantData?.resumeLink.includes('png') ||
                        applicantData?.resumeLink.includes('jpg') ||
                        applicantData?.resumeLink.includes('JPG') ||
                        applicantData?.resumeLink.includes('jpeg')
                      "
                      class="upload-image-h"
                      [src]="global?.basePathImage + applicantData?.resumeLink"
                      alt=""
                    />
                    <div
                      *ngIf="
                        applicantData?.resumeLink.includes('pdf') ||
                        applicantData?.resumeLink.includes('doc')
                      "
                    >
                      <img
                        class="upload-image-h"
                        src="assets/icons/docIcon.svg"
                        alt=""
                      />
                      <a
                        class="Upload-Links"
                        target="_blank"
                        href="http://api.indianarchery.info/{{
                          applicantData?.resumeLink
                        }}"
                        download
                      >
                        http://api.indianarchery.info/{{ applicantData?.resumeLink }}
                      </a>
                    </div>
                  </div>
                  <div
                    *ngIf="applicantData?.aadharImage !== ''"
                    class="ui-g-4 ui-lg-4 ui-md-6 ui-sm-12"
                  >
                    <p class="upload-Img-link">Aadhar card</p>
                    <img
                      (click)="openImageModel(applicantData?.aadharImage)"
                      *ngIf="
                        applicantData?.aadharImage.includes('svg') ||
                        applicantData?.aadharImage.includes('png') ||
                        applicantData?.aadharImage.includes('jpg') ||
                        applicantData?.aadharImage.includes('JPG') ||
                        applicantData?.aadharImage.includes('jpeg')
                      "
                      class="upload-image-h"
                      [src]="global?.basePathImage + applicantData?.aadharImage"
                      alt=""
                    />
                    <div
                      *ngIf="
                        applicantData?.aadharImage.includes('pdf') ||
                        applicantData?.aadharImage.includes('doc')
                      "
                    >
                      <img
                        class="upload-image-h"
                        src="assets/icons/docIcon.svg"
                        alt=""
                      />
                      <a
                        class="Upload-Links"
                        target="_blank"
                        href="http://api.indianarchery.info/{{
                          applicantData?.aadharImage
                        }}"
                        download
                      >
                        http://api.indianarchery.info/{{ applicantData?.aadharImage }}
                      </a>
                    </div>
                    <p class="card-num">
                      Aadhar card number: {{ applicantData?.aadharCard }}
                    </p>
                  </div>
                  <div
                    *ngIf="applicantData?.signatureLink !== ''"
                    class="ui-g-4 ui-lg-4 ui-md-6 ui-sm-12"
                  >
                    <p class="upload-Img-link">Signature Doc</p>
                    <img
                      (click)="openImageModel(applicantData?.signatureLink)"
                      *ngIf="
                        applicantData?.signatureLink.includes('svg') ||
                        applicantData?.signatureLink.includes('png') ||
                        applicantData?.signatureLink.includes('jpg') ||
                        applicantData?.signatureLink.includes('JPG') ||
                        applicantData?.signatureLink.includes('jpeg')
                      "
                      class="upload-image-h"
                      [src]="
                        global?.basePathImage + applicantData?.signatureLink
                      "
                      alt=""
                    />

                    <div
                      *ngIf="
                        applicantData?.signatureLink.includes('pdf') ||
                        applicantData?.signatureLink.includes('doc')
                      "
                    >
                      <img
                        class="upload-image-h"
                        src="assets/icons/docIcon.svg"
                        alt=""
                      />
                      <a
                        class="Upload-Links"
                        target="_blank"
                        href="http://api.indianarchery.info/{{
                          applicantData?.signatureLink
                        }}"
                        download
                      >
                        http://api.indianarchery.info/{{
                          applicantData?.signatureLink
                        }}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<p-dialog
  header="Reject"
  [(visible)]="rejectModel"
  [dismissableMask]="true"
  [modal]="true"
  styleClass="mydialog"
  [responsive]="true"
  [minY]="70"
  [maximizable]="true"
  [baseZIndex]="10000"
>
  <div class="user-login-status">
    <div class="img-wrap">
      <img
        *ngIf="applicantData?.profileImage !== ''"
        class="user-image"
        [src]="global?.basePathImage + applicantData?.profileImage"
        alt=""
      />

      <img
        *ngIf="applicantData?.profileImage == ''"
        class="user-image"
        src="assets/icons/noUser.svg"
        alt=""
      />
    </div>
    <h1 *ngIf="rejectModel">
      Are You sure You want to reject {{ applicantData?.fullName }}
    </h1>
    <div class="form-group">
      <label class="label">Reason</label>
      <textarea
        [(ngModel)]="reasonText"
        class="text-area"
        autoResize="false"
        [rows]="5"
        [cols]="30"
        pInputTextarea
      ></textarea>

      <div *ngIf="noReasonFound" class="invalid-feedback">
        Reason is Required
      </div>
    </div>
    <p-footer>
      <button class="model-but-save" (click)="reject()">Reject</button>
      <button
        class="model-but-close"
        style="background: gray"
        (click)="rejectCloseModel()"
      >
        Close
      </button>
    </p-footer>
  </div>
</p-dialog>

<p-dialog
  header="User"
  [(visible)]="imageModel"
  [dismissableMask]="true"
  [modal]="true"
  styleClass="mydialog"
  [responsive]="true"
  [minY]="70"
  [maximizable]="true"
  [baseZIndex]="10000"
>
  <div class="custom-popup">
    <div class="image-container">
      <img class="big-show-image" [src]="imageShow" alt="" />
    </div>
  </div>
</p-dialog>
