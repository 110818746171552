import { Component, OnInit ,NgZone} from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from "@angular/forms";
import { Router } from "@angular/router";
import { GlobalService } from "../../global-service";
import { MessageService } from "primeng/api";
declare var Razorpay: any;

@Component({
  selector: "app-fans-register",
  templateUrl: "./fans-register.component.html",
  styleUrls: ["./fans-register.component.css", "../../../assets/theme/form.css"]
})
export class FansRegisterComponent implements OnInit {
  eyeShow: boolean = false;

  fanFormGroup: FormGroup;
  submitted = false;
  errorData = false;
  StateList: any = [];
  termsModel: boolean = false;
  checked: boolean = false;

  imageUpload: boolean = false;
  signatureFile: any = "";
  disableButton: boolean = false;
  ResumeFormat: any;
  options: any;
  paymentId: any;
  logSpinner: boolean = false;

  signatureNewFile: any = "";
  signatureFormat: any;
  signature: boolean = false;

  aadharNewFile: any = "";
  aadharFormat: any;
  aadhar: boolean = false;
  noPaymentModelFan: boolean = false;
  priceData: any;
  paymentVerifyLoader: boolean = false;
  paymentSuccessModel: boolean = false;
  responseData:any;
  orderId: any;
  emailVerificationFormGroup:FormGroup;
  emailVerifyModel: boolean = false;
  codeSubmited:boolean = false;
  isEmailVerified:boolean = false;


  constructor(
    private zone: NgZone,
    private formBuilder: FormBuilder,
    private router: Router,
    public global: GlobalService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.FanForm();
    this.emailVerificationForm();
    this.getStateList();
    this.getPriceFan()
    this.termsModel = true;
    // this.fanFormGroup.controls['phone'].setValue(91);
    // console.log(this.fanFormGroup.controls['phone'],"this.fanFormGroup.controls['phone']")
  }
  getPriceFan() {
    const url = this.global.basePath + 'common/getprice?type=Fan';
    this.global.GetRequestUnauthorised(url).subscribe((res) => {
      const data = res[0].json.data;
      this.priceData = res[0].json.data;
			console.log("TCL: FansRegisterComponent -> getPriceFan -> this.priceData", this.priceData)
    });
  }

  // initPay() {
  //   this.fanFormGroup.controls.fullName,
  //   console.log("FansRegisterComponent -> initPay -> this.fanFormGroup.controls.fullName", this.fanFormGroup.controls.fullName)
  //   this.options = {
  //     key: 'rzp_test_h6mreaEDfqFLnZ'
  //     amount: '100'
  //     currency: 'INR',
  //     name: 'Archery Association of India',
  //     description: 'Please make the payment to register for AAI',
  //     image: '',
  //     handler: (response) => this.callResponse(response),
  //     prefill: {
  //       name: this.fanFormGroup.controls.fullName.value,
  //       email: this.fanFormGroup.controls.email.value,
  //       contact: this.fanFormGroup.controls.phone.value,
  //     },
  //   };

  //   const rzp1 = new Razorpay(this.options);
  //   rzp1.open();
  //   console.log('works');
  // }

  // callResponse(response) {
  //   this.paymentId = response.razorpay_payment_id;
  // }

  togglePassword() {
    this.eyeShow = !this.eyeShow;
  }

  getStateList() {
    const url = this.global.basePath + "common/state";
    this.global.GetRequest(url).subscribe(res => {
      const array = res[0].json.data;
      for (const element of array) {
        this.StateList.push({ label: element.name, value: element.name });
      }
    });
  }
  FanForm() {
    this.fanFormGroup = this.formBuilder.group({
      fullName: ["", Validators.required],
      fatherName: ["", [Validators.required]],
      currentState: ["", [Validators.required]],
      dob: ["", [Validators.required]],
      phone: [
        "",
        [
          Validators.required,
          Validators.min(1000000000),
          Validators.max(9999999999),
          Validators.pattern("^[0-9]*$")
        ]
      ],

      email: [
        "",
        [
          Validators.required,
          Validators.pattern(
            /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
          )
        ]
      ],
      reasonToJoin: ["", [Validators.required]],
      password: ["", [Validators.required]],
      profileImage: ["", [Validators.required]],

      signatureLink: ["", [Validators.required]],

      aadharCard: ["", [Validators.required]],
      aadharImage: ["", [Validators.required]]
    });
  }
  get f() {
    return this.fanFormGroup.controls;
  }

  onSubmit() {
    this.submitted = true;
    this.fanFormGroup.controls["profileImage"].setValue(this.signatureFile);
    this.fanFormGroup.controls["signatureLink"].setValue(this.signatureNewFile);
    this.fanFormGroup.controls["aadharImage"].setValue(this.aadharNewFile);

    if (this.fanFormGroup.invalid) {
      return;
    } else {
      // this.disableButton = true;
      // this.termsModel = true;

      this.logSpinner = true;
      const data = this.fanFormGroup.value;
      const url1 = this.global.base_path_api() + "user/fanregister";
      this.global.PostRequestUnauthorised(url1, data).subscribe(
        res => {
          const response = res[0].json.data;
          this.responseData =response
					console.log("TCL: FansRegisterComponent -> onSubmit -> this.responseData", this.responseData)
          // this.fanFormGroup.reset();
          this.logSpinner = false;
          this.submitted = false;
          // this.disableButton = false;
          this.imageUpload = false;
          this.signatureFile = "";
          this.noPaymentModelFan = true;
          // this.termsModel = false;
          // this.router.navigate(["/fan-status"]);
        },
        err => {
          if (err) {
            this.errorData = true;
            this.logSpinner = false;
            this.messageService.add({
              severity: "error",
              summary: "Message",
              detail: err.error.msg,
              sticky: true
            });
            this.submitted = false;
          }
        }
      );
    }
  }

  UploadSignature(files: any) {
    const file = files[0];
    const url = this.global.base_path_api() + "common/upload";
    if (file) {
      const formData = new FormData();
      formData.append("img", file);
      const xhr = new XMLHttpRequest();
      xhr.open("POST", url, true);
      const self = this;
      xhr.onload = function() {
        if (xhr.status === 200) {
          const resp = JSON.parse(xhr.response);
          self.signatureNewFile = resp.name;
          if (resp.name !== "") {
            self.signature = true;
            self.signatureFormat = resp.fileType;
          } else {
            alert(resp.message);
            self.signature = false;
            return;
          }
        }
      };

      xhr.send(formData);
    }
  }

  fileChange(files: any) {
    console.log("TeamOfficialRegisterComponent -> fileChange -> files", files);
    const file = files[0];
    const url = this.global.base_path_api() + "common/upload";
    if (file) {
      const formData = new FormData();
      formData.append("img", file);
      const xhr = new XMLHttpRequest();
      xhr.open("POST", url, true);
      const self = this;
      xhr.onload = function() {
        if (xhr.status == 200) {
          const resp = JSON.parse(xhr.response);
          self.signatureFile = resp.name;
          self.ResumeFormat = resp.fileType;
          if (resp.name !== "") {
            self.imageUpload = true;
          } else {
            alert(resp.message);
            self.imageUpload = false;
            return;
          }
        }
      };

      xhr.send(formData);
    }
  }

  UploadAadhar(files: any) {
    console.log("FansRegisterComponent -> UploadAadhar -> coclickednst");

    const file = files[0];
    const url = this.global.base_path_api() + "common/upload";
    if (file) {
      const formData = new FormData();
      formData.append("img", file);
      const xhr = new XMLHttpRequest();
      xhr.open("POST", url, true);
      const self = this;
      xhr.onload = function() {
        if (xhr.status === 200) {
          const resp = JSON.parse(xhr.response);
          self.aadharNewFile = resp.name;
          if (resp.name !== "") {
            self.aadhar = true;
            self.aadharFormat = resp.fileType;
          } else {
            alert(resp.message);
            self.aadhar = false;
            return;
          }
        }
      };

      xhr.send(formData);
    }
  }

  closeTerms() {
    this.termsModel = false;
  }
  checkIfAccepted(event) {
    this.checked = event.checked;
  }

  submitRegister() {
    this.termsModel = false;
  }
  toRoles() {
    this.router.navigate(["/role-select"]);
  }
  getOrderId() {
    const url =
      this.global.basePath + "common/getid?amount=" + this.priceData.price;
    this.global.GetRequestUnauthorised(url).subscribe((res) => {
      const data = res[0].json.orderId;
      this.orderId = data;
      if (this.orderId) {
        this.MakePayment();
      } else {
        this.getOrderId();
      }
    });
  }

  MakePayment() {
    this.noPaymentModelFan = false;
    this.options = {
      key: 'rzp_live_ppvHgybCkYFxfa',
      amount: this.priceData.price,
      currency: 'INR',
      name: 'Archery Association of India',
      order_id:this.orderId,
      description:
        'Please make the payment to register for Archery association of India',
      image: '',
      handler: (response) => this.callResponse(response),
      prefill: {
        name: this.responseData.name,
        email: this.responseData.email,
        contact: this.responseData.phone,
      },
    };

    const rzp1 = new Razorpay(this.options);
    rzp1.open();
  }

  callResponse(response) {
    this.paymentVerifyLoader = true;
    this.paymentId = response.razorpay_payment_id;
    this.zone.run(() => {
      this.paymentVerifyLoader = true;
    });
    this.paymentVerifyApi();
  }

  paymentVerifyApi() {
    const data = {
      id: this.paymentId,
      // email: this.responseData.email,
      type: 'fan',
    };
    const url = this.global.basePath + 'common/getpaymentinfo';
    this.global.PostRequestUnauthorised(url, data).subscribe((res) => {
      this.zone.run(() => {
        this.paymentVerifyLoader = false;
        this.paymentSuccessModel = true;
      });
    });
  }

  closeSuccessModel() {
    this.paymentSuccessModel = false;
  }
  toHome(){
    this.router.navigateByUrl("/");
    window.scroll(0,0);
  }

  openVerifyModel(){
    const emailControl = this.fanFormGroup.get('email');
    emailControl?.markAsTouched();
    emailControl?.updateValueAndValidity();

    if (emailControl?.valid) {
      let data = { 'email': this.fanFormGroup.controls["email"].value}
      const url = this.global.basePath + "user/otp-send-for-emailverification";
        this.global.PostRequestUnauthorised(url, data).subscribe(
          (res) => {
            this.emailVerifyModel = true;
            this.messageService.add({
              severity: "success",
              summary: "Message",
              detail: res[0].json.msg,
              sticky: false,
            });
          },
          (err) => {
            this.messageService.add({
              severity: "error",
              summary: "Message",
              detail: err.error.msg,
              sticky: false,
            });
          }
        );
    } else {
      console.log('Email is invalid');
    }
  }

  closeVerifyModel(){
    this.emailVerifyModel = false;
    this.codeSubmited = false;
    this.emailVerificationFormGroup.reset();
  }

  emailVerificationForm() {
    this.emailVerificationFormGroup = this.formBuilder.group({
      verificationCode: [
        '',
        [
          Validators.required,
          Validators.pattern('^\\d{6}$') // Pattern for exactly 6 digits
        ]
      ]
    });
  }

  get v() {
    return this.emailVerificationFormGroup.controls;
  }

  onVerifyCode(){
    this.codeSubmited = true;
    if (this.emailVerificationFormGroup.valid) {
      let data = { 'otp': this.emailVerificationFormGroup.controls["verificationCode"].value,
        'email': this.fanFormGroup.controls["email"].value
      }
      const url = this.global.basePath + "user/email-otp-verify";
        this.global.PostRequestUnauthorised(url, data).subscribe(
          (res) => {
            this.isEmailVerified = true;
            this.emailVerifyModel = false;
            this.messageService.add({
              severity: "success",
              summary: "Message",
              detail: res[0].json.msg,
              sticky: false,
            });
          },
          (err) => {
            this.messageService.add({
              severity: "error",
              summary: "Message",
              detail: err.error.msg,
              sticky: false,
            });
          }
        );
    }
  }

  isEmailInvalid(): boolean {
    const emailControl = this.fanFormGroup.get('email');
    return emailControl?.touched && emailControl?.invalid;
  }
}
