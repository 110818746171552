import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  ElementRef,
  HostListener,
} from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  FormArray,
} from "@angular/forms";
import { Router } from "@angular/router";
import {
  tap,
  distinctUntilChanged,
  switchMap,
  startWith,
} from "rxjs/operators";
import { EMPTY } from "rxjs";
import { GlobalService } from "../../global-service";
import { MessageService } from "primeng/api";

@Component({
  selector: "app-archer-register",
  templateUrl: "./archer-register.component.html",
  styleUrls: [
    "./archer-register.component.css",
    "../../../assets/theme/form.css",
  ],
})
export class ArcherRegisterComponent implements OnInit {
  eyeShow: boolean = false;
  archerFormGroup: FormGroup;
  genderList: any;
  maritalStatus: any;
  isMinor: any;
  submitted = false;
  isEducationVal = false;
  isEmployedVal = false;
  proofOfAge: any = [];
  memberAssociation: any = [];
  playingCategory: any;
  participationLevel: any;
  isSameBirth = false;
  otherResidenceProofDoc: any = [];
  residenceProofDocArray: any = [];
  passportVal = false;
  errorData = false;
  StateList: any = [];
  disableButton: boolean = false;

  profileImage: any = "";
  imageUpload: boolean = false;

  signatureFile: any = "";
  signature: boolean = false;

  passportFile: any = "";
  passport: boolean = false;

  passportFileBack: any = "";
  passportBack: boolean = false;

  residenceFile: any = "";
  residence: any;

  residenceFileBack: any = "";
  residenceBack: any;

  otherResidenceFile: any = "";
  otherResidence: boolean = false;

  AgeFile: any = "";
  age: boolean = false;
  otherResidenceFormat: any;
  residenceFormat: any;
  residenceFormatBack: any;
  ageFormat: any;
  passportFormat: any;
  passportFormatBack: any;

  signatureFormat: any;
  ImageFormat: any;
  sameAdd: boolean = false;

  termsModel: boolean = false;
  checked: boolean = false;
  isMinorCheck: boolean = false;

  addressInfo: boolean = false;
  eduBox: boolean = false;
  coachBox: boolean = false;
  ageProof: boolean = false;
  residenceProof: boolean = false;
  passportBox: boolean = false;
  photoBox: boolean = false;
  logSpinner: boolean = false;

  firstValidation: boolean = false;
  secondValidation: boolean = false;
  thirdValidation: boolean = false;
  fourthValidation: boolean = false;
  fifthValidation: boolean = false;
  sixthValidation: boolean = false;
  seventhValidation: boolean = false;
  value: number = 0;

  mainValidation: boolean = false;
  mainMessage: any;
  emailVerificationFormGroup:FormGroup;
  emailVerifyModel: boolean = false;
  codeSubmited:boolean = false;
  isEmailVerified:boolean = false;

  public isPresentSameAsPermanent: FormControl = new FormControl(false);

  @ViewChild("stickyMenu") menuElement: ElementRef;
  sticky: boolean = false;
  elementPosition: any;

  aadharNewFile: any = "";
  aadharFormat: any;
  aadhar: boolean = false;
  applicantData: any;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    public global: GlobalService,
    private messageService: MessageService
  ) {
    this.genderList = [
      { label: "Select", value: "" },
      { label: "Male", value: "Male" },
      { label: "Female", value: "Female" },
    ];
    this.maritalStatus = [
      { label: "Select", value: "" },
      { label: "Married", value: "Married" },
      { label: "Single", value: "Single" },
    ];
    this.isMinor = [
      { label: "Select", value: "" },
      { label: "Yes", value: true },
      { label: "No", value: false },
    ];

    this.playingCategory = [
      { label: "Select", value: "" },
      { label: "Indian Round", value: "Indian Round" },
      { label: "Compound", value: "Compound" },
      { label: "Recurve", value: "Recurve" },
      { label: "Para", value: "Para" },
    ];

    this.participationLevel = [
      { label: "Select", value: "" },
      { label: "District", value: "District" },
      { label: "State", value: "State " },
      { label: "National", value: "National" },
      { label: "International", value: "International" },
      { label: "Other", value: "Other" },
    ];

    this.proofOfAge = [
      {
        label: "Birth Certificate issued by the government authority",
        value: "true",
      },
      { label: "10th Marksheet", value: "false" },
    ];

    // this.otherResidenceProofDoc = [
    //   {
    //     label:
    //       "In case of Studying/Training outside native state - Identity Card of School/College/ Recognized Institution/ Training Academy/Centre or Bonafide Certificate issued by School/College/Recognised Institution/ Training Academy/Centre on letter pad with stamp.",
    //     value: "true",
    //   },
    //   {
    //     label:
    //       "In case of employment outside native state – Identity Card of Employer or Certificate issued by the Employer.",
    //     value: "true",
    //   },
    // ];

    this.residenceProofDocArray = [
      { label: "Aadhar Card", value: "asda" },
      { label: "Passport", value: "asd" },
      { label: "Driving License", value: "as" },
      { label: "Rational Card", value: "as" },
      { label: "Rent Agreement (Registered)", value: "sa" },
      { label: "Electricity /Water /Landline Telephone Bill", value: "sa" },
      { label: "Bank Passbook (Cover and Front page)", value: "sa" },
      {
        label:
          "Any Government issued document (subject to the approval of the AAI)",
        value: "as",
      },
    ];
  }

  // openAddress() {
  //   this.addressInfo = true;
  // }

  ngAfterViewInit() {
    this.elementPosition = 100;
  }

  @HostListener("window:scroll", ["$event"])
  handleScroll() {
    const windowScroll = window.pageYOffset;
    if (windowScroll >= this.elementPosition) {
      this.sticky = true;
    } else {
      this.sticky = false;
    }
  }

  openAddress() {
    this.submitted = true;
    this.archerFormGroup.controls["aadharImage"].setValue(this.aadharNewFile);
    if (
      this.archerFormGroup.controls.fullName.value === "" ||
      this.archerFormGroup.controls.email.value === "" ||
      this.archerFormGroup.controls.password.value === "" ||
      this.archerFormGroup.controls.gender.value === "" ||
      this.archerFormGroup.controls.fatherName.value === "" ||
      this.archerFormGroup.controls.motherName.value === "" ||
      this.archerFormGroup.controls.dob.value === "" ||
      this.archerFormGroup.controls.phone.value === "" ||
      this.archerFormGroup.controls.maritalStatus.value === "" ||
      this.archerFormGroup.controls.isMinor.value === "" ||
      this.archerFormGroup.controls.aadharCard.value === "" ||
      this.archerFormGroup.controls.aadharImage.value === ""
    ) {
      // this.firstValidation = true;
    } else {
      const token = localStorage.getItem("token");
      if (!token) {
        this.checkForUserValidation();
      } else {
        this.addressInfo = true;
      }
      // this.checkForUserValidation();
    }
  }

  checkForUserValidation() {
    const data = {
      email: this.archerFormGroup.controls.email.value,
      phone: this.archerFormGroup.controls.phone.value,
      aadharCard: this.archerFormGroup.controls.aadharCard.value,
      accountType: "Player",
    };
    const url1 = this.global.base_path_api() + "user/checkemail";
    this.global.PostRequestUnauthorised(url1, data).subscribe(
      (res) => {
        const response = res[0].json.payload;
        this.mainValidation = false;
        this.addressInfo = true;
        this.value = 20;
        this.submitted = false;
      },
      (err) => {
        if (err) {
          this.mainValidation = true;
          this.mainMessage = err.error.msg;
        }
      }
    );
  }

  openEduBox() {
    this.submitted = true;

    const presentControl = this.archerFormGroup.get(
      "presentAddress"
    ) as FormGroup;

    const permanentControl = this.archerFormGroup.get(
      "permanentAddress"
    ) as FormGroup;

    if (
      presentControl.status === "INVALID" ||
      permanentControl.status === "INVALID"
    ) {
      // this.secondValidation = true;
    } else {
      this.eduBox = true;
      this.value = 30;
      // this.secondValidation = false;
      this.submitted = false;
    }
  }

  openCoachBox() {
    this.submitted = true;
    const schoolControl = this.archerFormGroup.get(
      "collegeDetails"
    ) as FormGroup;
    const employeeControl = this.archerFormGroup.get(
      "employmentDetails"
    ) as FormGroup;
    if (
      this.archerFormGroup.controls.isStudying.value === "" ||
      this.archerFormGroup.controls.isEmployed.value === ""
    ) {
      this.submitted = true;
    } else {
      if (
        this.archerFormGroup.controls.isEmployed.value === true &&
        employeeControl.status === "INVALID"
      ) {
        this.submitted = true;
      } else if (
        this.archerFormGroup.controls.isStudying.value === true &&
        schoolControl.status === "INVALID"
      ) {
        this.submitted = true;
      } else if (
        this.archerFormGroup.controls.isStudying.value === false &&
        schoolControl.status === "INVALID"
      ) {
        this.submitted = true;
      } else if (
        this.archerFormGroup.controls.isEmployed.value === false &&
        employeeControl.status === "INVALID"
      ) {
        this.submitted = true;
      } else {
        this.coachBox = true;
        this.value = 40;
        // this.thirdValidation = false;
        this.submitted = false;
      }
    }
  }

  openAgeProof() {
    this.submitted = true;

    const beginningControl = this.archerFormGroup.get(
      "trainingDetails"
    ) as FormGroup;

    const currentControl = this.archerFormGroup.get(
      "currentTrainingDetails"
    ) as FormGroup;
    if (
      this.archerFormGroup.controls.memberAssociation.value === "" ||
      this.archerFormGroup.controls.playingCategory.value === "" ||
      beginningControl.status === "INVALID" ||
      currentControl.status === "INVALID"
    ) {
      // this.fourthValidation = true;
    } else {
      this.ageProof = true;
      this.value = 50;
      // this.fourthValidation = false;
      this.submitted = false;
    }
  }

  openResidenceProof() {
    this.submitted = true;
    this.archerFormGroup.controls["ageProofDocLink"].setValue(this.AgeFile);
    if (
      this.archerFormGroup.controls.ageProofDoc.value === "" ||
      this.archerFormGroup.controls.ageProofDocLink.value === ""
    ) {
      // this.fifthValidation = true;
    } else {
      this.residenceProof = true;
      this.value = 60;
      // this.fifthValidation = false;
      this.submitted = false;
    }
  }

  openPassportBox() {
    this.submitted = true;
    this.archerFormGroup.controls["residenceProofDocLink"].setValue(
      this.residenceFile
    );
    this.archerFormGroup.controls["residenceProofDocBackLink"].setValue(
      this.residenceFileBack
    );
    console.log(
      "TCL: ArcherRegisterComponent -> openPassportBox ->    this.archerFormGroup.controls.isBirthPlaceSameAsPresentPlace.value",
      this.archerFormGroup.controls.isBirthPlaceSameAsPresentPlace.value
    );
    if (
      this.archerFormGroup.controls.isBirthPlaceSameAsPresentPlace.value === ""
    ) {
      this.submitted = true;
    } else if (
      this.archerFormGroup.controls.residenceProofDocLink.value === "" ||
      this.archerFormGroup.controls.residenceProofDocBackLink.value === ""
    ) {
      this.submitted = true;
    } else {
      this.passportBox = true;
      this.value = 70;
      // this.sixthValidation = false;
      this.submitted = false;
    }
  }

  openPhotoBox() {
    this.submitted = true;
    this.archerFormGroup.controls["passportDocLink"].setValue(
      this.passportFile
    );
    this.archerFormGroup.controls["passportDocLinkBack"].setValue(
      this.passportFileBack
    );

    if (this.archerFormGroup.controls.havePassport.value === "") {
      this.submitted = true;
    } else if (
      this.archerFormGroup.controls.havePassport.value === true &&
      this.archerFormGroup.controls.passportDocLink.value === "" &&
      this.archerFormGroup.controls.passportDocLinkBack.value === ""
    ) {
      this.submitted = true;
    } else if (this.archerFormGroup.controls.havePassport.value === false) {
      this.submitted = false;
    } else {
      this.photoBox = true;
      this.value = 90;
      this.submitted = false;
    }
  }

  ngOnInit(): void {
    this.termsModel = true;
    this.emailVerificationForm()
    this.loginForm();
    this.getStateList();
    this.getMemberAssociation();
    this.addEducation();
    this.addProfession();
    this.isPresentSameAsPermanent.valueChanges
      .pipe(
        distinctUntilChanged(),
        switchMap((isSameAddress) => {
          if (isSameAddress) {
            console.log("clicked");
            this.sameAdd = true;

            return this.archerFormGroup.get("presentAddress").valueChanges.pipe(
              // at the beginning fill the form with the current values
              startWith(this.archerFormGroup.get("presentAddress").value),
              tap((value) =>
                // every time the sending address changes, update the billing address
                this.archerFormGroup.get("permanentAddress").setValue(value)
              )
            );
          } else {
            this.sameAdd = false;

            this.archerFormGroup.get("permanentAddress").reset();

            return EMPTY;
          }
        })
        // don't forget to unsubscribe when component's destroyed
      )
      .subscribe();

    const token = localStorage.getItem("token");
    if (token) {
      const userData: any = JSON.parse(localStorage.getItem("userInfo"));
      console.log("🚀 ~ file: archer-register.component.ts ~ line 475 ~ ArcherRegisterComponent ~ ngOnInit ~ userData", userData)
   
        const type = userData.accountType;
        console.log("🚀 ~ file: archer-register.component.ts ~ line 478 ~ ArcherRegisterComponent ~ ngOnInit ~ userData.accountType", userData.accountType)
        console.log("TCL: ArcherRegisterComponent -> type", type);
        console.log("token exist.....");
        if(type !==undefined){
        this.getArcherDetails(type);
        }
      
   
    }
  }

  getArcherDetails(type: any) {
    const url = this.global.basePath + "user/getdeatil?accountType=" + type;
    // const url = this.global.basePath + "user/getDetailid?id=5fefed65a841984ad2fba325";

    this.global.GetRequest(url).subscribe((res) => {
      const data = res[0].json.data;
      this.applicantData = data;
      console.log(
        "TCL: CoachRegisterComponent -> getArcherDetails -> this.applicantData",
        this.applicantData
      );
      this.setFormData();
    });
  }

  setFormData() {
    this.isEmailVerified = true;
    this.archerFormGroup.get("fullName").setValue(this.applicantData.fullName);
    this.archerFormGroup.get("gender").setValue(this.applicantData.gender);
    // this.archerFormGroup
    //   .get("memberAssociation")
    //   .setValue(this.applicantData.memberAssociation._id);
    this.archerFormGroup.get("phone").setValue(this.applicantData.phone);
    this.archerFormGroup.get("email").setValue(this.applicantData.email);


    this.archerFormGroup.get("aadharCard").setValue(this.applicantData.aadharCard);


    this.archerFormGroup.get("password").setValue("null");

    this.archerFormGroup.controls["fullName"].disable();
    this.archerFormGroup.controls["gender"].disable();
    // this.archerFormGroup.controls["memberAssociation"].disable();
    this.archerFormGroup.controls["phone"].disable();
    this.archerFormGroup.controls["password"].disable();
    this.archerFormGroup.controls["email"].disable();
    this.archerFormGroup.controls["aadharCard"].disable();


    this.profileImage = this.applicantData.profileImage;
    this.imageUpload = true;
    this.ImageFormat = "image";

    this.signatureFile = this.applicantData.signatureLink;
    this.signature = true;
    this.signatureFormat = "image";

    this.aadharNewFile = this.applicantData.aadharImage;
    this.aadhar = true;
    this.aadharFormat = "image";

  }

  togglePassword() {
    this.eyeShow = !this.eyeShow;
  }

  minorTest(event) {
    console.log("ArcherRegisterComponent -> minorTest -> event", event);

    if (event.value === true) {
      this.isMinorCheck = true;
      this.archerFormGroup.controls["parentsPhone"].setValidators(
        Validators.required
      );
      this.archerFormGroup.controls["parentsEmail"].setValidators(
        Validators.required
      );
    } else if (event.value === false) {
      this.isMinorCheck = false;
      this.archerFormGroup.controls["parentsPhone"].setValidators(null);
      this.archerFormGroup.controls["parentsEmail"].setValidators(null);
    }
  }

  setvalues() {
    // this.archerFormGroup.controls["havePassport"].setValue(false);
    // this.archerFormGroup.controls["isBirthPlaceSameAsPresentPlace"].setValue(
    //   false
    // );
    // this.archerFormGroup.controls['isStudying'].setValue();
    // this.archerFormGroup.controls['isEmployed'].setValue();
    this.archerFormGroup.controls["isPresentSameAsPermanent"].setValue(false);
  }

  getStateList() {
    const url = this.global.basePath + "common/state";
    this.global.GetRequest(url).subscribe((res) => {
      const array = res[0].json.data;
      for (const element of array) {
        this.StateList.push({ label: element.name, value: element.name });
      }
    });
  }
  getMemberAssociation() {
    const url = this.global.basePath + "common/stateunion";
    this.global.GetRequest(url).subscribe((res) => {
      const array = res[0].json.data;
      for (const element of array) {
        this.memberAssociation.push({
          label: element.name,
          value: element._id,
        });
      }
    });
  }

  loginForm() {
    this.archerFormGroup = this.formBuilder.group({
      fullName: ["", Validators.required],
      // lastName: ['', [Validators.required]],
      phone: [
        "",
        [
          Validators.required,
          Validators.min(1000000000),
          Validators.max(9999999999),
          Validators.pattern("^[0-9]*$"),
        ],
      ],
      email: [
        "",
        [
          Validators.required,
          Validators.pattern(
            /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
          ),
        ],
      ],

      password: ["", [Validators.required]],
      transactionId: [""],
      fatherName: ["", [Validators.required]],
      motherName: ["", [Validators.required]],
      dob: ["", [Validators.required]],
      gender: ["", [Validators.required]],
      nationality: ["Indian"],
      maritalStatus: ["", [Validators.required]],
      WhatsAppNo: [
        "",
        [
          Validators.min(1000000000),
          Validators.max(9999999999),
          Validators.pattern("^[0-9]*$"),
        ],
      ],
      isMinor: ["", [Validators.required]],
      aadharCard: ["", [Validators.required]],
      aadharImage: ["", [Validators.required]],
      parentsPhone: [
        "",
        [
          Validators.min(1000000000),
          Validators.max(9999999999),
          Validators.pattern("^[0-9]*$"),
        ],
      ],
      parentsEmail: [
        "",
        [
          Validators.pattern(
            /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
          ),
        ],
      ],

      alternatePhone: [
        "",
        [
          Validators.min(1000000000),
          Validators.max(9999999999),
          Validators.pattern("^[0-9]*$"),
        ],
      ],
      isPresentSameAsPermanent: [""],

      permanentAddress: this.formBuilder.group({
        houseNo: ["", [Validators.required]],
        city: ["", [Validators.required]],
        district: ["", [Validators.required]],
        state: ["", [Validators.required]],
        pinCode: ["", [Validators.required]],
        street: ["", [Validators.required]],
        landmark: ["", [Validators.required]],
      }),

      presentAddress: this.formBuilder.group({
        houseNo: ["", [Validators.required]],
        city: ["", [Validators.required]],
        district: ["", [Validators.required]],
        state: ["", [Validators.required]],
        pinCode: ["", [Validators.required]],
        street: ["", [Validators.required]],
        landmark: ["", [Validators.required]],
      }),

      isStudying: ["", [Validators.required]],

      collegeDetails: this.formBuilder.group({
        collegeName: [""],
        standard: [""],
        houseNo: [""],
        city: [""],
        district: [""],
        state: [""],
        pinCode: [""],
        educationQualification: this.formBuilder.array([]),
        professionalQualification: this.formBuilder.array([]),
      }),

      isEmployed: ["", [Validators.required]],

      employmentDetails: this.formBuilder.group({
        organisationName: [""],
        joining: [""],
        designation: [""],
        postingPLace: [""],
        streetAddress: [""],
        city: [""],
        district: [""],
        state: [""],
        pinCode: [""],
      }),

      memberAssociation: ["", [Validators.required]],
      playingCategory: ["", [Validators.required]],
      // participationLevel: ['', [Validators.required]],

      trainingDetails: this.formBuilder.group({
        trainingPlaceName: ["", [Validators.required]],
        coachName: ["", [Validators.required]],
        coachPhone: [
          "",
          [
            Validators.required,
            Validators.min(1000000000),
            Validators.max(9999999999),
            Validators.pattern("^[0-9]*$"),
          ],
        ],
      }),

      currentTrainingDetails: this.formBuilder.group({
        trainingPlaceName: ["", [Validators.required]],
        coachName: ["", [Validators.required]],
        coachPhone: [
          "",
          [
            Validators.required,
            Validators.min(1000000000),
            Validators.max(9999999999),
            Validators.pattern("^[0-9]*$"),
          ],
        ],
        joinYear: ["", [Validators.required]],
        email: [
          "",
          [
            Validators.pattern(
              /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
            ),
          ],
        ],
      }),

      ageProofDoc: ["", [Validators.required]],
      ageProofDocLink: ["", [Validators.required]],

      // residence link
      isBirthPlaceSameAsPresentPlace: [""],
      // otherResidenceProofDoc: [""],
      // otherResidenceProofDocLink: [""],

      residenceProofDoc: ["", [Validators.required]],
      residenceProofDocLink: ["", [Validators.required]],
      residenceProofDocBackLink: ["", [Validators.required]],

      // passport Link
      havePassport: [""],
      passportDocLink: [""],
      passportDocLinkBack: [""],

      // profile pic and signature done
      profileImage: ["", [Validators.required]],
      signatureLink: ["", [Validators.required]],

      // currentState: ['', [Validators.required]],
    });
    this.setvalues();
  }

  get f() {
    return this.archerFormGroup.controls;
  }
  get permanent() {
    return (this.archerFormGroup.get("permanentAddress") as FormGroup).controls;
  }
  get pre() {
    return (this.archerFormGroup.get("presentAddress") as FormGroup).controls;
  }
  get edu() {
    return (this.archerFormGroup.get("collegeDetails") as FormGroup).controls;
  }

  get employment() {
    return (this.archerFormGroup.get("employmentDetails") as FormGroup)
      .controls;
  }

  get training() {
    return (this.archerFormGroup.get("trainingDetails") as FormGroup).controls;
  }
  get currentTraining() {
    return (this.archerFormGroup.get("currentTrainingDetails") as FormGroup)
      .controls;
  }

  onSubmit() {
    let url1: any;

    this.archerFormGroup.controls["profileImage"].setValue(this.profileImage);
    this.archerFormGroup.controls["signatureLink"].setValue(this.signatureFile);
    console.log(
      this.archerFormGroup.controls.havePassport.value,
      "valthis.archerFormGroup.controls.havePassport.value"
    );
    if (this.archerFormGroup.controls.havePassport.value === "true") {
      this.archerFormGroup.controls["passportDocLink"].setValue(
        this.passportFile
      );
      this.archerFormGroup.controls["passportDocLinkBack"].setValue(
        this.passportFileBack
      );
    }

    this.archerFormGroup.controls["residenceProofDocLink"].setValue(
      this.residenceFile
    );
    this.archerFormGroup.controls["aadharImage"].setValue(this.aadharNewFile);

    this.archerFormGroup.controls["ageProofDocLink"].setValue(this.AgeFile);
    if (this.sameAdd) {
      this.archerFormGroup.controls["isPresentSameAsPermanent"].setValue(true);
    } else {
      this.archerFormGroup.controls["isPresentSameAsPermanent"].setValue(false);
    }
    console.log(this.archerFormGroup.value, " this.archerFormGroup.value");
    this.submitted = true;

    if (this.archerFormGroup.invalid) {
      this.messageService.add({
        severity: "error",
        summary: "Message",
        detail: "Please fill all the required fields",
        sticky: true,
      });
      return;
    } else {
      // this.termsModel = true;
      this.value = 100;
      // this.disableButton = true;

      this.logSpinner = true;
      const data = this.archerFormGroup.getRawValue();

      const token = localStorage.getItem("token");
      if (token && localStorage.getItem("userInfo")) {
         url1 =
          this.global.base_path_api() +
          "user/archerregister?oldId=" +
          this.applicantData._id;
      } else {
         url1 =
          this.global.base_path_api() + "user/archerregister?oldId=" + null;
      }

      this.global.PostRequestUnauthorised(url1, data).subscribe(
        (res) => {
          const response = res[0].json.payload;
          this.logSpinner = false;
          this.archerFormGroup.reset();
          this.submitted = false;
          // this.disableButton = false;
          this.clearUploads();
          // this.termsModel = false;
          if (localStorage.getItem("token") && localStorage.getItem("userInfo")) {
            if (this.applicantData.accountType === "Team Officials") {
              this.router.navigate(["/id-card/officials"]);
            } else if (this.applicantData.accountType === "Judge") {
              this.router.navigate(["/id-card/judges"]);
            } else if (this.applicantData.accountType === "Coach") {
              this.router.navigate(["/id-card/coach"]);
            } else if (this.applicantData.accountType === "Player") {
              this.router.navigate(["/id-card/archer"]);
            }
          } else {
            this.router.navigate(["/status"]);
          }
        },
        (err) => {
          if (err) {
            this.errorData = true;
            this.logSpinner = false;
            this.messageService.add({
              severity: "error",
              summary: "Message",
              detail: err.error.msg,
              sticky: true,
            });
            this.submitted = false;
          }
        }
      );
    }
  }
  clearUploads() {
    this.profileImage = "";
    this.imageUpload = false;
    this.signatureFile = "";
    this.signature = false;
    this.passportFile = "";
    this.passport = false;
    this.residenceFile = "";
    this.residence = false;
    this.otherResidenceFile = "";
    this.otherResidence = false;
    this.AgeFile = "";
    this.age = false;
  }

  // changeBirth(event) {
  //   if (event.checked) {
  //     this.isSameBirth = true;
  //   } else {
  //     this.isSameBirth = false;
  //   }
  // }

  educational(): FormArray {
    const studyControl = this.archerFormGroup.get(
      "collegeDetails"
    ) as FormGroup;
    return studyControl.get("educationQualification") as FormArray;
  }
  newEducation(): FormGroup {
    return this.formBuilder.group({
      name: "",
    });
  }
  addEducation() {
    this.educational().push(this.newEducation());
  }
  deleteEducation(index) {
    const studyControl = this.archerFormGroup.get(
      "collegeDetails"
    ) as FormGroup;
    const educational = studyControl.controls
      .educationQualification as FormArray;
    educational.removeAt(index);
  }

  professional(): FormArray {
    const eduControl = this.archerFormGroup.get("collegeDetails") as FormGroup;
    return eduControl.get("professionalQualification") as FormArray;
  }
  newProfession(): FormGroup {
    return this.formBuilder.group({
      name: "",
    });
  }
  addProfession() {
    this.professional().push(this.newProfession());
  }
  deleteProfession(index) {
    const eduControl = this.archerFormGroup.get("collegeDetails") as FormGroup;
    const professional = eduControl.controls
      .professionalQualification as FormArray;
    professional.removeAt(index);
  }

  changeEducation(event) {
    console.log("ArcherRegisterComponent -> changeEducation -> event", event);
    const studyControl = this.archerFormGroup.get(
      "collegeDetails"
    ) as FormGroup;
    if (event === "true") {
      console.log("inside true");
      this.isEducationVal = true;
      studyControl.controls["collegeName"].setValidators(Validators.required);
      studyControl.controls["houseNo"].setValidators(Validators.required);
      studyControl.controls["city"].setValidators(Validators.required);
      studyControl.controls["district"].setValidators(Validators.required);
      studyControl.controls["state"].setValidators(Validators.required);
      studyControl.controls["pinCode"].setValidators(Validators.required);
      studyControl.controls["standard"].setValidators(Validators.required);
      this.submitted = true;
    } else if (event === "false") {
      console.log("inside false");

      this.isEducationVal = false;
      studyControl.controls["collegeName"].setValidators(null);
      studyControl.controls["houseNo"].setValidators(null);
      studyControl.controls["city"].setValidators(null);
      studyControl.controls["district"].setValidators(null);
      studyControl.controls["state"].setValidators(null);
      studyControl.controls["pinCode"].setValidators(null);
      studyControl.controls["standard"].setValidators(null);
      this.submitted = false;
    }
  }

  changePassportVal(event) {
    if (event === "true") {
      this.passportVal = true;
    } else {
      this.passportVal = false;
    }
  }

  changeIsBirth(event) {
    console.log(
      "TCL: ArcherRegisterComponent -> changeIsBirth -> event",
      event
    );
    if (event === "true") {
      // this.isSameBirth = true;

      console.log(
        "this.residenceProofDoc.length",
        this.residenceProofDocArray.length
      );

      this.residenceProofDocArray.push(
        {
          label:
            "In case of Studying/Training outside native state - Identity Card of School/College/ Recognized Institution/ Training Academy/Centre or Bonafide Certificate issued by School/College/Recognised Institution/ Training Academy/Centre on letter pad with stamp.",
          value: "true",
        },
        {
          label:
            "In case of employment outside native state – Identity Card of Employer or Certificate issued by the Employer.",
          value: "true",
        }
      );
    } else if (event === "false") {
      console.log("inside fakseeeeeee");
      if (this.residenceProofDocArray.length > 7) {
        this.residenceProofDocArray.splice(
          7,
          this.residenceProofDocArray.length
        );
      }
      // this.isSameBirth = false;
    }
  }

  changeEmploy(event) {
    const eduControl = this.archerFormGroup.get(
      "employmentDetails"
    ) as FormGroup;
    if (event === "true") {
      this.isEmployedVal = true;
      eduControl.controls["organisationName"].setValidators(
        Validators.required
      );
      eduControl.controls["designation"].setValidators(Validators.required);
      eduControl.controls["joining"].setValidators(Validators.required);
      eduControl.controls["postingPLace"].setValidators(Validators.required);
      eduControl.controls["streetAddress"].setValidators(Validators.required);
      eduControl.controls["city"].setValidators(Validators.required);
      eduControl.controls["district"].setValidators(Validators.required);
      eduControl.controls["state"].setValidators(Validators.required);
      eduControl.controls["pinCode"].setValidators(Validators.required);
      this.submitted = true;
    } else if (event === "false") {
      this.isEmployedVal = false;
      eduControl.controls["organisationName"].setValidators(null);
      eduControl.controls["designation"].setValidators(null);
      eduControl.controls["joining"].setValidators(null);
      eduControl.controls["postingPLace"].setValidators(null);
      eduControl.controls["streetAddress"].setValidators(null);
      eduControl.controls["city"].setValidators(null);
      eduControl.controls["district"].setValidators(null);
      eduControl.controls["state"].setValidators(null);
      eduControl.controls["pinCode"].setValidators(null);
      this.submitted = false;
    }
  }

  changePassport(event) {
    if (event.checked) {
      this.passportVal = true;
    } else {
      this.passportVal = false;
    }
  }

  fileChange(files: any) {
    const file = files[0];
    const url = this.global.base_path_api() + "common/upload";
    if (file) {
      const formData = new FormData();
      formData.append("img", file);
      const xhr = new XMLHttpRequest();
      xhr.open("POST", url, true);
      const self = this;
      xhr.onload = function () {
        if (xhr.status == 200) {
          const resp = JSON.parse(xhr.response);
          self.profileImage = resp.name;
          if (resp.name !== "") {
            self.imageUpload = true;
            self.ImageFormat = resp.fileType;
          } else {
            alert(resp.message);
            self.imageUpload = false;
            return;
          }
        }
      };

      xhr.send(formData);
    }
  }

  UploadSignature(files: any) {
    const file = files[0];
    const url = this.global.base_path_api() + "common/upload";
    if (file) {
      const formData = new FormData();
      formData.append("img", file);
      const xhr = new XMLHttpRequest();
      xhr.open("POST", url, true);
      const self = this;
      xhr.onload = function () {
        if (xhr.status === 200) {
          const resp = JSON.parse(xhr.response);
          self.signatureFile = resp.name;
          if (resp.name !== "") {
            self.signature = true;
            self.signatureFormat = resp.fileType;
          } else {
            alert(resp.message);
            self.signature = false;
            return;
          }
        }
      };

      xhr.send(formData);
    }
  }

  uploadPassport(files: any) {
    const file = files[0];
    const url = this.global.base_path_api() + "common/upload";
    if (file) {
      const formData = new FormData();
      formData.append("img", file);
      const xhr = new XMLHttpRequest();
      xhr.open("POST", url, true);
      const self = this;
      xhr.onload = function () {
        if (xhr.status === 200) {
          const resp = JSON.parse(xhr.response);
          self.passportFile = resp.name;
          if (resp.name !== "") {
            self.passport = true;
            self.passportFormat = resp.fileType;
          } else {
            alert(resp.message);
            self.passport = false;
            return;
          }
        }
      };

      xhr.send(formData);
    }
  }

  uploadPassportBack(files: any) {
    const file = files[0];
    const url = this.global.base_path_api() + "common/upload";
    if (file) {
      const formData = new FormData();
      formData.append("img", file);
      const xhr = new XMLHttpRequest();
      xhr.open("POST", url, true);
      const self = this;
      xhr.onload = function () {
        if (xhr.status === 200) {
          const resp = JSON.parse(xhr.response);
          self.passportFileBack = resp.name;
          if (resp.name !== "") {
            self.passportBack = true;
            self.passportFormatBack = resp.fileType;
          } else {
            alert(resp.message);
            self.passportBack = false;
            return;
          }
        }
      };

      xhr.send(formData);
    }
  }

  uploadResidence(files: any) {
    const file = files[0];
    const url = this.global.base_path_api() + "common/upload";
    if (file) {
      const formData = new FormData();
      formData.append("img", file);
      const xhr = new XMLHttpRequest();
      xhr.open("POST", url, true);
      const self = this;
      xhr.onload = function () {
        if (xhr.status === 200) {
          const resp = JSON.parse(xhr.response);
          self.residenceFile = resp.name;
          if (resp.name !== "") {
            self.residence = true;
            self.residenceFormat = resp.fileType;
          } else {
            alert(resp.message);
            self.residence = false;
            return;
          }
        }
      };

      xhr.send(formData);
    }
  }

  uploadResidenceBack(files: any) {
    const file = files[0];
    const url = this.global.base_path_api() + "common/upload";
    if (file) {
      const formData = new FormData();
      formData.append("img", file);
      const xhr = new XMLHttpRequest();
      xhr.open("POST", url, true);
      const self = this;
      xhr.onload = function () {
        if (xhr.status === 200) {
          const resp = JSON.parse(xhr.response);
          self.residenceFileBack = resp.name;
          if (resp.name !== "") {
            self.residenceBack = true;
            self.residenceFormatBack = resp.fileType;
          } else {
            alert(resp.message);
            self.residenceBack = false;
            return;
          }
        }
      };

      xhr.send(formData);
    }
  }

  uploadOtherResidence(files: any) {
    const file = files[0];
    const url = this.global.base_path_api() + "common/upload";
    if (file) {
      const formData = new FormData();
      formData.append("img", file);
      const xhr = new XMLHttpRequest();
      xhr.open("POST", url, true);
      const self = this;
      xhr.onload = function () {
        if (xhr.status === 200) {
          const resp = JSON.parse(xhr.response);
          self.otherResidenceFile = resp.name;
          if (resp.name !== "") {
            self.otherResidence = true;
            self.otherResidenceFormat = resp.fileType;
          } else {
            alert(resp.message);
            self.otherResidence = false;
            return;
          }
        }
      };

      xhr.send(formData);
    }
  }

  uploadAgeFile(files: any) {
    const file = files[0];
    const url = this.global.base_path_api() + "common/upload";
    if (file) {
      const formData = new FormData();
      formData.append("img", file);
      const xhr = new XMLHttpRequest();
      xhr.open("POST", url, true);
      const self = this;
      xhr.onload = function () {
        if (xhr.status === 200) {
          const resp = JSON.parse(xhr.response);
          self.AgeFile = resp.name;
          if (resp.name !== "") {
            self.age = true;
            self.ageFormat = resp.fileType;
          } else {
            alert(resp.message);
            self.age = false;
            return;
          }
        }
      };

      xhr.send(formData);
    }
  }

  checkIfAccepted(event) {
    this.checked = event.checked;
  }

  submitRegister() {
    this.termsModel = false;
  }

  UploadAadhar(files: any) {
    const file = files[0];
    const url = this.global.base_path_api() + "common/upload";
    if (file) {
      const formData = new FormData();
      formData.append("img", file);
      const xhr = new XMLHttpRequest();
      xhr.open("POST", url, true);
      const self = this;
      xhr.onload = function () {
        if (xhr.status === 200) {
          const resp = JSON.parse(xhr.response);
          self.aadharNewFile = resp.name;
          if (resp.name !== "") {
            self.aadhar = true;
            self.aadharFormat = resp.fileType;
          } else {
            alert(resp.message);
            self.aadhar = false;
            return;
          }
        }
      };

      xhr.send(formData);
    }
  }

  toRoles() {
    if (localStorage.getItem("token")) {
      if (this.applicantData.accountType === "Team Officials") {
        this.router.navigate(["/id-card/officials"]);
      } else if (this.applicantData.accountType === "Judge") {
        this.router.navigate(["/id-card/judges"]);
      } else if (this.applicantData.accountType === "Coach") {
        this.router.navigate(["/id-card/coach"]);
      } else if (this.applicantData.accountType === "Player") {
        this.router.navigate(["/id-card/archer"]);
      }
    } else {
      this.router.navigate(["/role-select"]);
    }
  }
  toHome(){
    this.router.navigateByUrl("/");
    window.scroll(0,0);
  }

  openVerifyModel(){
    const emailControl = this.archerFormGroup.get('email');
    emailControl?.markAsTouched();
    emailControl?.updateValueAndValidity();

    if (emailControl?.valid) {
      let data = { 'email': this.archerFormGroup.controls["email"].value}
      const url = this.global.basePath + "user/otp-send-for-emailverification";
        this.global.PostRequestUnauthorised(url, data).subscribe(
          (res) => {
            this.emailVerifyModel = true;
            this.messageService.add({
              severity: "success",
              summary: "Message",
              detail: res[0].json.msg,
              sticky: false,
            });
          },
          (err) => {
            this.messageService.add({
              severity: "error",
              summary: "Message",
              detail: err.error.msg,
              sticky: false,
            });
          }
        );
    } else {
      console.log('Email is invalid');
    }
  }

  closeVerifyModel(){
    this.emailVerifyModel = false;
    this.codeSubmited = false;
    this.emailVerificationFormGroup.reset();
  }

  emailVerificationForm() {
    this.emailVerificationFormGroup = this.formBuilder.group({
      verificationCode: [
        '',
        [
          Validators.required,
          Validators.pattern('^\\d{6}$') // Pattern for exactly 6 digits
        ]
      ]
    });
  }

  get v() {
    return this.emailVerificationFormGroup.controls;
  }

  onVerifyCode(){
    this.codeSubmited = true;
    if (this.emailVerificationFormGroup.valid) {
      let data = { 'otp': this.emailVerificationFormGroup.controls["verificationCode"].value,
        'email': this.archerFormGroup.controls["email"].value
      }
      const url = this.global.basePath + "user/email-otp-verify";
        this.global.PostRequestUnauthorised(url, data).subscribe(
          (res) => {
            this.isEmailVerified = true;
            this.emailVerifyModel = false;
            this.messageService.add({
              severity: "success",
              summary: "Message",
              detail: res[0].json.msg,
              sticky: false,
            });
          },
          (err) => {
            this.messageService.add({
              severity: "error",
              summary: "Message",
              detail: err.error.msg,
              sticky: false,
            });
          }
        );
    }
  }

  isEmailInvalid(): boolean {
    const emailControl = this.archerFormGroup.get('email');
    return emailControl?.touched && emailControl?.invalid;
  } 
}
