import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  FormArray,
} from "@angular/forms";
import { Router } from "@angular/router";
import { GlobalService } from "../../global-service";
import { MessageService } from "primeng/api";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app-team-official-register",
  templateUrl: "./team-official-register.component.html",
  styleUrls: [
    "./team-official-register.component.css",
    "../../../assets/theme/form.css",
  ],
})
export class TeamOfficialRegisterComponent implements OnInit {
  teamFormGroup: FormGroup;
  submitted = false;
  ImageFile: any;
  joinedAs: any;
  errorData = false;
  StateList: any = [];
  baseImageUrl: any;
  imageUpload: boolean = false;
  signatureFile: any = "";
  disableButton: boolean = false;
  ResumeFormat: any;
  termsModel: boolean = false;
  checked: boolean = false;
  memberAssociation: any = [];
  eyeShow: boolean = false;
  logSpinner: boolean = false;
  signatureNewFile: any = "";
  signatureFormat: any;
  signature: boolean = false;

  aadharNewFile: any = "";
  aadharFormat: any;
  aadhar: boolean = false;
  applicantData: any;
  emailVerificationFormGroup:FormGroup;
  emailVerifyModel: boolean = false;
  codeSubmited:boolean = false;
  isEmailVerified:boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    public global: GlobalService,
    private messageService: MessageService,
    private http: HttpClient
  ) {
    this.joinedAs = [
      { label: "Select", value: "" },
      { label: "Team Manager", value: "Team Manager" },
      { label: "Physiotherapist", value: "Physiotherapist" },
      {
        label: "Psychologist or Mental Trainer",
        value: "Psychologist or Mental Trainer",
      },
      { label: "Masseur", value: "Masseur" },
      { label: "Others", value: "Others" },
    ];
  }

  ngOnInit(): void {
    this.termsModel = true;
    this.emailVerificationForm();
    this.baseImageUrl = this.global.basePathImage;
    this.FanForm();
    this.getStateList();
    this.getMemberAssociation();
    this.addEducation();
    this.addProfession();
    const token = localStorage.getItem("token");
    if (token) {
      const userData: any = JSON.parse(localStorage.getItem("userInfo"));
			console.log("TCL: TeamOfficialRegisterComponent -> userData", userData)
      const type = userData.accountType;
			console.log("TCL: TeamOfficialRegisterComponent -> type", type)
      console.log("token exist.....");
      this.getArcherDetails(type);
    }
  }

  getArcherDetails(type: any) {
    const url = this.global.basePath + "user/getdeatil?accountType=" + type;
    this.global.GetRequest(url).subscribe((res) => {
      const data = res[0].json.data;
      this.applicantData = data;
      console.log(
        "TCL: CoachRegisterComponent -> getArcherDetails -> this.applicantData",
        this.applicantData
      );
      this.setFormData();
    });
  }

  setFormData() {
    this.isEmailVerified = true;
    this.teamFormGroup.get("fullName").setValue(this.applicantData.fullName);
    // this.teamFormGroup
    //   .get("memberAssociation")
    //   .setValue(this.applicantData.memberAssociation._id);
    this.teamFormGroup.get("phone").setValue(this.applicantData.phone);
    this.teamFormGroup.get("email").setValue(this.applicantData.email);
    this.teamFormGroup.get("password").setValue("null");

    this.teamFormGroup.controls["fullName"].disable();
    // this.teamFormGroup.controls["memberAssociation"].disable();
    this.teamFormGroup.controls["phone"].disable();
    this.teamFormGroup.controls["email"].disable();
    this.teamFormGroup.controls["password"].disable();
  }

  getMemberAssociation() {
    const url = this.global.basePath + "common/stateunion";
    this.global.GetRequest(url).subscribe((res) => {
      const array = res[0].json.data;
      for (const element of array) {
        this.memberAssociation.push({
          label: element.name,
          value: element._id,
        });
      }
    });
  }
  togglePassword() {
    this.eyeShow = !this.eyeShow;
  }

  getStateList() {
    const url = this.global.basePath + "common/state";
    this.global.GetRequest(url).subscribe((res) => {
      const array = res[0].json.data;
      for (const element of array) {
        this.StateList.push({ label: element.name, value: element.name });
      }
    });
  }

  FanForm() {
    this.teamFormGroup = this.formBuilder.group({
      fullName: ["", Validators.required],
      fatherName: ["", [Validators.required]],
      memberAssociation: ["", [Validators.required]],
      dob: ["", [Validators.required]],
      phone: [
        "",
        [
          Validators.required,
          Validators.min(1000000000),
          Validators.max(9999999999),
          Validators.pattern("^[0-9]*$"),
        ],
      ],

      email: [
        "",
        [
          Validators.required,
          Validators.pattern(
            /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
          ),
        ],
      ],

      joinedAs: ["", [Validators.required]],
      password: ["", [Validators.required]],
      profileImage: ["", [Validators.required]],

      educationQualification: this.formBuilder.array([]),
      professionalQualification: this.formBuilder.array([]),
      signatureLink: ["", [Validators.required]],

      aadharCard: ["", [Validators.required]],
      aadharImage: ["", [Validators.required]],
    });
  }

  get f() {
    return this.teamFormGroup.controls;
  }

  // education and Professional
  educational(): FormArray {
    return this.teamFormGroup.get("educationQualification") as FormArray;
  }
  newEducation(): FormGroup {
    return this.formBuilder.group({
      name: "",
    });
  }
  addEducation() {
    this.educational().push(this.newEducation());
  }
  deleteEducation(index) {
    const educational = this.teamFormGroup.controls
      .educationQualification as FormArray;
    educational.removeAt(index);
  }

  professional(): FormArray {
    return this.teamFormGroup.get("professionalQualification") as FormArray;
  }
  newProfession(): FormGroup {
    return this.formBuilder.group({
      name: "",
    });
  }
  addProfession() {
    this.professional().push(this.newEducation());
  }
  deleteProfession(index) {
    const professional = this.teamFormGroup.controls
      .professionalQualification as FormArray;
    professional.removeAt(index);
  }
  // education and Professional

  onSubmit() {
    let url1:any
    this.submitted = true;
    this.teamFormGroup.controls["profileImage"].setValue(this.signatureFile);
    this.teamFormGroup.controls["signatureLink"].setValue(
      this.signatureNewFile
    );
    this.teamFormGroup.controls["aadharImage"].setValue(this.aadharNewFile);

    if (this.teamFormGroup.invalid) {
      return;
    } else {
      // this.termsModel = true;
      // this.disableButton = true;
      this.logSpinner = true;
      const data = this.teamFormGroup.getRawValue();

      const token = localStorage.getItem("token");
      if(token){
         url1 =
        this.global.base_path_api() +
        "user/teamofficialregister?oldId=" +
        this.applicantData._id;
      }
      else{
         url1 =
        this.global.base_path_api() +
        "user/teamofficialregister?oldId=" +
        null;
      }

      this.global.PostRequestUnauthorised(url1, data).subscribe(
        (res) => {
          const response = res[0].json.payload;
          this.logSpinner = false;
          this.teamFormGroup.reset();
          this.submitted = false;
          // this.disableButton = false;
          this.imageUpload = false;
          this.signatureFile = "";
          // this.termsModel = false;
          if (localStorage.getItem("token")) {
            if (this.applicantData.accountType === "Team Officials") {
              this.router.navigate(["/id-card/officials"]);
            } else if (this.applicantData.accountType === "Judge") {
              this.router.navigate(["/id-card/judges"]);
            } else if (this.applicantData.accountType === "Coach") {
              this.router.navigate(["/id-card/coach"]);
            } else if (this.applicantData.accountType === "Player") {
              this.router.navigate(["/id-card/archer"]);
            }
          } else {
            this.router.navigate(["/status"]);
          }
        },
        (err) => {
          if (err) {
            this.errorData = true;
            this.logSpinner = false;
            this.messageService.add({
              severity: "error",
              summary: "Message",
              detail: err.error.msg,
              sticky: true,
            });
            this.submitted = false;
          }
        }
      );
    }
  }

  fileChange(files: any) {
    console.log("TeamOfficialRegisterComponent -> fileChange -> files", files);
    const file = files[0];
    const url = this.global.base_path_api() + "common/upload";
    if (file) {
      const formData = new FormData();
      formData.append("img", file);
      const xhr = new XMLHttpRequest();
      xhr.open("POST", url, true);
      const self = this;
      xhr.onload = function () {
        if (xhr.status === 200) {
          const resp = JSON.parse(xhr.response);
          self.signatureFile = resp.name;
          self.ResumeFormat = resp.fileType;

          if (resp.name !== "") {
            self.imageUpload = true;
          } else {
            alert(resp.message);
            self.imageUpload = false;
            return;
          }
        }
      };

      xhr.send(formData);
    }
  }

  UploadSignature(files: any) {
    const file = files[0];
    const url = this.global.base_path_api() + "common/upload";
    if (file) {
      const formData = new FormData();
      formData.append("img", file);
      const xhr = new XMLHttpRequest();
      xhr.open("POST", url, true);
      const self = this;
      xhr.onload = function () {
        if (xhr.status === 200) {
          const resp = JSON.parse(xhr.response);
          self.signatureNewFile = resp.name;
          if (resp.name !== "") {
            self.signature = true;
            self.signatureFormat = resp.fileType;
          } else {
            alert(resp.message);
            self.signature = false;
            return;
          }
        }
      };

      xhr.send(formData);
    }
  }

  UploadAadhar(files: any) {
    console.log("in upload area");
    const file = files[0];
    const url = this.global.base_path_api() + "common/upload";
    if (file) {
      const formData = new FormData();
      formData.append("img", file);
      const xhr = new XMLHttpRequest();
      xhr.open("POST", url, true);
      const self = this;
      xhr.onload = function () {
        if (xhr.status === 200) {
          const resp = JSON.parse(xhr.response);
          self.aadharNewFile = resp.name;
          console.log(
            "TeamOfficialRegisterComponent -> xhr.onload -> self.aadharNewFile",
            self.aadharNewFile
          );
          if (resp.name !== "") {
            self.aadhar = true;
            self.aadharFormat = resp.fileType;
          } else {
            alert(resp.message);
            self.aadhar = false;
            return;
          }
        }
      };

      xhr.send(formData);
    }
  }

  checkIfAccepted(event) {
    this.checked = event.checked;
  }
  submitRegister() {
    this.termsModel = false;
  }
  toRoles() {
    if (localStorage.getItem("token")) {
      if (this.applicantData.accountType === "Team Officials") {
        this.router.navigate(["/id-card/officials"]);
      } else if (this.applicantData.accountType === "Judge") {
        this.router.navigate(["/id-card/judges"]);
      } else if (this.applicantData.accountType === "Coach") {
        this.router.navigate(["/id-card/coach"]);
      } else if (this.applicantData.accountType === "Player") {
        this.router.navigate(["/id-card/archer"]);
      }
    } else {
      this.router.navigate(["/role-select"]);
    }
  }
  toHome(){
    this.router.navigateByUrl("/");
    window.scroll(0,0);
  }
  
  openVerifyModel(){
    const emailControl = this.teamFormGroup.get('email');
    emailControl?.markAsTouched();
    emailControl?.updateValueAndValidity();

    if (emailControl?.valid) {
      let data = { 'email': this.teamFormGroup.controls["email"].value}
      const url = this.global.basePath + "user/otp-send-for-emailverification";
        this.global.PostRequestUnauthorised(url, data).subscribe(
          (res) => {
            this.emailVerifyModel = true;
            this.messageService.add({
              severity: "success",
              summary: "Message",
              detail: res[0].json.msg,
              sticky: false,
            });
          },
          (err) => {
            this.messageService.add({
              severity: "error",
              summary: "Message",
              detail: err.error.msg,
              sticky: false,
            });
          }
        );
    } else {
      console.log('Email is invalid');
    }
  }

  closeVerifyModel(){
    this.emailVerifyModel = false;
    this.codeSubmited = false;
    this.emailVerificationFormGroup.reset();
  }

  emailVerificationForm() {
    this.emailVerificationFormGroup = this.formBuilder.group({
      verificationCode: [
        '',
        [
          Validators.required,
          Validators.pattern('^\\d{6}$') // Pattern for exactly 6 digits
        ]
      ]
    });
  }

  get v() {
    return this.emailVerificationFormGroup.controls;
  }

  onVerifyCode(){
    this.codeSubmited = true;
    if (this.emailVerificationFormGroup.valid) {
      let data = { 'otp': this.emailVerificationFormGroup.controls["verificationCode"].value,
        'email': this.teamFormGroup.controls["email"].value
      }
      const url = this.global.basePath + "user/email-otp-verify";
        this.global.PostRequestUnauthorised(url, data).subscribe(
          (res) => {
            this.isEmailVerified = true;
            this.emailVerifyModel = false;
            this.messageService.add({
              severity: "success",
              summary: "Message",
              detail: res[0].json.msg,
              sticky: false,
            });
          },
          (err) => {
            this.messageService.add({
              severity: "error",
              summary: "Message",
              detail: err.error.msg,
              sticky: false,
            });
          }
        );
    }
  }

  isEmailInvalid(): boolean {
    const emailControl = this.teamFormGroup.get('email');
    return emailControl?.touched && emailControl?.invalid;
  }
}
