<div class="Light-nav">
    <div class="logo-wrapper">
        <img src="assets/icons/Logo.JPG" alt=''>
    </div>
    <div class="Light-nav-content">
        <div class="Light-support-wrap">
            <h2 class="Light-nav-heading">Archery association of India</h2>
            <img class="Light-head-support" src="assets/icons/target.svg" alt=''>
        </div>
        <p class="Light-nav-text" style="margin-top: 20px;">Archery Association of India came into existence in 1973,
            with the primary objective to organize, encourage, and promote the game of Archery in the country by
            providing proper training facilities to Indian Archers.
        </p>
    </div>
</div>
<div class="page-head-section">
    <p>Tournament</p>
</div>
<div class="logout-button-row">
    <button (click)="logout()" class="logout">Logout</button>
</div>
<div class="event-area ">
    <div class="events-row">
        <div class="event-card" *ngFor="let data of campaignData">
            <div class="ui-g">
                <div class="ui-g-5 ui-lg-5 ui-sm-12 padZero">
                    <div class="event-card-banner"
                        [style.backgroundImage]="'url(' + global?.basePathImage  + data?.tournamentLogo + ')'"></div>
                </div>
                <div class="ui-g-5 ui-lg-5 ui-sm-12 padZero">
                    <div class="event-wrap">
                        <div class="event-flex">
                            <!-- <img class="events-logo" [src]="global?.basePathImage + data?.tournamentLogo" alt="" /> -->
                            <p class="event-title">
                                {{data?.name}}
                            </p>
                        </div>
                        <p class="event-description">
                            From Date : {{data.fromDate | date:'mediumDate'}}
                        </p>
                        <p class="event-description">
                            To Date : {{data.toDate | date:'mediumDate'}}
                        </p>
                        <p class="event-description">
                            Deadline Date : {{data.deadlineDate | date:'mediumDate'}}
                        </p>
                        <div class="min-flex" style="margin-top:30px">
                            <button (click)="tournamentDetails(data._id)" class="details-event">Tournament Details
                            </button>
                        </div>
                    </div>
                </div>
                <div class="ui-g-2 ui-lg-2 ui-sm-12 padZero">
                    <!-- <img class="upload-image" src="assets/images/pdfdownload.png" /> -->
                    <a class="upload-image" [href]="global?.basePathImage  + data?.tournamentPdf"><img class="upload-image"
                            src="assets/images/pdfdownload.png" style="width:100px;height:100px;" /></a>
                </div>
            </div>

        </div>
        <div class="event-card" *ngIf="campaignData.length == 0">
            <h3>Tournament list not found</h3>
        </div>
    </div>
</div>