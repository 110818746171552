<div class="Light-nav">
    <div class="logo-wrapper">
        <img src="assets/icons/Logo.JPG" alt=''>
    </div>
    <div class="Light-nav-content">
        <div class="Light-support-wrap">
            <h2 class="Light-nav-heading">Archery association of India</h2>
            <img class="Light-head-support" src="assets/icons/target.svg" alt=''>
        </div>
        <p class="Light-nav-text" style="margin-top: 20px;">Archery Association of India came into existence in 1973,
            with the primary objective to organize, encourage, and promote the game of Archery in the country by
            providing proper training facilities to Indian Archers.
        </p>
    </div>
</div>


<div class="Applicant-wrapper">
    <div class="name-admin-wrap">
        <p class="admin-name">Welcome, {{adminName}}</p>
        <p class="admin-sub-text">Here you can manage all the request for the registration. </p>
    </div>
    <div class="flex-opt">
        <div class="drop-wrap">
            <p-dropdown (onChange)="setRole($event)" [options]="Roles" [(ngModel)]="selectedRole"
                placeholder="Select Role" [editable]="false"></p-dropdown>
        </div>
        <div class="row">
            <a routerLink="/tournament" class="logout"> Tournament </a>
            <button (click)="logout()" class="logout">Logout</button>
        </div>
    </div>

    <router-outlet> </router-outlet>

</div>