import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { GlobalService } from "../global-service";
import { MessageService } from "primeng/api";

@Component({
  selector: "app-archer-detail",
  templateUrl: "./archer-detail.component.html",
  styleUrls: [
    "./archer-detail.component.css",
    "../../assets/theme/detail.css",
  ],
})
export class ArcherDetailComponent implements OnInit {
  loader: boolean = false;
  applicantId: any;
  applicantData: any;
  reasonText: any = "";
  rejectModel: boolean = false;
  noReasonFound: boolean = false;
  rejectUser: any;
  imageShow: any;
  imageModel: boolean = false;

  constructor(
    private router: Router,
    public global: GlobalService,
    private route: ActivatedRoute,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.applicantId = this.route.snapshot.paramMap.get("id");
    this.getArcherDetails(this.applicantId);
  }

  backToApproval(){
    this.router.navigate(['/admin-approval/archer']);
  }

  getArcherDetails(id) {
    this.loader = true;
    const url =
      this.global.basePath + "union/accountdetail?id=" + id + "&accType=Player";
    this.global.GetRequest(url).subscribe((res) => {
      const data = res[0].json.data;
      this.applicantData = data;
      console.log("ArcherDetailComponent -> getArcherDetails -> data", data);

      this.loader = false;
    });
  }

  accept() {
    const data = {
      isActive: true,
      id: this.applicantData._id,
      accType: "Player",
    };

    const url = this.global.base_path_api() + "union/verifyuser";
    this.global.PostRequest(url, data).subscribe(
      (res) => {
        const response = res[0].json;
        this.messageService.add({
          severity: "success",
          summary: "Message",
          detail: "User is Approved successfully!",
          closable: true,
          life: 6000,
        });
        this.getArcherDetails(this.applicantId);
      },
      (err) => {
        if (err) {
        }
      }
    );
  }

  rejectOpenModel() {
    // this.rejectUser = item;
    this.rejectModel = true;
  }
  rejectCloseModel() {
    this.rejectModel = false;
    // this.rejectUser = "";
  }

  reject() {
    if (this.reasonText === "") {
      this.noReasonFound = true;
    } else {
      this.noReasonFound = false;
      const data = {
        isReject: true,
        id: this.applicantData._id,
        rejectReason: this.reasonText,
        accType: "Player",
      };

      const url = this.global.base_path_api() + "union/verifyuser";
      this.global.PostRequest(url, data).subscribe(
        (res) => {
          const response = res[0].json;
          this.messageService.add({
            severity: "success",
            summary: "Message",
            detail: "User is Rejected successfully!",
            closable: true,
            life: 6000,
          });
          this.getArcherDetails(this.applicantId);
          this.rejectModel = false;
        },
        (err) => {
          if (err) {
          }
        }
      );
    }
  }

  reApprove() {
    const data = {
      isActive: true,
      isReject: false,
      id: this.applicantData._id,
      accType: "Player",
      rejectReason: "",
    };

    const url = this.global.base_path_api() + "union/verifyuser";
    this.global.PostRequest(url, data).subscribe(
      (res) => {
        const response = res[0].json;
        this.messageService.add({
          severity: "success",
          summary: "Message",
          detail: "User is reApproved successfully!",
          closable: true,
          life: 6000,
        });
        this.getArcherDetails(this.applicantId);
      },
      (err) => {
        if (err) {
        }
      }
    );
  }
  closeImageModel() {
    this.imageModel = false;
  }
  openImageModel(image) {
    this.imageModel = true;
    this.imageShow = this.global?.basePathImage + image;
  }
}
