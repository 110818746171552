import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AccordionModule } from "primeng/accordion";
import { DialogModule } from "primeng/dialog";
import { TabViewModule } from "primeng/tabview";
import { DropdownModule } from "primeng/dropdown";
import { CalendarModule } from "primeng/calendar";
import { CheckboxModule } from "primeng/checkbox";
import { RadioButtonModule } from "primeng/radiobutton";
import { InputTextareaModule } from "primeng/inputtextarea";
import { ToastModule } from "primeng/toast";
import { CaptchaModule } from "primeng/captcha";
import { MultiSelectModule } from "primeng/multiselect";
import { ProgressBarModule } from "primeng/progressbar";
import { CarouselModule } from "primeng/carousel";
import { TableModule } from "primeng/table";
import {PaginatorModule} from 'primeng/paginator';
@NgModule({
  declarations: [],
  imports: [CommonModule],
  exports: [
    AccordionModule,
    DialogModule,
    TabViewModule,
    DropdownModule,
    CalendarModule,
    CheckboxModule,
    RadioButtonModule,
    InputTextareaModule,
    ToastModule,
    CaptchaModule,
    MultiSelectModule,
    ProgressBarModule,
    CarouselModule,
    TableModule,
    PaginatorModule
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
    };
  }
}
