import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from '../global-service';
@Component({
  selector: 'app-role-select',
  templateUrl: './role-select.component.html',
  styleUrls: ['./role-select.component.css'],
})
export class RoleSelectComponent implements OnInit {
  constructor(private router: Router, public global: GlobalService) {}

  ngOnInit(): void {}
  selectedRole(value) {
    this.router.navigate([value]);
  }
  toLogin(){
    this.router.navigate(['/login']);
  }
  toHome(){
    this.router.navigateByUrl("/");
    window.scroll(0,0);
  }
}
