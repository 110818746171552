<div *ngIf="loading" class="loading">
    <img src="assets/icons/loader.gif" alt="">
</div>
<div class="Light-nav">
    <div class="logo-wrapper">
        <img src="assets/icons/Logo.JPG" alt=''>    
    </div>
    <div class="Light-nav-content">
        <div class="Light-support-wrap">
            <h2 class="Light-nav-heading">Archery association of India</h2>
            <img class="Light-head-support" src="assets/icons/target.svg" alt=''>
        </div>
        <p class="Light-nav-text" style="margin-top: 20px;">Archery Association of India came into existence in 1973,
            with the primary objective to organize, encourage, and promote the game of Archery in the country by
            providing proper training facilities to Indian Archers.
        </p>
    </div>
</div>
<div class="page-head-section page-head-section-text">
    <img class="arrow-left-side" src="assets/icons/white-back-arrow.png" alt='' (click)="backToList()">
    <p>{{tournamentObj?.name}}</p> 
</div>
<div class="layout-wrapper cus-container ">
    <div class="position-relative">
        <div class="logout-button-row logut-buton-tab">
            <button (click)="logout()" class="logout">Logout</button>
        </div>
        <p-tabView>

            <p-tabPanel header="Entries">
                <div class="main-tab-entry">
                    <div class="sub-space-entry-tab">
                        <div class="">
                            <div>
                                <p>Contact Us :{{tournamentObj?.contactUs}}</p><br>
                            </div>
                            <table *ngIf="tournamentObj">
                                <tr>
                                    <th>Categories</th>
                                    <th>Maximum Entries</th>
                                    <th>Final Entries</th>
                                </tr>
                                <tr *ngFor="let category of categoriesArray;">

                                    <td>{{ formatCategoryKey(category.key) }}</td>
                                    <td>{{ category.maximum }}</td>
                                    <td>{{
                                        selectedCategoryValue(category.key)}} &nbsp;&nbsp;<a (click)="openViewDetails(tournamentId,category.key)">
                                            <img style="height: 20px; cursor: pointer" src="assets/icons/blackeye.png" alt="" /></a>&nbsp;&nbsp;<a
                                        *ngIf="checkDate(tournamentObj?.deadlineDate)" (click)="openTournamentDetails(category.key)" ><img
                                        style="height: 20px; cursor: pointer" src="assets/icons/add_icon.svg" alt="" /></a></td>
                                </tr>
                                <tr>
                                    <td>All Entries</td>
                                    <td>{{allEntries}}</td>
                                    <td>{{allFinalEntries }}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            <div>

                </div>
                </p-tabPanel>

            <p-tabPanel header="Transports">

                <form class="custom-form" [formGroup]="addTransportFormGroup" (ngSubmit)="transportOnSubmit()"  *ngIf="checkDate(tournamentObj?.deadlineDate)">
                    <div class="main-3-row">
                        <div class="form-group">
                            <label class="label">Arrival Place <span class="required">*</span></label>
                            <input type="text" formControlName="arrivalPlace" class="form-control">
                            <div *ngIf="submitted" class="invalid-feedback">
                                <div *ngIf="v.arrivalPlace.errors && v.arrivalPlace.errors.required">
                                    Arrival place is required
                                </div>
                                </div>
                        </div>
                        <div class="form-group">
                            <label class="label">Arrival Date And Time <span class="required">*</span></label>
                            <p-calendar class="calender-form" [showTime]="true" inputId="time" formControlName="arrivalDateAndTime"></p-calendar>
                            <div *ngIf="submitted" class="invalid-feedback">
                                <div *ngIf="v.arrivalDateAndTime.errors && v.arrivalDateAndTime.errors.required">
                                    Arrival date and time is required
                                </div>
                                </div>
                        </div>
                        <div class="form-group">
                            <label class="label">Departure Place <span class="required">*</span> </label>
                            <input type="text" class="form-control" formControlName="departurePlace">
                            <div *ngIf="submitted" class="invalid-feedback">
                                <div *ngIf="v.departurePlace.errors && v.departurePlace.errors.required">
                                    Departure place is required
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="label">Departure Date And Time <span class="required">*</span></label>
                            <p-calendar class="calender-form" [showTime]="true" inputId="time" formControlName="departureDateAndTime"></p-calendar>
                            <div *ngIf="submitted" class="invalid-feedback">
                                <div *ngIf="v.departureDateAndTime.errors && v.departureDateAndTime.errors.required">
                                    Departure date and time is required
                                </div>
                                </div>
                        </div>
                    </div>
                    <div class="main-3-row">
                        <div class="form-group">
                            <label class="label"> Categories <span class="required">*</span></label>
                            <p-dropdown placeholder="Select Categories Type" [options]="categoryObj" formControlName="playingCategory"
                                class="dropdown-form"></p-dropdown>
                                <div *ngIf="submitted" class="invalid-feedback">
                                    <div *ngIf="v.playingCategory.errors && v.playingCategory.errors.required">
                                        Please select category
                                    </div>
                                    </div>
                            </div>
                            <div class="form-group">
                                <label class="label">Number Of Persons <span class="required">*</span></label>
                                <input type="number" formControlName="numberOfPersons" class="form-control">
                                <div *ngIf="submitted" class="invalid-feedback">
                                    <div *ngIf="v.numberOfPersons.errors && v.numberOfPersons.errors.required">
                                        Number of persons is required
                                    </div>
                                </div>
                                </div>
                                <div class="form-group">
                            <label class="label"> Transport Type <span class="required">*</span></label>
                            <p-dropdown placeholder="Select Transport Type" [options]="transportTypeObj" formControlName="type"
                                class="dropdown-form" (onChange)="onTransportTypeChange($event)"></p-dropdown>
                                <div *ngIf="submitted" class="invalid-feedback">
                                    <div *ngIf="v.type.errors && v.type.errors.required">
                                        Please select transport type
                                    </div>
                                </div>
                                </div>
                        <div class="form-group" *ngIf="showTicketField">
                            <label class="label"> {{labelName}} </label>
                            <input type="text" formControlName="vehicleNumber" class="form-control">
                        </div>
                        </div>
                        <div class="transport-form-btn">
                            <button class="model-but-save" type="submit" [disabled]="buttonFlag">Submit</button>
                        </div>
                        </form>
                        <table>
                            <tr>
                                <th>Transport Type</th>
                                <th>Vehicle No</th>
                                <th>Number Of Persons</th>
                                <th>Arrival Place</th>
                                <th>Arrival Date And Time</th>
                                <th>Departure Place</th>
                                <th>Departure Date And Time</th>
                                <th>Playing Category</th>
                                <th>Action</th>
                            </tr>
                            <tr *ngFor="let data of transportationList">
                                <td>{{ data.type}}</td>
                                <td>{{ (data.vehicleNumber)? data.vehicleNumber : '-' }}</td>
                                <td>{{ data.numberOfPersons}}</td>
                                <td>{{ data.arrivals.place}}</td>
                                <td>{{ data.arrivals.date | date:'mediumDate'}} {{ data.arrivals.time}}</td>
                                <td>{{ data.departure.place}}</td>
                                <td>{{ data.departure.date | date:'mediumDate'}} {{ data.departure.time}}</td>
                                <td> {{ data.playingCategory}}</td>
                                <td><a (click)="onDeleteTransport(data._id)">
                                    <img style="height: 20px; cursor: pointer"
            src="assets/icons/delete-red.png" alt="" />
        </a></td>
                            </tr>
                            <tr *ngIf="!transportationList">
                                <td colspan="9" style="text-align: center;">No records found</td>
                            </tr>
                        </table>
                        </p-tabPanel>
            <p-tabPanel header="Summary">
                <div class="download-pdf">
                    <button class="model-but-save" (click)="downloadPDF()">Download PDF</button>
                </div>
                <div class="event-card">
                    <div class="state-table-data" style="padding: 10px 20px;" #content>
                        <h3 *ngIf="onlyPdfContent">{{tournamentObj?.name}}</h3> 
                        <h3>{{associationFullName}}</h3>
                        <h4>Entries*</h4>
                        <table class="state-table-main">
                            <tr>
                                <th>Member Id</th>
                                <th>Full Name</th>
                                <th>Father Name</th>
                                <th>Date Of Birth</th>
                                <th>Gender</th>
                                <th>Playing Category</th>
                                <th>Role</th>
                            </tr>
                            <tr *ngFor="let entry of summaryDetails?.entries">
                                <td>{{ entry?.archerId?.memberId }}</td>
                                <td>{{entry?.archerId?.fullName}}</td>
                                <td>{{entry?.archerId?.fatherName}}</td>
                                <td>{{entry?.archerId?.dob | date:'mediumDate'}}</td>
                                <td>{{entry?.archerId?.gender}}</td>
                                <td>{{ entry?.playingCategory}}</td>
                                <td>Archer</td>
                                </tr>
                                <tr *ngFor="let coach of summaryDetails?.CoachEntries">
                                    <td>{{ coach?.coachId?.memberId }}</td>
                                    <td>{{coach?.coachId?.fullName}}</td>
                                    <td> - </td>
                                    <td>{{coach?.coachId?.dob | date:'mediumDate'}}</td>
                                    <td>{{coach?.coachId?.gender}}</td>
                                    <td>{{ coach?.coachCategory}}</td>
                                    <td>{{ coach?.coachId?.accountType}} {{'- '+ coach?.coachId?.phone}}</td>
                                    </tr>
                                    <tr *ngFor="let teamOfficial of summaryDetails?.OtherTeamOfficial">
                                        <td>{{ teamOfficial?.coachId?.memberId }}</td>
                                        <td>{{ teamOfficial?.coachId?.fullName}}</td>
                                        <td> - </td>
                                        <td>{{ teamOfficial?.coachId?.dob | date:'mediumDate'}}</td>
                                        <td> - </td>
                                        <td>{{ teamOfficial?.coachCategory}}</td>
                                        <td>{{ teamOfficial?.coachId?.accountType}} {{'- '+ teamOfficial?.coachId?.phone}}</td>
                                    </tr>
                                    <tr
                                        *ngIf="summaryDetails?.entries.length == 0 && summaryDetails?.CoachEntries.length == 0 && summaryDetails?.OtherTeamOfficial.length == 0">
                                        <td colspan="7" style="text-align: center;">No records Found</td>
                            </tr>
                        </table>
                        <h4>Transportation*</h4>
                        <table class="state-table-main">
                            <tr>
                                <th>Transport Type</th>
                                <th>Vehicle No</th>
                                <th>Number Of Persons</th>
                                <th>Arrival Place</th>
                                <th>Arrival Date And Time</th>
                                <th>Departure Place</th>
                                <th>Departure Date And Time</th>
                                <th>Playing Category</th>
                            </tr>
                            <tr *ngFor="let transport of summaryDetails?.transportation">
                                <td>{{transport.type}}</td>
                                <td>{{(transport.vehicleNumber)? transport.vehicleNumber : '-'}}</td>
                                <td>{{ transport.numberOfPersons}}</td>
                                <td>{{ transport.arrivals.place}}</td>
                                <td>{{ transport.arrivals.date | date:'mediumDate'}} {{ transport.arrivals.time}}</td>
                                <td>{{ transport.departure.place}}</td>
                                <td>{{ transport.departure.date | date:'mediumDate'}} {{ transport.departure.time}}</td>
                                <td> {{ transport.playingCategory}}</td>
                            </tr>
                            <tr *ngIf="summaryDetails?.transportation.length == 0">
                                <td colspan="8" style="text-align: center;">No records found</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </p-tabPanel>


        </p-tabView>
    </div>
</div>

<p-dialog header="Add Entry" [dismissableMask]=true [modal]="true" styleClass="mydialog" [responsive]="true" [minY]="70"
    [maximizable]="true" [baseZIndex]="10000" [(visible)]="addEntryModel">
    <form [formGroup]="addEntryFormGroup">
        <div class="user-login-status">
            <h1>Please Confirm You Want To Add</h1>
            <div class="form-group" *ngIf="isArcher">
                <label class="label full-width-label">Archer List <span class="required">*</span></label>
                <p-dropdown class="full-width-dropdown" placeholder="Select Archer" [options]="archerListObj"
                    (onChange)="selectedArcher($event,'archer')"
                    formControlName="archerId" [filter]="true"></p-dropdown>
                    <div *ngIf="submit" class="invalid-feedback full-width-label">
                        <div *ngIf="f.archerId.errors && f.archerId.errors.required">
                            Please select Archer
                        </div>
                        </div>
                        <p *ngIf="limitMsg" class="invalid-feedback">{{errorMsg}}</p>
            </div>
            <div class="form-group" *ngIf="coachList">
                <label class="label full-width-label"> Coach List <span class="required">*</span></label>
                <p-dropdown class="full-width-dropdown" placeholder="Select Coach" [options]="archerListObj"
                    (onChange)="selectedArcher($event,'coach')"
                    formControlName="coachId" [filter]="true"></p-dropdown>
                    <div *ngIf="submit" class="invalid-feedback full-width-label">
                        <div *ngIf="f.coachId.errors && f.coachId.errors.required">
                            Please select coach
                        </div>
                        </div>
                        <p *ngIf="limitMsg" class="invalid-feedback">{{errorMsg}}</p>
            </div>
            <div class="form-group" *ngIf="teamOfficialList">
                <label class="label full-width-label"> Team Official List <span class="required">*</span></label>
                <p-dropdown class="full-width-dropdown" placeholder="Select Team Official List" [options]="archerListObj"
                    (onChange)="selectedArcher($event,'teamOfficial')" formControlName="coachId" [filter]="true"></p-dropdown>
                    <div *ngIf="submit" class="invalid-feedback full-width-label">
                        <div *ngIf="f.coachId.errors && f.coachId.errors.required">
                            Please select team official
                            </div>
                            </div>
                            <p *ngIf="limitMsg" class="invalid-feedback">{{errorMsg}}</p>
            </div>
            <div class="form-group">
                <label class="label full-width-label" *ngIf="selectedArcherObj?.dob">DOB : {{selectedArcherObj?.dob |
                    date:'mediumDate'}}</label>
            </div>
            <div class="form-group" *ngIf="coachList || teamOfficialList">
                <label class="label full-width-label"> Categories List<span class="required">*</span></label>
                <p-dropdown class="full-width-dropdown" placeholder="Select Category" [options]="categoryObj"
                    formControlName="coachCategory"
                    [filter]="true"></p-dropdown>
                    <div *ngIf="submit" class="invalid-feedback full-width-label">
                        <div *ngIf="f.coachCategory.errors && f.coachCategory.errors.required">
                            Please select category
                        </div>
                        </div>
            </div>
            <p-footer>
                <button class="model-but-save" type="button" (click)="onSubmit()">Confirm</button>
                <button class="model-but-close" style="background: gray;"
                    (click)="closeTournamentDetails()">Close</button>
            </p-footer>
        </div>
    </form>
</p-dialog>

<p-dialog header="View Entry" [dismissableMask]=true [modal]="true" styleClass="mydialog" [responsive]="true"
    [minY]="70" [maximizable]="true" [baseZIndex]="10000" [(visible)]="viewEntryModel">
    <a class="modal-close-button" (click)="closeViewDetails()"><img style="height: 20px; cursor: pointer"
            src="assets/icons/closeblack.png" alt="" /></a>
    <div class="user-login-status">
        <h1>{{ viewCategoryName}} Entries</h1><br>
        <table *ngIf="isArcher">
            <tr>
                <th>Name</th>
                <th>Date Of Birth</th>
                <th>Action</th>
            </tr>
            <tr *ngFor="let entry of listOfEntries">
                <td>{{entry.archerId.memberId}}{{'-'+ entry.archerId.fullName}}</td>
                <td>{{entry.archerId.dob | date:'mediumDate'}}</td>
                <td><a (click)="onDeleteClick(entry._id)">
                        <img style="height: 20px; cursor: pointer" src="assets/icons/delete-red.png" alt="" /></a></td>
            </tr>
        </table>
        <table *ngIf="coachList">
            <tr>
                <th>Name</th>
                <th>Date Of Birth</th>
                <th>Action</th>
            </tr>
            <tr *ngFor="let coach of listOfCoachEntries">
                <td>{{coach.coachId.memberId}}{{'-'+ coach.coachId.fullName}}</td>
                <td>{{coach.coachId.dob | date:'mediumDate'}}</td>
                <td><a (click)="onDeleteClick(coach._id)">
                        <img style="height: 20px; cursor: pointer" src="assets/icons/delete-red.png" alt="" /></a></td>
            </tr>
        </table>
        
        <table *ngIf="teamOfficialList">
            <tr>
                <th>Name</th>
                <th>Date Of Birth</th>
                <th>Action</th>
            </tr>
            <tr *ngFor="let teamOfficial of listOfTeamOfficialData">
                <td>{{teamOfficial.coachId.memberId}}{{'-'+ teamOfficial.coachId.fullName}}</td>
                <td>{{teamOfficial.coachId.dob | date:'mediumDate'}}</td>
                <td><a (click)="onDeleteClick(teamOfficial._id)">
                        <img style="height: 20px; cursor: pointer" src="assets/icons/delete-red.png" alt="" /></a></td>
            </tr>
        </table>

        <table *ngIf="!noRecordFound">
            <tr>
                <th>Name</th>
                <th>Date Of Birth</th>
                <th>Action</th>
            </tr>
            <tr>
                <td colspan="3" style="text-align: center;">No records found</td>
            </tr>
        </table>
    </div>
</p-dialog>