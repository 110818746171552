<p-toast
  [baseZIndex]="900000"
  [preventOpenDuplicates]="false"
  [style]="{ marginTop: '80px' }"
></p-toast>

<div class="Light-nav">
  <div class="logo-wrapper">
    <img (click)="toHome()" style="cursor: pointer;" src="assets/icons/Logo.JPG" alt="" />
  </div>
  <div class="Light-nav-content">
    <div class="Light-support-wrap">
      <h2 class="Light-nav-heading">Archery association of India</h2>
      <img class="Light-head-support" src="assets/icons/target.svg" alt="" />
    </div>
    <p class="Light-nav-text" style="margin-top: 20px">
      Archery Association of India came into existence in 1973, with the primary
      objective to organize, encourage, and promote the game of Archery in the
      country by providing proper training facilities to Indian Archers.
    </p>
  </div>
</div>

<div class="register-wrapper">
  <div class="flex">
    <img
      class="back-Arrow-Register"
      (click)="toRoles()"
      src="assets/icons/backArrow.svg"
    />
    <h2 Class="FormMainTitle">Archer Registration Form</h2>
  </div>

  <div #stickyMenu [class.sticky]="sticky" class="progress-wrap">
    <p>Form completion</p>
    <p-progressBar [value]="value"></p-progressBar>
  </div>

  <form [formGroup]="archerFormGroup" (ngSubmit)="onSubmit()">
    <div class="register-card-wrapper">
      <h1 class="register-card-title">Personal Information</h1>
      <p class="register-card-text">Enter your personal details</p>
      <div class="register-card">
        <div class="ui-g">
          <div class="ui-g-4 ui-lg-4 ui-md-4 ui-sm-12">
            <div class="form-group">
              <label class="label"
                >Full Name <span class="required">*</span></label
              >
              <input
                type="text"
                formControlName="fullName"
                class="form-control-register"
              />
              <div *ngIf="submitted" class="invalid-feedback">
                <div *ngIf="f.fullName.errors && f.fullName.errors.required">
                  Full Name is required
                </div>
              </div>
            </div>
          </div>
          <div class="ui-g-4 ui-lg-4 ui-md-4 ui-sm-12">
            <div class="form-group">
              <label class="label"
                >Email Id <span class="required">*</span></label
              >
              <input
                type="text"
                formControlName="email"
                class="form-control-register"
                [readonly]="isEmailVerified"
              />
              <div *ngIf="submitted" class="invalid-feedback">
                <div *ngIf="f.email.errors && f.email.errors.required">
                  Email is required
                </div>
              </div>
              <div class="invalid-feedback" *ngIf="f.email?.errors?.pattern">
                Email must be a valid email address
              </div>
              <div *ngIf="isEmailInvalid()" class="invalid-feedback">
                Email is required
              </div>
            </div>
          </div>

          <div class="ui-g-4 ui-lg-4 ui-md-4 ui-sm-12">
            <div class="form-group">
              <label class="label"
                >Gender <span class="required">*</span></label
              >
              <div class="drop-area">
                <p-dropdown
                  [options]="genderList"
                  formControlName="gender"
                ></p-dropdown>
              </div>
              <div *ngIf="submitted" class="invalid-feedback">
                <div *ngIf="f.gender.errors && f.gender.errors.required">
                  Gender is required
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="ui-g">
          <div class="ui-g-4 ui-lg-4 ui-md-4 ui-sm-12">
            <div class="form-group">
              <label class="label"
                >Father Name <span class="required">*</span></label
              >
              <input
                type="text"
                formControlName="fatherName"
                class="form-control-register"
              />
              <div *ngIf="submitted" class="invalid-feedback">
                <div
                  *ngIf="f.fatherName.errors && f.fatherName.errors.required"
                >
                  Father Name is required
                </div>
              </div>
            </div>
          </div>
          <div class="ui-g-4 ui-lg-4 ui-md-4 ui-sm-12">
            <div class="form-group">
              <label class="label"
                >Mother Name <span class="required">*</span></label
              >
              <input
                type="text"
                formControlName="motherName"
                class="form-control-register"
              />
              <div *ngIf="submitted" class="invalid-feedback">
                <div
                  *ngIf="f.motherName.errors && f.motherName.errors.required"
                >
                  Mother name is required
                </div>
              </div>
            </div>
          </div>
          <div class="ui-g-4 ui-lg-4 ui-md-4 ui-sm-12">
            <div class="form-group" style="position: relative">
              <label class="label"
                >Password <span class="required">*</span></label
              >
              <input
                [type]="eyeShow ? 'text' : 'password'"
                formControlName="password"
                class="form-control-register"
              />

              <img
                class="eye"
                *ngIf="!eyeShow"
                src="assets/icons/eye.svg"
                (click)="togglePassword()"
                alt=""
              />
              <img
                class="eye"
                *ngIf="eyeShow"
                src="assets/icons/sight.svg"
                (click)="togglePassword()"
                alt=""
              />

              <div *ngIf="submitted" class="invalid-feedback">
                <div *ngIf="f.password.errors && f.password.errors.required">
                  Password is required
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="ui-g">
          <div class="ui-g-4 ui-lg-4 ui-md-4 ui-sm-12">
            <div class="form-group">
              <label class="label"
                >Date of birth <span class="required">*</span></label
              >
              <div class="calendar">
                <p-calendar
                  dateFormat="dd/mm/yy"
                  [yearNavigator]="true"
                  yearRange="1940:2024"
                  formControlName="dob"
                >
                </p-calendar>
              </div>
              <div *ngIf="submitted" class="invalid-feedback">
                <div *ngIf="f.dob.errors && f.dob.errors.required">
                  Dob is required
                </div>
              </div>
            </div>
          </div>

          <div class="ui-g-4 ui-lg-4 ui-md-4 ui-sm-12">
            <div class="form-group">
              <label class="label">Phone <span class="required">*</span></label>
              <!-- <input type="number" formControlName="phone" class="form-control-register" /> -->

              <div id="input-wrapper">
                <label for="number">+91 - </label>
                <input
                  type="number"
                  formControlName="phone"
                  class="form-control-register"
                />
              </div>

              <div *ngIf="submitted" class="invalid-feedback">
                <div *ngIf="f.phone.errors && f.phone.errors.required">
                  Number is required
                </div>
              </div>
              <div
                class="invalid-feedback"
                *ngIf="f.phone?.errors?.min || f.phone?.errors?.max"
              >
                Please enter a 10 digit mobile number
              </div>
            </div>
          </div>

          <div class="ui-g-4 ui-lg-4 ui-md-4 ui-sm-12">
            <div class="form-group">
              <label class="label">Alternate Phone</label>
              <!-- <input type="number" formControlName="alternatePhone" class="form-control-register" /> -->
              <div id="input-wrapper">
                <label for="number">+91 - </label>
                <input
                  type="number"
                  formControlName="alternatePhone"
                  class="form-control-register"
                />
              </div>
              <div
                class="invalid-feedback"
                *ngIf="
                  f.alternatePhone?.errors?.min || f.alternatePhone?.errors?.max
                "
              >
                Please enter a 10 digit mobile number
              </div>
            </div>
          </div>
        </div>
        <div class="ui-g">
          <div class="ui-g-4 ui-lg-4 ui-md-4 ui-sm-12">
            <div class="form-group">
              <label class="label"
                >Marital Status <span class="required">*</span></label
              >
              <div class="drop-area">
                <p-dropdown
                  [options]="maritalStatus"
                  formControlName="maritalStatus"
                ></p-dropdown>
              </div>
              <div *ngIf="submitted" class="invalid-feedback">
                <div
                  *ngIf="
                    f.maritalStatus.errors && f.maritalStatus.errors.required
                  "
                >
                  Marital Status required
                </div>
              </div>
            </div>
          </div>

          <div class="ui-g-4 ui-lg-4 ui-md-4 ui-sm-12">
            <div class="form-group">
              <label class="label">WhatsApp Number</label>
              <!-- <input
                type="number"
                formControlName="WhatsAppNo"
                class="form-control-register"
              /> -->
              <div id="input-wrapper">
                <label for="number">+91 - </label>
                <input
                  type="number"
                  formControlName="WhatsAppNo"
                  class="form-control-register"
                />
              </div>
              <div *ngIf="submitted" class="invalid-feedback">
                <div
                  *ngIf="f.WhatsAppNo.errors && f.WhatsAppNo.errors.required"
                >
                  WhatsApp Number is required
                </div>
              </div>
              <div
                class="invalid-feedback"
                *ngIf="f.WhatsAppNo?.errors?.min || f.WhatsAppNo?.errors?.max"
              >
                Please enter a 10 digit mobile number
              </div>
            </div>
          </div>
          <div class="ui-g-4 ui-lg-4 ui-md-4 ui-sm-12">
            <div class="form-group">
              <label class="label"
                >Is Minor <span class="required">*</span></label
              >
              <div class="drop-area">
                <p-dropdown
                  [options]="isMinor"
                  (onChange)="minorTest($event)"
                  formControlName="isMinor"
                ></p-dropdown>
              </div>
              <div *ngIf="submitted" class="invalid-feedback">
                <div *ngIf="f.isMinor.errors && f.isMinor.errors.required">
                  Is Minor required
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="isMinorCheck" class="ui-g">
          <div class="ui-g-4 ui-lg-4 ui-md-4 ui-sm-12">
            <div class="form-group">
              <label class="label"
                >Parents Phone <span class="required">*</span>
              </label>
              <div id="input-wrapper">
                <label for="number">+91 - </label>
                <input
                  type="number"
                  formControlName="parentsPhone"
                  class="form-control-register"
                />
              </div>

              <div *ngIf="submitted" class="invalid-feedback">
                <div
                  *ngIf="
                    f.parentsPhone.errors && f.parentsPhone.errors.required
                  "
                >
                  Parents Phone is required
                </div>
              </div>
              <div
                class="invalid-feedback"
                *ngIf="
                  f.parentsPhone?.errors?.min || f.parentsPhone?.errors?.max
                "
              >
                Please enter a 10 digit mobile number
              </div>
            </div>
          </div>
          <div class="ui-g-4 ui-lg-4 ui-md-4 ui-sm-12">
            <div class="form-group">
              <label class="label"
                >Parents Email Id <span class="required">*</span></label
              >
              <input
                type="text"
                formControlName="parentsEmail"
                class="form-control-register"
              />
              <div *ngIf="submitted" class="invalid-feedback">
                <div
                  *ngIf="
                    f.parentsEmail.errors && f.parentsEmail.errors.required
                  "
                >
                  Parents Email is required
                </div>
              </div>
              <div
                class="invalid-feedback"
                *ngIf="f.parentsEmail?.errors?.pattern"
              >
                Email must be a valid email address
              </div>
            </div>
          </div>
        </div>

        <div class="ui-g">
          <div class="ui-g-4 ui-lg-4 ui-md-4 ui-sm-12">
            <div class="form-group">
              <label class="label"
                >Aadhar Card Number <span class="required">*</span></label
              >
              <input
                type="number"
                formControlName="aadharCard"
                class="form-control-register"
              />
              <div *ngIf="submitted" class="invalid-feedback">
                <div *ngIf="f.aadharCard?.errors?.required">
                  Aadhar Card is required
                </div>
              </div>
            </div>
          </div>

          <div class="ui-g-4 ui-lg-4 ui-md-4 ui-sm-12">
            <p class="sub-text">
              Upload Aadhar card image <span class="required">*</span>
            </p>
            <div class="file-info-wrapper">
              <div class="upload-wrap-form">
                <label for="fileInputAadhar">
                  <img
                    *ngIf="!aadhar"
                    class="upload-image"
                    id="AdharIcon"
                    src="assets/icons/browse.svg"
                  />
                  <img
                    *ngIf="aadhar && aadharFormat === 'image'"
                    class="upload-image"
                    id="AdharIcon"
                    [src]="global?.basePathImage + aadharNewFile"
                  />
                  <img
                    *ngIf="aadhar && aadharFormat === 'document'"
                    class="upload-image"
                    id="AdharIcon"
                    src="assets/icons/docIcon.svg"
                  />
                </label>

                <input
                  accept=".png,.jpg"
                  id="fileInputAadhar"
                  type="file"
                  #fileuploadAadhar
                  (change)="UploadAadhar(fileuploadAadhar.files)"
                />
              </div>
              <div class="file-info">
                <p>Maximum upload File Size:<strong> 3MB </strong></p>
                <p>File type Supported:<strong> .png, .Jpg </strong></p>
              </div>
            </div>

            <div
              *ngIf="submitted"
              class="invalid-feedback"
              style="margin-left: 8px"
            >
              <div
                *ngIf="f.aadharImage.errors && f.aadharImage.errors.required"
              >
                Required
              </div>
            </div>
          </div>
        </div>
        <p
          *ngIf="mainValidation"
          class="invalid-feedback"
          style="text-align: right"
        >
          {{ mainMessage }}
        </p>
      </div>
      <div *ngIf="!addressInfo && isEmailVerified" (click)="openAddress()" class="next-button">
        Next
      </div>
      <div *ngIf="!isEmailVerified" class="next-button" (click)="openVerifyModel()">
        Send Otp
      </div>
    </div>

    <div *ngIf="addressInfo" class="register-card-wrapper">
      <h1 class="register-card-title">Address Information</h1>
      <p class="register-card-text">Enter your address details</p>
      <div class="register-card">
        <ng-container formGroupName="presentAddress">
          <p class="sub-text">Enter Present Address</p>

          <div class="ui-g">
            <div class="ui-g-4 ui-lg-4 ui-md-4 ui-sm-12">
              <div class="form-group">
                <label class="label"
                  >House No <span class="required">*</span></label
                >
                <input
                  type="text"
                  formControlName="houseNo"
                  class="form-control-register"
                />
                <div
                  *ngIf="submitted && pre.houseNo.errors"
                  class="invalid-feedback"
                >
                  <div
                    *ngIf="pre.houseNo.errors && pre.houseNo.errors.required"
                  >
                    House No is required
                  </div>
                </div>
              </div>
            </div>

            <div class="ui-g-4 ui-lg-4 ui-md-4 ui-sm-12">
              <div class="form-group">
                <label class="label"
                  >Street <span class="required">*</span></label
                >
                <input
                  type="text"
                  formControlName="street"
                  class="form-control-register"
                />
                <div
                  *ngIf="submitted && pre.street.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="pre.street.errors && pre.street.errors.required">
                    Street is required
                  </div>
                </div>
              </div>
            </div>

            <div class="ui-g-4 ui-lg-4 ui-md-4 ui-sm-12">
              <div class="form-group">
                <label class="label"
                  >Landmark <span class="required">*</span></label
                >
                <input
                  type="text"
                  formControlName="landmark"
                  class="form-control-register"
                />
                <div
                  *ngIf="submitted && pre.landmark.errors"
                  class="invalid-feedback"
                >
                  <div
                    *ngIf="pre.landmark.errors && pre.landmark.errors.required"
                  >
                    Landmark is required
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="ui-g">
            <div class="ui-g-4 ui-lg-4 ui-md-4 ui-sm-12">
              <div class="form-group">
                <label class="label"
                  >City <span class="required">*</span></label
                >
                <input
                  type="text"
                  formControlName="city"
                  class="form-control-register"
                />
                <div
                  *ngIf="submitted && pre.city.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="pre.city.errors && pre.city.errors.required">
                    City is required
                  </div>
                </div>
              </div>
            </div>
            <div class="ui-g-4 ui-lg-4 ui-md-4 ui-sm-12">
              <div class="form-group">
                <label class="label"
                  >District <span class="required">*</span></label
                >
                <input
                  type="text"
                  formControlName="district"
                  class="form-control-register"
                />
                <div
                  *ngIf="submitted && pre.district.errors"
                  class="invalid-feedback"
                >
                  <div
                    *ngIf="pre.district.errors && pre.district.errors.required"
                  >
                    District is required
                  </div>
                </div>
              </div>
            </div>

            <div class="ui-g-4 ui-lg-4 ui-md-4 ui-sm-12">
              <div class="form-group">
                <label class="label"
                  >State <span class="required">*</span></label
                >
                <div class="drop-area">
                  <p-dropdown
                    placeholder="Select"
                    [options]="StateList"
                    formControlName="state"
                  >
                  </p-dropdown>
                </div>
                <!-- <input type="text" formControlName="state" placeholder="state"
                                    class="form-control-register" /> -->
                <div
                  *ngIf="submitted && pre.state.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="pre.state.errors && pre.state.errors.required">
                    State is required
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="ui-g">
            <div class="ui-g-4 ui-lg-4 ui-md-4 ui-sm-12">
              <div class="form-group">
                <label class="label"
                  >Pin Code <span class="required">*</span></label
                >
                <input
                  type="number"
                  formControlName="pinCode"
                  class="form-control-register"
                />
                <div
                  *ngIf="submitted && pre.pinCode.errors"
                  class="invalid-feedback"
                >
                  <div
                    *ngIf="pre.pinCode.errors && pre.pinCode.errors.required"
                  >
                    PinCode is required
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <div style="margin: 20px 0 40px 0">
          <p-checkbox
            [formControl]="isPresentSameAsPermanent"
            binary="true"
            label="Permanent address same as Present Address"
          ></p-checkbox>
        </div>

        <ng-container formGroupName="permanentAddress">
          <p class="sub-text">
            Enter Permanent Address <span class="required">*</span>
          </p>

          <div class="ui-g">
            <div class="ui-g-4 ui-lg-4 ui-md-4 ui-sm-12">
              <div class="form-group">
                <label class="label"
                  >House No <span class="required">*</span></label
                >
                <input
                  type="text"
                  formControlName="houseNo"
                  class="form-control-register"
                />
                <div *ngIf="submitted" class="invalid-feedback">
                  <div
                    *ngIf="
                      permanent.houseNo.errors &&
                      permanent.houseNo.errors.required
                    "
                  >
                    House No is required
                  </div>
                </div>
              </div>
            </div>

            <div class="ui-g-4 ui-lg-4 ui-md-4 ui-sm-12">
              <div class="form-group">
                <label class="label"
                  >Street <span class="required">*</span></label
                >
                <input
                  type="text"
                  formControlName="street"
                  class="form-control-register"
                />
                <div
                  *ngIf="submitted && pre.street.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="pre.street.errors && pre.street.errors.required">
                    Street is required
                  </div>
                </div>
              </div>
            </div>

            <div class="ui-g-4 ui-lg-4 ui-md-4 ui-sm-12">
              <div class="form-group">
                <label class="label"
                  >Landmark <span class="required">*</span></label
                >
                <input
                  type="text"
                  formControlName="landmark"
                  class="form-control-register"
                />
                <div
                  *ngIf="submitted && pre.landmark.errors"
                  class="invalid-feedback"
                >
                  <div
                    *ngIf="pre.landmark.errors && pre.landmark.errors.required"
                  >
                    Landmark is required
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="ui-g">
            <div class="ui-g-4 ui-lg-4 ui-md-4 ui-sm-12">
              <div class="form-group">
                <label class="label"
                  >City <span class="required">*</span></label
                >
                <input
                  type="text"
                  formControlName="city"
                  class="form-control-register"
                />
                <div *ngIf="submitted" class="invalid-feedback">
                  <div
                    *ngIf="
                      permanent.city.errors && permanent.city.errors.required
                    "
                  >
                    City is required
                  </div>
                </div>
              </div>
            </div>
            <div class="ui-g-4 ui-lg-4 ui-md-4 ui-sm-12">
              <div class="form-group">
                <label class="label"
                  >District <span class="required">*</span></label
                >
                <input
                  type="text"
                  formControlName="district"
                  class="form-control-register"
                />
                <div *ngIf="submitted" class="invalid-feedback">
                  <div
                    *ngIf="
                      permanent.district.errors &&
                      permanent.district.errors.required
                    "
                  >
                    District is required
                  </div>
                </div>
              </div>
            </div>

            <div class="ui-g-4 ui-lg-4 ui-md-4 ui-sm-12">
              <div class="form-group">
                <label class="label"
                  >State <span class="required">*</span></label
                >
                <div class="drop-area">
                  <p-dropdown
                    placeholder="Select"
                    [options]="StateList"
                    formControlName="state"
                  >
                  </p-dropdown>
                </div>

                <div *ngIf="submitted" class="invalid-feedback">
                  <div
                    *ngIf="
                      permanent.state.errors && permanent.state.errors.required
                    "
                  >
                    State is required
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="ui-g">
            <div class="ui-g-4 ui-lg-4 ui-md-4 ui-sm-12">
              <div class="form-group">
                <label class="label"
                  >Pin Code <span class="required">*</span></label
                >
                <input
                  type="number"
                  formControlName="pinCode"
                  class="form-control-register"
                />
                <div *ngIf="submitted" class="invalid-feedback">
                  <div
                    *ngIf="
                      permanent.pinCode.errors &&
                      permanent.pinCode.errors.required
                    "
                  >
                    PinCode is required
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <!-- <p
          *ngIf="secondValidation"
          class="invalid-feedback"
          style="text-align: right;"
        >
          Please fill all the required fields first.
        </p> -->
      </div>
      <div *ngIf="!eduBox" (click)="openEduBox()" class="next-button">Next</div>
    </div>

    <div *ngIf="eduBox">
      <div class="register-card-wrapper">
        <h1 class="register-card-title">Education Details</h1>
        <p class="register-card-text">Enter your School/ College details</p>
        <div class="register-card">
          <div style="margin: 20px 0 40px 0">
            <p class="new-true">Are you studying in school / college ?</p>

            <span style="margin-right: 20px">
              <p-radioButton
                (click)="changeEducation('true')"
                name="group1"
                value="true"
                label="Yes"
                [formControl]="archerFormGroup.controls['isStudying']"
                inputId="opt1"
              ></p-radioButton>
            </span>

            <p-radioButton
              (click)="changeEducation('false')"
              name="group1"
              value="false"
              label="No"
              [formControl]="archerFormGroup.controls['isStudying']"
              inputId="opt2"
            >
            </p-radioButton>

            <div *ngIf="submitted" class="invalid-feedback">
              <div
                *ngIf="
                  archerFormGroup.controls['isStudying'].errors &&
                  archerFormGroup.controls['isStudying'].errors.required
                "
              >
                Required
              </div>
            </div>
          </div>

          <div *ngIf="isEducationVal">
            <ng-container formGroupName="collegeDetails">
              <div class="ui-g">
                <div class="ui-g-4 ui-lg-4 ui-md-4 ui-sm-12">
                  <div class="form-group">
                    <label class="label"
                      >Name of School/ College
                      <span class="required">*</span></label
                    >
                    <input
                      type="text"
                      formControlName="collegeName"
                      class="form-control-register"
                    />
                    <div *ngIf="submitted" class="invalid-feedback">
                      <div
                        *ngIf="
                          edu.collegeName.errors &&
                          edu.collegeName.errors.required
                        "
                      >
                        Name is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="ui-g-4 ui-lg-4 ui-md-4 ui-sm-12">
                  <div class="form-group">
                    <label class="label"
                      >Street Address /Landmark
                      <span class="required">*</span></label
                    >
                    <input
                      type="text"
                      formControlName="houseNo"
                      class="form-control-register"
                    />
                    <div *ngIf="submitted" class="invalid-feedback">
                      <div
                        *ngIf="
                          edu.houseNo.errors && edu.houseNo.errors.required
                        "
                      >
                        House No is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="ui-g-4 ui-lg-4 ui-md-4 ui-sm-12">
                  <div class="form-group">
                    <label class="label"
                      >City <span class="required">*</span></label
                    >
                    <input
                      type="text"
                      formControlName="city"
                      class="form-control-register"
                    />
                    <div *ngIf="submitted" class="invalid-feedback">
                      <div *ngIf="edu.city.errors && edu.city.errors.required">
                        City is required
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="ui-g">
                <div class="ui-g-4 ui-lg-4 ui-md-4 ui-sm-12">
                  <div class="form-group">
                    <label class="label"
                      >Which standard are you studying?
                      <span class="required">*</span></label
                    >
                    <input
                      type="text"
                      formControlName="standard"
                      class="form-control-register"
                    />
                    <div *ngIf="submitted" class="invalid-feedback">
                      <div
                        *ngIf="
                          edu.standard.errors && edu.standard.errors.required
                        "
                      >
                        Standard is required
                      </div>
                    </div>
                  </div>
                </div>

                <div class="ui-g-4 ui-lg-4 ui-md-4 ui-sm-12">
                  <div class="form-group">
                    <label class="label"
                      >District <span class="required">*</span></label
                    >
                    <input
                      type="text"
                      formControlName="district"
                      class="form-control-register"
                    />
                    <div *ngIf="submitted" class="invalid-feedback">
                      <div
                        *ngIf="
                          edu.district.errors && edu.district.errors.required
                        "
                      >
                        District is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="ui-g-4 ui-lg-4 ui-md-4 ui-sm-12">
                  <div class="form-group">
                    <label class="label"
                      >State <span class="required">*</span></label
                    >
                    <div class="drop-area">
                      <p-dropdown
                        placeholder="Select"
                        [options]="StateList"
                        formControlName="state"
                      >
                      </p-dropdown>
                    </div>
                    <div
                      *ngIf="submitted && pre.state.errors"
                      class="invalid-feedback"
                    >
                      <div
                        *ngIf="edu.state.errors && edu.state.errors.required"
                      >
                        State is required
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="ui-g">
                <div class="ui-g-4 ui-lg-4 ui-md-4 ui-sm-12">
                  <div class="form-group">
                    <label class="label"
                      >Pin Code <span class="required">*</span></label
                    >
                    <input
                      type="number"
                      formControlName="pinCode"
                      class="form-control-register"
                    />
                    <div *ngIf="submitted" class="invalid-feedback">
                      <div
                        *ngIf="
                          edu.pinCode.errors && edu.pinCode.errors.required
                        "
                      >
                        PinCode is required
                      </div>
                    </div>
                  </div>
                </div>

                <div class="ui-g-4 ui-lg-4 ui-md-4 ui-sm-12">
                  <div class="form-group" style="position: relative">
                    <div style="display: 'flex'">
                      <label class="label">Educational Qualification </label>
                      <div class="add-qualification" (click)="addEducation()">
                        Add More
                      </div>
                    </div>
                    <div
                      formArrayName="educationQualification"
                      *ngFor="
                        let quantity of educational().controls;
                        let i = index
                      "
                    >
                      <div [formGroupName]="i">
                        <input
                          type="text"
                          formControlName="name"
                          class="form-control-register add-qualification-input"
                        />
                        <p
                          *ngIf="i > 0"
                          class="RemoveFormArray"
                          (click)="deleteEducation(i)"
                        >
                          Remove
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="ui-g-4 ui-lg-4 ui-md-4 ui-sm-12">
                  <div class="form-group" style="position: relative">
                    <div style="display: 'flex'">
                      <label class="label">Professional Qualification </label>
                      <div class="add-qualification" (click)="addProfession()">
                        Add More
                      </div>
                    </div>
                    <div
                      formArrayName="professionalQualification"
                      *ngFor="
                        let quantity of professional().controls;
                        let i = index
                      "
                    >
                      <div [formGroupName]="i">
                        <input
                          type="text"
                          formControlName="name"
                          class="form-control-register add-qualification-input"
                        />
                        <p
                          *ngIf="i > 0"
                          class="RemoveFormArray"
                          (click)="deleteProfession(i)"
                        >
                          Remove
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>

      <div class="register-card-wrapper">
        <h1 class="register-card-title">Employment Details</h1>
        <p class="register-card-text">Enter your Employment details</p>
        <div class="register-card">
          <div style="margin: 20px 0 40px 0">
            <p class="new-true">Are you employed ?</p>
            <span style="margin-right: 20px">
              <p-radioButton
                (click)="changeEmploy('true')"
                name="group2"
                value="true"
                label="Yes"
                [formControl]="archerFormGroup.controls['isEmployed']"
                inputId="opt3"
              ></p-radioButton>
            </span>

            <p-radioButton
              (click)="changeEmploy('false')"
              name="group2"
              value="false"
              label="No"
              [formControl]="archerFormGroup.controls['isEmployed']"
              inputId="opt4"
            >
            </p-radioButton>

            <div *ngIf="submitted" class="invalid-feedback">
              <div
                *ngIf="
                  archerFormGroup.controls['isEmployed'].errors &&
                  archerFormGroup.controls['isEmployed'].errors.required
                "
              >
                Required
              </div>
            </div>
          </div>

          <div *ngIf="isEmployedVal">
            <ng-container formGroupName="employmentDetails">
              <div class="ui-g">
                <div class="ui-g-4 ui-lg-4 ui-md-4 ui-sm-12">
                  <div class="form-group">
                    <label class="label"
                      >Name of the Organisation / Institution
                      <span class="required">*</span></label
                    >
                    <input
                      type="text"
                      formControlName="organisationName"
                      class="form-control-register"
                    />
                    <div *ngIf="submitted" class="invalid-feedback">
                      <div
                        *ngIf="
                          employment.organisationName.errors &&
                          employment.organisationName.errors.required
                        "
                      >
                        Name is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="ui-g-4 ui-lg-4 ui-md-4 ui-sm-12">
                  <div class="form-group">
                    <label class="label"
                      >Designation <span class="required">*</span></label
                    >
                    <input
                      type="text"
                      formControlName="designation"
                      class="form-control-register"
                    />
                    <div *ngIf="submitted" class="invalid-feedback">
                      <div
                        *ngIf="
                          employment.designation.errors &&
                          employment.designation.errors.required
                        "
                      >
                        Designation is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="ui-g-4 ui-lg-4 ui-md-4 ui-sm-12">
                  <div class="form-group">
                    <label class="label"
                      >Place of Posting <span class="required">*</span></label
                    >
                    <input
                      type="text"
                      formControlName="postingPLace"
                      class="form-control-register"
                    />
                    <div *ngIf="submitted" class="invalid-feedback">
                      <div
                        *ngIf="
                          employment.postingPLace.errors &&
                          employment.postingPLace.errors.required
                        "
                      >
                        City is required
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="ui-g">
                <div class="ui-g-4 ui-lg-4 ui-md-4 ui-sm-12">
                  <div class="form-group">
                    <label class="label"
                      >Date of Joining <span class="required">*</span></label
                    >
                    <div class="calendar">
                      <p-calendar
                        dateFormat="dd/mm/yy"
                        [yearNavigator]="true"
                        yearRange="1940:2024"
                        formControlName="joining"
                      >
                      </p-calendar>
                    </div>
                    <div *ngIf="submitted" class="invalid-feedback">
                      <div
                        *ngIf="
                          employment.joining.errors &&
                          employment.joining.errors.required
                        "
                      >
                        Year of Joining is required
                      </div>
                    </div>
                  </div>
                </div>

                <div class="ui-g-4 ui-lg-4 ui-md-4 ui-sm-12">
                  <div class="form-group">
                    <label class="label"
                      >Street Address /Landmark
                      <span class="required">*</span></label
                    >
                    <input
                      type="text"
                      formControlName="streetAddress"
                      class="form-control-register"
                    />
                    <div *ngIf="submitted" class="invalid-feedback">
                      <div
                        *ngIf="
                          employment.streetAddress.errors &&
                          employment.streetAddress.errors.required
                        "
                      >
                        Street Address required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="ui-g-4 ui-lg-4 ui-md-4 ui-sm-12">
                  <div class="form-group">
                    <label class="label"
                      >City <span class="required">*</span></label
                    >
                    <input
                      type="text"
                      formControlName="city"
                      class="form-control-register"
                    />
                    <div *ngIf="submitted" class="invalid-feedback">
                      <div
                        *ngIf="
                          employment.city.errors &&
                          employment.city.errors.required
                        "
                      >
                        City is required
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="ui-g">
                <div class="ui-g-4 ui-lg-4 ui-md-4 ui-sm-12">
                  <div class="form-group">
                    <label class="label"
                      >District <span class="required">*</span></label
                    >
                    <input
                      type="text"
                      formControlName="district"
                      class="form-control-register"
                    />
                    <div *ngIf="submitted" class="invalid-feedback">
                      <div
                        *ngIf="
                          employment.district.errors &&
                          employment.district.errors.required
                        "
                      >
                        District is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="ui-g-4 ui-lg-4 ui-md-4 ui-sm-12">
                  <div class="form-group">
                    <label class="label"
                      >State <span class="required">*</span></label
                    >
                    <div class="drop-area">
                      <p-dropdown
                        placeholder="Select"
                        [options]="StateList"
                        formControlName="state"
                      >
                      </p-dropdown>
                    </div>
                    <div *ngIf="submitted" class="invalid-feedback">
                      <div
                        *ngIf="
                          employment.state.errors &&
                          employment.state.errors.required
                        "
                      >
                        State is required
                      </div>
                    </div>
                  </div>
                </div>

                <div class="ui-g-4 ui-lg-4 ui-md-4 ui-sm-12">
                  <div class="form-group">
                    <label class="label"
                      >Pin Code <span class="required">*</span></label
                    >
                    <input
                      type="number"
                      formControlName="pinCode"
                      class="form-control-register"
                    />
                    <div *ngIf="submitted" class="invalid-feedback">
                      <div
                        *ngIf="
                          employment.pinCode.errors &&
                          employment.pinCode.errors.required
                        "
                      >
                        Pin code is required
                      </div>
                    </div>
                  </div>
                </div>

                <!-- <div class="ui-g-4 ui-lg-4 ui-md-4 ui-sm-12">
                                    <div class="form-group" style='position:relative'>
                                        <div style="display:'flex'">
                                            <label class="label">Professional Qualification </label>
                                            <div class="add-qualification" (click)="addProfession()">Add More</div>
                                        </div>
                                        <div formArrayName="professionalQualification"
                                            *ngFor="let quantity of professional().controls; let i = index">
                                            <div [formGroupName]="i">
                                                <input type="text" formControlName="name" 
                                                    class="form-control-register add-qualification-input" />
                                                <p *ngIf="i > 0" class="RemoveFormArray" (click)="deleteProfession(i)">
                                                    Remove</p>
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
              </div>
            </ng-container>
          </div>
          <!-- <p
            *ngIf="thirdValidation"
            class="invalid-feedback"
            style="text-align: right;"
          >
            Please fill all the required fields first.
          </p> -->
        </div>
        <div *ngIf="!coachBox" (click)="openCoachBox()" class="next-button">
          Next
        </div>
      </div>
    </div>

    <div *ngIf="coachBox">
      <div class="register-card-wrapper">
        <h1 class="register-card-title">Category and Participation Details</h1>
        <p class="register-card-text">Enter your details</p>
        <div class="register-card">
          <div class="ui-g">
            <div class="ui-g-4 ui-lg-4 ui-md-4 ui-sm-12">
              <div class="form-group">
                <label class="label"
                  >AAI Member State Associations/Units
                  <span class="required">*</span>
                </label>
                <div class="drop-area">
                  <p-dropdown
                    placeholder="Select"
                    [options]="memberAssociation"
                    formControlName="memberAssociation"
                  >
                  </p-dropdown>
                </div>
                <div *ngIf="submitted" class="invalid-feedback">
                  <div
                    *ngIf="
                      f.memberAssociation.errors &&
                      f.memberAssociation.errors.required
                    "
                  >
                    Required
                  </div>
                </div>
              </div>
            </div>
            <div class="ui-g-4 ui-lg-4 ui-md-4 ui-sm-12">
              <div class="form-group">
                <label class="label"
                  >Category <span class="required">*</span></label
                >
                <div class="drop-area">
                  <p-dropdown
                    [options]="playingCategory"
                    formControlName="playingCategory"
                  >
                  </p-dropdown>
                </div>
                <div *ngIf="submitted" class="invalid-feedback">
                  <div
                    *ngIf="
                      f.playingCategory.errors &&
                      f.playingCategory.errors.required
                    "
                  >
                    Required
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="register-card-wrapper">
        <h1 class="register-card-title">Beginning Coach and Training Place</h1>
        <p class="register-card-text">
          Please give details of 'BEGINNING' Coach and training Place
        </p>
        <div class="register-card">
          <ng-container formGroupName="trainingDetails">
            <div class="ui-g">
              <div class="ui-g-4 ui-lg-4 ui-md-4 ui-sm-12">
                <div class="form-group">
                  <label class="label"
                    >Name of Training Place <span class="required">*</span>
                  </label>
                  <input
                    type="text"
                    formControlName="trainingPlaceName"
                    class="form-control-register"
                  />
                  <div *ngIf="submitted" class="invalid-feedback">
                    <div
                      *ngIf="
                        training.trainingPlaceName.errors &&
                        training.trainingPlaceName.errors.required
                      "
                    >
                      Name is required
                    </div>
                  </div>
                </div>
              </div>
              <div class="ui-g-4 ui-lg-4 ui-md-4 ui-sm-12">
                <div class="form-group">
                  <label class="label"
                    >Name of Coach <span class="required">*</span></label
                  >
                  <input
                    type="text"
                    formControlName="coachName"
                    class="form-control-register"
                  />
                  <div *ngIf="submitted" class="invalid-feedback">
                    <div
                      *ngIf="
                        training.coachName.errors &&
                        training.coachName.errors.required
                      "
                    >
                      Coach is required
                    </div>
                  </div>
                </div>
              </div>
              <div class="ui-g-4 ui-lg-4 ui-md-4 ui-sm-12">
                <div class="form-group">
                  <label class="label"
                    >Coach Contact No. <span class="required">*</span>
                  </label>
                  <!-- <input
                    type="number"
                    formControlName="coachPhone"
                    class="form-control-register"
                  /> -->
                  <div id="input-wrapper">
                    <label for="number">+91 - </label>
                    <input
                      type="number"
                      formControlName="coachPhone"
                      class="form-control-register"
                    />
                  </div>
                  <div *ngIf="submitted" class="invalid-feedback">
                    <div
                      *ngIf="
                        training.coachPhone.errors &&
                        training.coachPhone.errors.required
                      "
                    >
                      Coach Contact No. is required
                    </div>
                  </div>
                  <div
                    class="invalid-feedback"
                    *ngIf="
                      training.coachPhone?.errors?.min ||
                      training.coachPhone?.errors?.max
                    "
                  >
                    Please enter a 10 digit mobile number
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <div class="register-card-wrapper">
        <h1 class="register-card-title">Current Coach and Training Place</h1>
        <p class="register-card-text">
          Please give details of 'CURRENT' Coach and Training Place
        </p>
        <div class="register-card">
          <ng-container formGroupName="currentTrainingDetails">
            <div class="ui-g">
              <div class="ui-g-4 ui-lg-4 ui-md-4 ui-sm-12">
                <div class="form-group">
                  <label class="label"
                    >Name of Training Place <span class="required">*</span>
                  </label>
                  <input
                    type="text"
                    formControlName="trainingPlaceName"
                    class="form-control-register"
                  />
                  <div *ngIf="submitted" class="invalid-feedback">
                    <div
                      *ngIf="
                        currentTraining.trainingPlaceName.errors &&
                        currentTraining.trainingPlaceName.errors.required
                      "
                    >
                      Name is required
                    </div>
                  </div>
                </div>
              </div>
              <div class="ui-g-4 ui-lg-4 ui-md-4 ui-sm-12">
                <div class="form-group">
                  <label class="label"
                    >Name of Coach <span class="required">*</span></label
                  >
                  <input
                    type="text"
                    formControlName="coachName"
                    class="form-control-register"
                  />
                  <div *ngIf="submitted" class="invalid-feedback">
                    <div
                      *ngIf="
                        currentTraining.coachName.errors &&
                        currentTraining.coachName.errors.required
                      "
                    >
                      Coach is required
                    </div>
                  </div>
                </div>
              </div>
              <div class="ui-g-4 ui-lg-4 ui-md-4 ui-sm-12">
                <div class="form-group">
                  <label class="label"
                    >Coach Contact No. <span class="required">*</span>
                  </label>
                  <!-- <input
                    type="number"
                    formControlName="coachPhone"
                    class="form-control-register"
                  /> -->
                  <div id="input-wrapper">
                    <label for="number">+91 - </label>
                    <input
                      type="number"
                      formControlName="coachPhone"
                      class="form-control-register"
                    />
                  </div>
                  <div *ngIf="submitted" class="invalid-feedback">
                    <div
                      *ngIf="
                        currentTraining.coachPhone.errors &&
                        currentTraining.coachPhone.errors.required
                      "
                    >
                      Coach Contact No. is required
                    </div>
                  </div>
                  <div
                    class="invalid-feedback"
                    *ngIf="
                      currentTraining.coachPhone?.errors?.min ||
                      currentTraining.coachPhone?.errors?.max
                    "
                  >
                    Please enter a 10 digit mobile number
                  </div>
                </div>
              </div>
            </div>

            <div class="ui-g">
              <div class="ui-g-4 ui-lg-4 ui-md-4 ui-sm-12">
                <div class="form-group">
                  <label class="label"
                    >Date you started training with the current Coach?
                    <span class="required">*</span></label
                  >
                  <div class="calendar">
                    <p-calendar
                      dateFormat="dd/mm/yy"
                      [yearNavigator]="true"
                      yearRange="1940:2024"
                      formControlName="joinYear"
                    >
                    </p-calendar>
                  </div>

                  <div *ngIf="submitted" class="invalid-feedback">
                    <div
                      *ngIf="
                        currentTraining.joinYear.errors &&
                        currentTraining.joinYear.errors.required
                      "
                    >
                      Please mention year.
                    </div>
                  </div>
                </div>
              </div>

              <div class="ui-g-4 ui-lg-4 ui-md-4 ui-sm-12">
                <div class="form-group">
                  <label class="label">Coach Email Id </label>
                  <input
                    type="text"
                    formControlName="email"
                    class="form-control-register"
                  />
                  <div *ngIf="submitted" class="invalid-feedback">
                    <div
                      *ngIf="
                        currentTraining.email.errors &&
                        currentTraining.email.errors.required
                      "
                    >
                      Email is required
                    </div>
                  </div>
                  <div
                    class="invalid-feedback"
                    *ngIf="currentTraining.email?.errors?.pattern"
                  >
                    Email must be a valid email address
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <!-- <p
            *ngIf="fourthValidation"
            class="invalid-feedback"
            style="text-align: right;"
          >
            Please fill all the required fields first.
          </p> -->
        </div>
        <div *ngIf="!ageProof" (click)="openAgeProof()" class="next-button">
          Next
        </div>
      </div>
    </div>

    <div *ngIf="ageProof" class="register-card-wrapper">
      <h1 class="register-card-title">
        Proof of Age <span class="required">*</span>
      </h1>
      <p class="register-card-text">
        Mandatory for all applicant to upload either of document
      </p>
      <div class="register-card">
        <div class="ui-g">
          <div *ngFor="let data of proofOfAge; let i = index" class="ui-g-12">
            <p-radioButton
              name="group34"
              value="{{ data.value }}"
              label="{{ data.label }}"
              formControlName="ageProofDoc"
              inputId="{{ i + 'optionI' }}"
            >
            </p-radioButton>
          </div>
        </div>
        <div style="margin: 20px 0 40px 0">
          <p class="sub-text">
            Upload Age Proof <span class="required">*</span>
          </p>
          <div class="file-info-wrapper">
            <div class="upload-wrap-form">
              <label for="fileInputAge">
                <img
                  *ngIf="!age"
                  class="upload-image"
                  id="AgeIcon"
                  src="assets/icons/browse.svg"
                />
                <img
                  *ngIf="age && ageFormat === 'image'"
                  class="upload-image"
                  id="AgeIcon"
                  [src]="global?.basePathImage + AgeFile"
                />
                <img
                  *ngIf="age && ageFormat === 'document'"
                  class="upload-image"
                  id="AgeIcon"
                  src="assets/icons/docIcon.svg"
                />
              </label>

              <input
                accept=".pdf,.png,.jpg"
                id="fileInputAge"
                type="file"
                #fileuploadAge
                (change)="uploadAgeFile(fileuploadAge.files)"
              />
            </div>
            <div class="file-info">
              <p>Maximum upload File Size:<strong> 3MB </strong></p>
              <p>File type Supported:<strong> .png, .Jpg, .pdf </strong></p>
            </div>
          </div>

          <div
            *ngIf="submitted"
            class="invalid-feedback"
            style="margin-left: 8px"
          >
            <div
              *ngIf="
                f.ageProofDocLink.errors && f.ageProofDocLink.errors.required
              "
            >
              Required
            </div>
          </div>
        </div>
        <!-- <p
          *ngIf="fifthValidation"
          class="invalid-feedback"
          style="text-align: right;"
        >
          Please fill all the required fields first.
        </p> -->
      </div>
      <div
        *ngIf="!residenceProof"
        (click)="openResidenceProof()"
        class="next-button"
      >
        Next
      </div>
    </div>

    <div *ngIf="residenceProof" class="register-card-wrapper">
      <h1 class="register-card-title">Proof of Residence</h1>
      <p class="register-card-text">
        In case place of birth & place of present stay different
      </p>
      <div class="register-card">
        <div style="margin: 20px 0 40px 0">
          <div style="margin: 20px 0 40px 0">
            <p class="new-true">
              Is your place of birth & place of present stay is different?
            </p>

            <span style="margin-right: 20px">
              <p-radioButton
                (click)="changeIsBirth('true')"
                name="group3"
                value="true"
                label="Yes"
                [formControl]="
                  archerFormGroup.controls['isBirthPlaceSameAsPresentPlace']
                "
                inputId="opt5"
              ></p-radioButton>
            </span>

            <p-radioButton
              (click)="changeIsBirth('false')"
              name="group3"
              value="false"
              label="No"
              [formControl]="
                archerFormGroup.controls['isBirthPlaceSameAsPresentPlace']
              "
              inputId="opt6"
            >
            </p-radioButton>
            <div *ngIf="submitted" class="invalid-feedback">
              <div
                *ngIf="
                  archerFormGroup.controls['isBirthPlaceSameAsPresentPlace']
                    .errors &&
                  archerFormGroup.controls['isBirthPlaceSameAsPresentPlace']
                    .errors.required
                "
              >
                Required
              </div>
            </div>
          </div>
        </div>
        <!-- <div *ngIf='isSameBirth'>
                    <p class="sub-text">Proof of Residence : In case place of birth & place of present stay is different
                    </p>

                    <div class="ui-g">
                        <div *ngFor="let data of otherResidenceProofDoc; let i=index" class="ui-g-12">
                            <p-radioButton name="groupTeen1" value="{{data.value}}" label={{data.label}}
                                formControlName="otherResidenceProofDoc" inputId="i">
                            </p-radioButton>
                        </div>
                    </div>
                    <div style="margin:20px 0 40px 0">
                        <p class="sub-text">Upload Residence Proof <span class="required">*</span> </p>
                        <div class="file-info-wrapper">
                            <div class="upload-wrap-form">
                                <label for="fileInputOtherResidence">
                                    <img *ngIf="!otherResidence" class="upload-image" id="OtherResidenceIcon"
                                        src="assets/icons/browse.svg" />
                                    <img *ngIf="otherResidence && otherResidenceFormat==='image'" class="upload-image"
                                        id="OtherResidenceIcon" [src]=" global?.basePathImage + otherResidenceFile" />

                                    <img *ngIf="otherResidence && otherResidenceFormat ==='document'"
                                        class="upload-image" id="OtherResidenceIcon" src="assets/icons/docIcon.svg" />

                                </label>

                                <input accept=".pdf,.png,.jpg" id="fileInputOtherResidence" type="file"
                                    #fileuploadOtherResidence
                                    (change)="uploadOtherResidence(fileuploadOtherResidence.files)" />
                            </div>
                            <div class="file-info">
                                <p>Maximum upload File Size:<strong> 3MB </strong></p>
                                <p>File type Supported:<strong> .png, .Jpg, .pdf </strong></p>
                            </div>
                        </div>

                        <div *ngIf="submitted" class="invalid-feedback" style="margin-left:8px">
                            <div
                                *ngIf="f.otherResidenceProofDocLink.errors && f.otherResidenceProofDocLink.errors.required">
                                Required
                            </div>
                        </div>
                    </div>
                </div> -->

        <p class="sub-text">
          Proof of Residence <span class="required">*</span>
        </p>
        <p class="register-card-text" style="margin: 0 7px">
          Mandatory for all applicant to upload either of document – In case
          place of birth & present stay is same
        </p>

        <div class="ui-g">
          <div
            *ngFor="let data of residenceProofDocArray; let i = index"
            class="ui-g-12"
          >
            <p-radioButton
              name="groupTeen"
              value="{{ data.value }}"
              label="{{ data.label }}"
              formControlName="residenceProofDoc"
              inputId="{{ i + 'radioId' }}"
            >
            </p-radioButton>
            <div *ngIf="submitted" class="invalid-feedback">
              <div
                *ngIf="
                  f.residenceProofDoc.errors && f.residenceProofDoc.errors.required
                "
              >
                Required
              </div>
            </div>
          </div>
        </div>
        <div style="margin: 20px 0 40px 0">
          <p class="sub-text">
            Upload Residence Proof Front<span class="required">*</span>
          </p>
          <div class="file-info-wrapper">
            <div class="upload-wrap-form">
              <label for="fileInputResidence">
                <img
                  *ngIf="!residence"
                  class="upload-image"
                  id="ResidenceIcon"
                  src="assets/icons/browse.svg"
                />
                <img
                  *ngIf="residence && residenceFormat === 'image'"
                  class="upload-image"
                  id="ResidenceIcon"
                  [src]="global?.basePathImage + residenceFile"
                />

                <img
                  *ngIf="residence && residenceFormat === 'document'"
                  class="upload-image"
                  id="ResidenceIcon"
                  src="assets/icons/docIcon.svg"
                />
              </label>

              <input
                accept=".pdf,.png,.jpg"
                id="fileInputResidence"
                type="file"
                #fileuploadResidence
                (change)="uploadResidence(fileuploadResidence.files)"
              />
            </div>

            <div class="file-info">
              <p>Maximum upload File Size:<strong> 3MB </strong></p>
              <p>File type Supported:<strong> .png, .Jpg, .pdf </strong></p>
            </div>
          </div>

          <div
            *ngIf="submitted"
            class="invalid-feedback"
            style="margin-left: 8px"
          >
            <div
              *ngIf="
                f.residenceProofDocLink.errors &&
                f.residenceProofDocLink.errors.required
              "
            >
              Required
            </div>
          </div>
        </div>


        <div style="margin: 20px 0 40px 0">
          <p class="sub-text">
            Upload Residence Proof Back<span class="required">*</span>
          </p>
          <div class="file-info-wrapper">
            <div class="upload-wrap-form">
              <label for="fileInputResidenceBackInp">
                <img
                  *ngIf="!residenceBack"
                  class="upload-image"
                  id="ResidenceIconBack"
                  src="assets/icons/browse.svg"
                />
                <img
                  *ngIf="residenceBack && residenceFormatBack === 'image'"
                  class="upload-image"
                  id="ResidenceIconBack"
                  [src]="global?.basePathImage + residenceFileBack"
                />

                <img
                  *ngIf="residenceBack && residenceFormatBack === 'document'"
                  class="upload-image"
                  id="ResidenceIconBack"
                  src="assets/icons/docIcon.svg"
                />
              </label>

              <input
                accept=".pdf,.png,.jpg"
                id="fileInputResidenceBackInp"
                type="file"
                #fileuploadResidenceBack
                (change)="uploadResidenceBack(fileuploadResidenceBack.files)"
              />
            </div>

            <div class="file-info">
              <p>Maximum upload File Size:<strong> 3MB </strong></p>
              <p>File type Supported:<strong> .png, .Jpg, .pdf </strong></p>
            </div>
          </div>

          <div
            *ngIf="submitted"
            class="invalid-feedback"
            style="margin-left: 8px"
          >
            <div
              *ngIf="
                f.residenceProofDocBackLink.errors &&
                f.residenceProofDocBackLink.errors.required
              "
            >
              Required
            </div>
          </div>
        </div>



        <!-- <p
          *ngIf="sixthValidation"
          class="invalid-feedback"
          style="text-align: right;"
        >
          Please fill all the required fields first.
        </p> -->
      </div>
      <div *ngIf="!passportBox" (click)="openPassportBox()" class="next-button">
        Next
      </div>
    </div>

    <div *ngIf="passportBox" class="register-card-wrapper">
      <h1 class="register-card-title">Upload Passport</h1>
      <p class="register-card-text">Upload you Passport</p>
      <div class="register-card ui-g">
        <div class="ui-g-4 ui-lg-4 ui-md-4 ui-sm-12">
          <!-- <p-checkbox (onChange)="changePassport($event)"
                        [formControl]="archerFormGroup.controls['havePassport']" binary="true"
                        label="Do you have Passport"></p-checkbox> -->

          <div style="margin: 20px 0 40px 0">
            <p class="new-true">Do you have Passport ?</p>

            <span style="margin-right: 20px">
              <p-radioButton
                (click)="changePassportVal('true')"
                name="group4"
                value="true"
                label="Yes"
                [formControl]="archerFormGroup.controls['havePassport']"
                inputId="opt7"
              ></p-radioButton>
            </span>

            <p-radioButton
              (click)="changePassportVal('false')"
              name="group4"
              value="false"
              label="No"
              [formControl]="archerFormGroup.controls['havePassport']"
              inputId="opt8"
            >
            </p-radioButton>

            <div *ngIf="submitted" class="invalid-feedback">
              <div
                *ngIf="
                  archerFormGroup.controls['havePassport'].errors &&
                  archerFormGroup.controls['havePassport'].errors.required
                "
              >
                Required
              </div>
            </div>
          </div>

          <div *ngIf="passportVal" style="margin: 20px 0 40px 0">
            <!-- <p class="sub-text">Please upload Front & Back side of passport</p> -->
            <div>
              <p class="sub-text">Please upload passport front</p>
              <div class="file-info-wrapper">
                <div class="upload-wrap-form">
                  <label for="fileInputPassport">
                    <img
                      *ngIf="!passport"
                      class="upload-image"
                      id="PassportIcon"
                      src="assets/icons/browse.svg"
                    />
                    <img
                      *ngIf="passport && passportFormat === 'image'"
                      class="upload-image"
                      id="PassportIcon"
                      [src]="global?.basePathImage + passportFile"
                    />

                    <img
                      *ngIf="passport && passportFormat === 'document'"
                      class="upload-image"
                      id="PassportIcon"
                      src="assets/icons/docIcon.svg"
                    />
                  </label>

                  <input
                    accept=".pdf,.png,.jpg"
                    id="fileInputPassport"
                    type="file"
                    #fileuploadPassport
                    (change)="uploadPassport(fileuploadPassport.files)"
                  />
                </div>
                <div class="file-info">
                  <p>Maximum upload File Size:<strong> 3MB </strong></p>
                  <p>File type Supported:<strong> .png, .Jpg, .pdf </strong></p>
                </div>
              </div>

              <div
                *ngIf="submitted"
                class="invalid-feedback"
                style="margin-left: 8px"
              >
                <div
                  *ngIf="
                    f.passportDocLink.errors &&
                    f.passportDocLink.errors.required
                  "
                >
                  Required
                </div>
              </div>
            </div>

            <div>
              <p class="sub-text">Please upload passport back</p>
              <div class="file-info-wrapper">
                <div class="upload-wrap-form">
                  <label for="fileInputPassportBack">
                    <img
                      *ngIf="!passportBack"
                      class="upload-image"
                      id="PassportIconBack"
                      src="assets/icons/browse.svg"
                    />
                    <img
                      *ngIf="passportBack && passportFormatBack === 'image'"
                      class="upload-image"
                      id="PassportIconBack"
                      [src]="global?.basePathImage + passportFileBack"
                    />

                    <img
                      *ngIf="passportBack && passportFormatBack === 'document'"
                      class="upload-image"
                      id="PassportIconBack"
                      src="assets/icons/docIcon.svg"
                    />
                  </label>

                  <input
                    accept=".pdf,.png,.jpg"
                    id="fileInputPassportBack"
                    type="file"
                    #fileuploadPassportBack
                    (change)="uploadPassportBack(fileuploadPassportBack.files)"
                  />
                </div>
                <div class="file-info">
                  <p>Maximum upload File Size:<strong> 3MB </strong></p>
                  <p>File type Supported:<strong> .png, .Jpg, .pdf </strong></p>
                </div>
              </div>

              <div
                *ngIf="submitted"
                class="invalid-feedback"
                style="margin-left: 8px"
              >
                <div
                  *ngIf="
                    f.passportDocLinkBack.errors &&
                    f.passportDocLinkBack.errors.required
                  "
                >
                  Required
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <p
          *ngIf="seventhValidation"
          class="invalid-feedback"
          style="text-align: right;"
        >
          Please fill all the required fields first.
        </p> -->
      </div>
      <div *ngIf="!photoBox" (click)="openPhotoBox()" class="next-button">
        Next
      </div>
    </div>

    <div *ngIf="photoBox" class="register-card-wrapper">
      <h1 class="register-card-title">Upload Photo and Signature</h1>
      <p class="register-card-text">Upload your Photo and Signature</p>
      <div class="register-card ui-g">
        <div class="ui-g-4 ui-lg-4 ui-md-4 ui-sm-12">
          <p class="sub-text">
            Upload Profile Pic <span class="required">*</span>
          </p>
          <div class="file-info-wrapper">
            <div class="upload-wrap-form">
              <label for="fileInputPic">
                <img
                  *ngIf="!imageUpload"
                  class="upload-image"
                  id="PicIcon"
                  src="assets/icons/browse.svg"
                />
                <img
                  *ngIf="imageUpload && ImageFormat === 'image'"
                  class="upload-image"
                  id="PicIcon"
                  [src]="global?.basePathImage + profileImage"
                />
                <img
                  *ngIf="imageUpload && ImageFormat === 'document'"
                  class="upload-image"
                  id="PicIcon"
                  src="assets/icons/docIcon.svg"
                />
              </label>

              <input
                accept=".png,.jpg"
                id="fileInputPic"
                type="file"
                #fileuploadPic
                (change)="fileChange(fileuploadPic.files)"
              />
            </div>
            <div class="file-info">
              <p>Maximum upload File Size:<strong> 3MB </strong></p>
              <p>File type Supported:<strong> .png, .Jpg </strong></p>
            </div>
          </div>

          <div
            *ngIf="submitted"
            class="invalid-feedback"
            style="margin-left: 8px"
          >
            <div
              *ngIf="f.profileImage.errors && f.profileImage.errors.required"
            >
              Required
            </div>
          </div>
        </div>
        <div class="ui-g-4 ui-lg-4 ui-md-4 ui-sm-12">
          <p class="sub-text">
            Upload Signature <span class="required">*</span>
          </p>
          <div class="file-info-wrapper">
            <div class="upload-wrap-form">
              <label for="fileInputSignature">
                <img
                  *ngIf="!signature"
                  class="upload-image"
                  id="SigIcon"
                  src="assets/icons/browse.svg"
                />
                <img
                  *ngIf="signature && signatureFormat === 'image'"
                  class="upload-image"
                  id="SigIcon"
                  [src]="global?.basePathImage + signatureFile"
                />
                <img
                  *ngIf="signature && signatureFormat === 'document'"
                  class="upload-image"
                  id="SigIcon"
                  src="assets/icons/docIcon.svg"
                />
              </label>

              <input
                accept=".png,.jpg"
                id="fileInputSignature"
                type="file"
                #fileuploadSignature
                (change)="UploadSignature(fileuploadSignature.files)"
              />
            </div>
            <div class="file-info">
              <p>Maximum upload File Size:<strong> 3MB </strong></p>
              <p>File type Supported:<strong> .png, .Jpg </strong></p>
            </div>
          </div>

          <div
            *ngIf="submitted"
            class="invalid-feedback"
            style="margin-left: 8px"
          >
            <div
              *ngIf="f.signatureLink.errors && f.signatureLink.errors.required"
            >
              Required
            </div>
          </div>
        </div>
      </div>
      <div style="text-align: right">
        <button class="authButton saveBut" type="submit">
          <span *ngIf="logSpinner"
            ><i class="pi pi-spin pi-spinner" style="margin-right: 6px"></i
          ></span>
          Submit Details
        </button>
      </div>
    </div>
  </form>
</div>

<p-dialog
  header="User"
  [(visible)]="termsModel"
  [modal]="true"
  styleClass="mydialog"
  [responsive]="true"
  [minY]="70"
  [maximizable]="true"
  [baseZIndex]="10000"
>
  <div class="Terms-conditions">
    <h1>Terms & Conditions</h1>
    <p>
      Declaration from Applicant prior to filling up the Application Form • I
      hereby confirm that I have read and understood the Instructions, rules and
      regulation mentioned hereunder and apply to Archery Association of India
      for Affiliation. • I agree to abide by the terms, conditions, rules,
      regulations and other statutory requirements applicable. • I hereby
      declare that the particulars given herein are true, correct, complete and
      valid to the best of my knowledge and belief and the documents submitted
      along with this application are genuine. I also understand that any wilful
      misinformation may render for refusal of this application or initiate
      punitive action in form of suspensation, debar or termination. • I hereby
      undertake to promptly inform AAI of any changes to the information
      provided and agree and accept that AAI or its EC Members or its member
      State Archery Associations/Associate Members or its host Associations not
      liable or responsible for any losses, costs, damages arising out of any
      actions undertaken or activities performed by them on the basis of the
      information provided by me as also due to delay in intimating such
      changes. • I hereby agree to provide any additional information /
      documentation that may be required, in connection with this application. •
      I understand that AAI, at its sole discretion, may make and amend terms,
      conditions, rules, regulations, format and other requirements with respect
      to affiliation, eligibility, age-verification, inter-state transfer, fee
      payment etc.time to time, as and when required.
      <br />
      <strong>Key Information </strong>
      <br />

      • The ‘Affiliation Portal’ allows Archers, Coaches, Officials (Technical
      Officials, Team Officials, Others) for affiliation who wish to participate
      in Championships, Games, International and National Tournaments being
      recognized or controlled by World Archery or Archery Association of India
      or its Member Associations. Applicant shall seek affiliation from AAI by
      making payment of an ‘Affiliation Fee’ on an annual calendar year basis,
      at least, two months prior to the end of each calendar year. Such
      affiliation shall be validated/ confirmed within one month thereafter by
      the Member Association of AAI who he/she represents. • The Registration
      shall remain applicable for the annual calendar year, provided there is no
      disciplinary action initiated against such person. ‘Team Officials’ means
      Team Managers/Captains, Physiotherapists, Psychologists, Mental Trainers
      Masseurs, Masseuses etc who accompany with their State/Unit team while
      taking part in any Archery competitions/tournament/event/activities.
      ‘Others’ means Senior functionaries (preferably President, Secretary and
      Treasurer who will get AAI ID cards free of cost) of Member State
      Association/Unit. Member State Association/unit will be provided with an
      option to add officials (not exceed to three) of their respective body in
      profile. • Any person who is willing or even may have any chance to
      participate in competitions/ events during a calendar year should ensure
      his/her affiliation / renewal within the first two months of each calendar
      year (preferably before 28th day of February). • Since the ‘Affiliation
      portal’ would be inter-linked with the ‘Tournament Portal’, therefore,
      only affiliated/ registered persons will be able to participate in the
      tournaments of Archery in the country and abroad. Member State
      Associations/Units must ensure the affiliation of all potential archers,
      coaches, managers etc. of its States/Units well in time in order to avoid
      disqualification/ non- participation. • Applicant must keep all necessary
      details/documents like scanned copy of Aadhar card, Address proof, DoB
      proof, photograph (specified size) handy and available at all times, prior
      to filing up the Online Affiliation Form to upload on the portal.
      Applicant must also have a valid and active email ID and mobile number to
      receive notifications from the system. In case, an Applicant does not have
      a valid personal e-mail ID, he/she must create his/her new e-mail ID and
      mobile number before applying on-line and must maintain that email account
      and mobile number during the continuation of his/ her registration. Any
      notification/ communication with the Applicant shall be made only through
      such email address and mobile number. • Member State Association/Unit of
      the AAI is authorized to validate applications (except AAI accredited
      NTOs) of their respective State/Unit for affiliation with the help of
      documents stated above. Entries validated by the Member Associations/
      Units will only be given affiliation under the portal. System-generated
      notifications will go to the applicants’ personal email id/ mobile numbers
      to notify them about the status (pending/approved/rejected or renewal) of
      their application. Validation through the State Association/ Unit shall be
      duly signed by the President/ Secretary General of such State Association/
      Unit. • Applicant shall not be allowed to register more than one time. Any
      duplicate entry through any other username or password shall lead to
      disciplinary action against such Applicant. However, editing option to
      alter the details of the Applicant is available on portal which allows
      applicant to change their details only after intimating about such change
      to the respective Member State Association / Unit and obtaining their
      approval. • The ‘Affiliation Cards’ would be issued through the portal to
      all the affiliated Archers, Coaches, Officials. Applicant can download
      digital Affiliation Card and must carry it, in physical form, while
      attending the competitions and other events of the Archery Association of
      India. The Affiliation Card is restricted to use by the holder only for
      the purpose of participation in Archery Competitions/
      Tournaments/Events/Activities. • In case, an Archer wishes to play from
      any other member State/Unit other than the one he/she is registered with,
      he/she can do so by providing valid and bonafide reasons in this regard.
      Such change may be permitted, provided, such an Archer has uploaded a ‘No
      Objection Certificates (NOCs) from both the member State
      Associations/Units (from where he/she wishes to play and from where he/she
      has already registered or has played before). AAI will examine such cases
      on the merits of information/documents provided and thereafter take any
      decision for accepting/ rejection such change. Obtaining NOCs, as stated
      above, shall be mandatory for all Archers for change of State/Unit. Please
      note that the existing rules and regulation of AAI for ‘Inter-State
      Transfer of Archers’ shall remain applicable. In case, an Archer
      participates from different state/unit without obtaining approval in the
      manner stated above, an appropriate disciplinary action would be taken
      against such errant Archer.
      <br />

      <strong> TERMS & CONDITIONS </strong>
      <br />

      Welcome to Affiliation Portal of Archery Association of India. This
      document is an electronic record in terms of Information Technology Act,
      2000 and published in accordance with the provisions of Rule 3) of the
      Information Technology (Intermediaries guidelines) Rules, 2011 that
      require publishing the rules and regulations, privacy policy and Terms of
      Use for access or usage of platform - www.indianarchery.info (hereinafter
      referred to as "Platform") The Platform is owned by Archery Association of
      India (hereinafter referred to as AAI), having its registered office at
      Gate No.2, Yamuna Sports Complex, Surajmal Vihar, Delhi, India. Your use
      of the Affiliation Portal and services and tools are governed by the
      following terms and conditions ("Terms of Use") as applicable to the
      website including the applicable policies which are incorporated herein by
      way of reference. By mere use of the website, You shall be contracting
      with AAI, the owner of the Platform. These terms and conditions including
      the policies constitute Your binding obligations, with AAI. For the
      purpose of these Terms of Use, wherever the context so requires "You" or
      "User" shall mean any natural or legal person who has agreed to become a
      buyer on/to use this Platform by providing data while registering on the
      Platform as Registered/Affiliated User. The term "We", "Us","Our" shall
      mean AAI and its affiliates. When You/applicant wishes to affiliate with
      the AAI through the portal any of the services provided by Us through the
      Platform, including but not limited to, (e.g. Product Reviews, Seller
      Reviews), he/she will be subject to the rules, guidelines, policies,
      terms, and conditions applicable to such service, and they shall be deemed
      to be incorporated into this Terms of Use and shall be considered as part
      and parcel of this Terms of Use. We reserve the right, at our sole
      discretion, to change, modify, add or remove portions of these Terms of
      Use, at any time without any prior written notice to You. You/Applicant
      shall ensure to review these Terms of Use periodically for
      updates/changes. Your /applicant continued use of the Platform following
      the posting of changes will mean that You/applicant accepts and agrees to
      the revisions. As long as You/Applicant complies with these Terms of Use,
      We grant You/the applicant a personal, non-exclusive, non-transferable,
      limited privilege to enter and use the Platform. By impliedly or expressly
      accepting these Terms of Use, You/applicant also accepts and agrees to be
      bound by AAI Policies including but not limited to Privacy Policy as
      amended from time to time.
      <br />

      <strong>Introduction </strong>

      <br />

      These Terms and Conditions (hereinafter referred to as “Terms”) contained
      herein on this webpage, shall govern your use of this website, including
      all pages within this website (collectively referred to herein below as
      this “Website”). These Terms apply in full force and effect to your use of
      this Website and by using this Website, you expressly accept all terms and
      conditions contained herein in full. You must not use this Website, if you
      have any objection to any of these Terms and Conditions.
      <br />

      <strong>Intellectual Property Rights </strong>

      <br />

      Other than content you own, which you may have opted to include on this
      Website, under these Terms, AAI and/or its licensors own all rights to the
      intellectual property and material contained in this Website, and all such
      rights are reserved. You are granted a limited license only, subject to
      the restrictions provided in these Terms, for purposes of viewing the
      material contained on this Website.
      <br />

      <strong>Restrictions</strong>

      <br />

      You/Applicants are expressly and emphatically restricted from all of the
      following: • publishing any Website material in any media; • selling,
      sublicensing and/or otherwise commercializing any Website material; •
      publicly performing and/or showing any Website material; • using this
      Website in any way that is, or may be, damaging to this Website; • using
      this Website in any way that impacts user access to this Website; • using
      this Website contrary to applicable laws and regulations, or in a way that
      causes, or may cause, harm to the Website, or to any person or legal
      entity; • engaging in any data mining, data harvesting, data extracting or
      any other similar activity in relation to this Website, or while using
      this Website; • using this Website to engage in any advertising or
      marketing; Certain areas of this Website are restricted from access by
      You/Applicant and AAI may further restrict access by You/Applicant to any
      areas of this Website, at any time, in its sole and absolute discretion.
      Any user ID and password you may have for this Website are confidential
      and you must maintain confidentiality of such information.
      <br />

      <strong>Your/Applicant Content</strong>

      <br />

      In these Terms and Conditions, “Your Content” shall mean any text, images
      or other material you choose to display on this Website. With respect to
      Your Content, by displaying it, you grant AAI a non-exclusive, worldwide,
      irrevocable, royalty-free, sub-licensable license to use, reproduce,
      adapt, publish, translate and distribute it in any and all media. Your
      Content must be your own and must not be infringing on any third party’s
      rights. AAI reserves the right to remove any of Your/Applicant Content
      from this Website at any time, and for any reason, without notice.
      <br />

      <strong>No warranties</strong>

      <br />

      This Website is provided “as is,” with all faults, and AAI makes no
      express or implied representations or warranties, of any kind related to
      this Website or the materials contained on this Website. Additionally,
      nothing contained on this Website shall be construed as providing consult
      or advice to you/applicant.
      <br />

      <strong>Limitation of liability</strong>

      <br />

      In no event AAI or any of its member of Executive Committee or officials
      or employees shall be liable to you/applicant for anything arising out of
      or in any way connected with your use of this Website, whether such
      liability is under contract, tort or otherwise, and AAI, including its
      Executive Committee Members or officials or employees shall not be liable
      for any indirect, consequential or special liability arising out of or in
      any way related to your/applicants’ use of this Website.
      <br />

      <strong>Indemnification</strong>

      <br />

      You/Applicant hereby indemnify to the fullest extent of AAI from and
      against any and all liabilities, costs, demands, causes of action, damages
      and expenses (including reasonable attorney’s fees) arising out of or in
      any way related to your breach of any of the provisions of these Terms.
      <br />

      <strong>Severability</strong>

      <br />

      If any provision of these Terms is found to be unenforceable or invalid
      under any applicable law, such unenforceability or invalidity shall not
      render these Terms unenforceable or invalid as a whole, and such
      provisions shall be deleted without affecting the remaining provisions
      herein.
      <br />

      <strong>Variation of Terms</strong>

      <br />

      AAI is permitted to revise these Terms at any time as it sees fit, and by
      using this Website and you/applicants are expected to review such Terms on
      a regular basis to ensure you understand all terms and conditions
      governing use of this Website.
      <br />

      <strong>Assignment</strong>

      <br />

      AAI shall be permitted to assign, transfer, and subcontract its rights
      and/or obligations under these Terms without any notification or consent
      required. However, You/applicant shall not be permitted to assign,
      transfer, or subcontract any of your rights and/or obligations under these
      Terms. Hyperlinks to Third Party websites From time to time this website
      may also include hyperlinks to other websites which are not maintained by
      Archery Association of India. These links are provided for your
      convenience only to provide further information. They do not signify that
      we endorse the website(s). We have no responsibility for the content of
      the linked website(s). Additional Undertakings These terms and conditions
      do not constitute the entire process for participating in all the archery
      competitions. On signing up of this agreement, the participant(s) may be
      required to enter into specific agreements or undertakings during separate
      events/ occasions. The terms therein may howsoever differ but not contrary
      to the terms stated herein. Revision of Terms and Conditions These terms
      and conditions are subject to amendments and shall be amended from time to
      time. We may revise these terms at any time by amending this page. Please
      check this page from time to time to take notice of any changes we made,
      as they are binding on you. Entire Agreement These Terms, including any
      legal notices and disclaimers contained on this Website, constitute the
      entire agreement between AAI and you/application and it members State
      Associations/Units in relation to your/applicants use of this Website, and
      supersede all prior agreements and understandings with respect to the
      same. Governing Law & Jurisdiction These terms and conditions are governed
      by the laws of India and the competent courts at New Delhi shall have
      exclusive jurisdiction. These Terms shall be governed by and construed
      under the laws of the Republic of India. You consent and agree that all
      legal proceedings relating to the subject matter of these Terms shall be
      maintained in courts sitting within the Republic of India, and that
      jurisdiction and venue for such proceedings shall lie exclusively with
      such courts.
      <br />

      <strong>Payments – Needs to be gone through</strong>

      <br />

      • Payment(s) through this Service may only be made with a Credit Card,
      Debit card or Net Banking. • Before using this Service, it is recommended
      that the user shall make necessary enquiry about the charges or fees
      payable against the Credit/Debit card used from Credit Card or the Debit
      Card service provider i.e. the respective Bank. • The credit card
      information supplied at the time of using the service is processed by the
      payment gateway of the service provider and is not supplied to AAI. It is
      the sole responsibility of the User of the service to ensure that the
      information entered in the relevant fields are correct. It is recommended
      that you take and retain a copy of the transaction for record keeping
      purposes, which might assist in resolution of any disputes that may arise
      out or usage of theservice. • The Applicant agrees, understands and
      confirms that his/ her personal data, including without limitation,
      details relating to debit card/ credit card/net banking transmitted over
      the Internet may be susceptible to misuse, hacking, theft and/ or fraud
      and that AAI or the Payment Service Provider(s) have no control over such
      matters. • The service is provided using a payment gateway service
      provider through a secured website. However, neither the payment gateway
      service provider nor the AAI gives any assurance, that the information so
      provided online by a user is secured or may be read or intercepted by a
      third party. AAI does not accept or assume any liability in the event of
      such unauthorized interception, hacking or other unauthorized access to
      information provided by a user of the service. • AAI and/or the Payment
      Service Providers shall not be liable for any inaccuracy, error or delay
      in, or omission of (a) any data, information or message, or (b) the
      transmission or delivery of any such data, information or message; or (c)
      any loss or damage arising from or occasioned by any such inaccuracy,
      error, delay or omission, non-performance or interruption in any such
      data, information or message. Under no circumstances shall the AAI and/or
      the Payment Service Providers, its employees, directors, and its third
      party agents involved in processing, delivering or managing the Services,
      be liable for any direct, indirect, incidental, special or consequential
      damages, or any damages whatsoever, including punitive or exemplary
      arising out of or in any way connected with the provision of or any
      inadequacy or deficiency in the provision of the Services or resulting
      from unauthorized access or alteration of transmissions of data or arising
      from suspension or termination of the Service. • The Applicant agrees that
      AAI or any of its employees will not be held liable by the Applicant for
      any loss or damages arising from your use of, or reliance upon the
      information contained on the Website, or any failure to comply with these
      Terms and Conditions where such failure is due to circumstance beyond
      AAI’s reasonable control. Debit/Credit Card, Bank Account Details: • The
      Applicant agrees that the debit/credit card details provided by him/ her
      for use of the aforesaid Service(s) must be correct and accurate. • The
      Applicant may pay his/ her permitted fees to AAI by using a debit/credit
      card or through online banking account. The Applicant warrants, agrees and
      confirms that when he/ she initiates a payment transaction and/or issues
      an online payment instruction and provides his/her card/bank details: •
      The Applicant is fully and lawfully entitled to use such credit / debit
      card, bank account for such transactions; • The Applicant is responsible
      to ensure that the card/ bank account details provided by him/ her are
      accurate; • The Applicant authorizes debit of the nominated card/ bank
      account for the Payment of fees selected by such Applicant along with the
      applicable Fees. Cancellation/Refund Policy Affiliation Fees once paid
      will not be refunded under any circumstances.
      <br />

      <strong>Privacy Policy</strong>

      <br />

      • In General, an Applicant / Member / Participant may browse this website
      for obtaining any data or information. However, in order to access the
      classified information, one needs to use the credentials provided as an
      Applicant / Member / participant. • The information provided by the user
      on this website shall be kept confidential and shall not be shared with
      anyone except AAI’s respective member units. Name, Date of Birth, State,
      photograph/action photograph, details of matches, scores and rankings. The
      AAI will adhere complete privacy norms as laid down under thelaw. • The
      DATA will neither be made accessible to third parties nor transferred in
      any form or manner unless required by law. • AAI may share your personal
      information with our bankers for clearing necessary fees payments. This
      information shall be strictly up to the legal permissible limit. • Online
      Fee Payment system purpose is to provide flexibility and conform to its
      continuing Member / Applicant by offering option to pay online using
      various payment methods. • It is sole responsibility of Applicant to pick
      online payment option. AAI do not recommend/ prefer/promote any particular
      method. • If payment is made by means of a card that you do not personally
      own, the permission of the card owner must always be obtained to make
      payments using the card. In using the system you confirm that you have
      such permission. • Information related to payments using debit or credit
      card is not accessed or stored by AAI.
      <br />

      <strong>ACKNOWLEGMENT OF RISK:</strong>

      <br />

      I knowingly, willingly and voluntarily acknowledge the inherent risks
      associated with participation in the sport of archery and understand that
      my participation in any Event involves risks and dangers including,
      without limitation, the potential for serious bodily injury, sickness and
      disease (including communicable diseases, virus or bacteria, Covid-19),
      trauma, pain & suffering, permanent disability, paralysis and death; loss
      of or damage to personal property; exposure to extreme conditions and
      circumstances; facilities or premises issues, including hazards resulting
      in slips, trips and falls; accidents involving other participants, event
      staff, volunteers, spectators or the general public; contact or exposure
      to other participants, including participants of varying skill levels;
      adverse weather conditions; travel risks; equipment failure, including
      protective equipment; inadequate safety measures; situations beyond the
      immediate control of the Event or Events organizers; and other undefined,
      not readily foreseeable and presently unknown risks and dangers (“Risks”).
      <br />

      <strong>ASSUMPTION OF RISK:</strong>

      <br />

      I understand that the aforementioned Risks may be caused in whole or in
      part or result directly or indirectly from the negligence of my own
      actions or inactions, the actions or inactions of others participating in
      or volunteering at the Events, or the negligent acts or omissions of the
      Released Parties defined below, and I hereby voluntarily and knowingly
      assume all such Risks and responsibility for any damages, liabilities,
      losses or expenses which I incur as a result of my participation in any
      Events.
      <br />

      <strong
        >WAIVER AND RELEASE OF LIABILITY, HOLD HARMLESS AND INDEMNITY:</strong
      >
      <br />

      In conjunction with my participation in any Events, I hereby release,
      waive and covenant not to sue, and further agree to indemnify, defend and
      hold harmless the following parties: Archery Association of India, its
      Executive Committee Members, its members State Archery
      Associations/Associate Members, Host Units, Event participants (including
      registered athletes, coaches, trainers, officials and other personnel),
      organizer, promoters, sponsors or advertisers of any Event or Events and
      all directors, officers, agents, administrators, contractors, employees or
      volunteers of any of the aforementioned parties (Individually and
      Collectively, the “Released Parties” or “Event Organizers”), with respect
      to any liability, claim(s), demand(s), cause(s) of action, damage(s), loss
      or expense (including court costs and reasonable attorney fees) of any
      kind or nature (“Liability”) which may arise out of, result from, or
      relate in any way to my participation in any Event or Event
      <br />

      <strong>PARENTAL CONSENT (IN CASE OF A MINOR):</strong>

      <br />

      In the case where the applicant is a minor, i.e. Archers below the age of
      18 years, it is understood that the parents/guardians of the participant
      are aware that preparation for and participation in the Archery
      competitions involves inherent risks [Detailed in Acknowledgment of Risk
      section above] of injuries to the participant and therefore, acknowledge
      and understand the danger of possible injuries in the sport. Parents/
      Guardians of Minor Archers give their unequivocal and unconditional
      consent to WAIVER AND RELEASE OF LIABILITY, HOLD HARMLESS AND INDEMNITY
      section above.

      <strong>CONTACT DETAILS:</strong> <br />
      If you have any queries, please contact us at
      <b>help.indianarchery@gmail.com</b>
    </p>
<div class="footer-model-fixed">
    <p-checkbox
      [(ngModel)]="checked"
      (onChange)="checkIfAccepted($event)"
      label="You agree to Accept our Terms & Conditions"
      binary="true"
    ></p-checkbox>

    <button
      class="authButton saveBut"
      (click)="submitRegister()"
      [disabled]="!checked"
    >
      Accept
    </button>
    </div>
  </div>
</p-dialog>

<p-dialog header="Godfather I" [(visible)]="emailVerifyModel"
  [dismissableMask]="true" [modal]="true" styleClass="mydialog" [responsive]="true" [minY]="70"
  [maximizable]="true" [baseZIndex]="10000">
    <form [formGroup]="emailVerificationFormGroup" (ngSubmit)="onVerifyCode()">
        <div>
          <h2 class="model-h2">Email Verification</h2>
          <p class="model-p text-success">* Enter 6 digit verification code has been sent to your registered email.</p>
          <div class="formcard-area">
            <div class="form-group-model">
              <label>Verification Code</label>
              <input  type="text" formControlName="verificationCode"/>
              <div style="justify-content: flex-end;">
                <div>
                  <div *ngIf="codeSubmited && v.verificationCode.errors">
                    <p *ngIf="v.verificationCode.errors.required" class=" model-p invalid-feedback">Verification code is required</p>
                    <p *ngIf="v.verificationCode.errors.pattern" class="model-p invalid-feedback">Verification code must be a six-digit number</p>
                  </div>
                </div>
                <div>
                  <p class="model-p" style="cursor: pointer" (click)="openVerifyModel()">Resend Otp</p>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group otp">
            <p-footer>
              <button class="model-but-save" style="background: gray" (click)="closeVerifyModel()" type="button">Close</button>
              <button class="model-but-close" style="background: linear-gradient(135deg, #44c1e6, #5160ca);" type="submit">Verify Otp</button>
            </p-footer>
          </div>
        </div>
    </form>
</p-dialog>