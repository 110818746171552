<p-toast
  [baseZIndex]="900000"
  [preventOpenDuplicates]="false"
  [style]="{ marginTop: '80px' }"
></p-toast>

<div class="Light-nav">
  <div class="logo-wrapper">
    <img src="assets/icons/Logo.JPG" alt="" />
  </div>
  <div class="Light-nav-content">
    <div class="Light-support-wrap">
      <h2 class="Light-nav-heading">Archery association of India</h2>
      <img class="Light-head-support" src="assets/icons/target.svg" alt="" />
    </div>
    <p class="Light-nav-text" style="margin-top: 20px">
      Archery Association of India came into existence in 1973, with the primary
      objective to organize, encourage, and promote the game of Archery in the
      country by providing proper training facilities to Indian Archers.
    </p>
  </div>
</div>

<div class="user-info-area">
  <div class="flex">
    <img
      class="backArrow"
      (click)="backToApproval()"
      src="assets/icons/backArrow.svg"
    />
    <h2 Class="detail-page-head">Archer Details</h2>
  </div>
  <div class="ui-g">
    <div class="ui-g-3 ui-lg-3 ui-md-3 ui-sm-12">
      <div class="user-info-card">
        <div
          *ngIf="applicantData?.profileImage !== ''"
          class="user-info-card-img"
        >
          <img
            *ngIf="applicantData?.profileImage !== ''"
            class="profile-img"
            [src]="global?.basePathImage + applicantData?.profileImage"
            alt=""
          />

          <img
            *ngIf="applicantData?.profileImage == ''"
            class="profile-img"
            src="assets/icons/archer.svg"
            alt=""
          />
        </div>
        <div class="user-colored-content">
          <div class="name-content">
            <h1 class="user-profile-title">{{ applicantData?.fullName }}</h1>
          </div>
          <p *ngIf="applicantData?.memberId" class="user-profile-text">Member Id: {{ applicantData?.memberId }}</p>
          <p class="user-profile-text">Email: {{ applicantData?.email }}</p>
          <p class="user-profile-text">
            Phone Number: {{ applicantData?.phone }}
          </p>
          <p class="user-profile-text">
            Dob: {{ applicantData?.dob | date: "mediumDate" }}
          </p>
          <p class="user-profile-text">
            Marital Status: {{ applicantData?.maritalStatus }}
          </p>
        </div>
      </div>
      <div class="user-basic-card">
        <h1 class="user-profile-title">Category and Participation Details</h1>
        <p class="user-basic-card-text">
          <span class="lit-head">Member Association:</span>
          {{ applicantData?.memberAssociation.name }}
        </p>
        <p class="user-basic-card-text">
          <span class="lit-head">Category:</span>
          {{ applicantData?.playingCategory }}
        </p>
        <!-- <p class="user-basic-card-text"><span class="lit-head">Level of Participation:</span>
                    {{applicantData?.participationLevel}}</p> -->
      </div>
      <div class="user-basic-card">
        <h1 class="user-profile-title">Parents Details</h1>
        <p class="user-basic-card-text">
          <span class="lit-head">Father Name:</span>
          {{ applicantData?.fatherName }}
        </p>
        <p class="user-basic-card-text">
          <span class="lit-head">Mother Name:</span>
          {{ applicantData?.motherName }}
        </p>
        <!-- <p class="user-basic-card-text"><span class="lit-head">Parents Phone:</span>
                    {{applicantData?.parentsPhone}}</p>
                <p class="user-basic-card-text"><span class="lit-head">Parents Email:</span>
                    {{applicantData?.parentsEmail}}</p> -->
      </div>
    </div>
    <div class="ui-g-9 ui-lg-9 ui-md-9 ui-sm-12">
      <div class="profile-banner">
        <p>{{ applicantData?.fullName }}</p>
        <div
          *ngIf="!applicantData?.isActive && !applicantData?.isReject"
          class="button-wrap"
        >
          <button class="authButton" (click)="accept()">Accept</button>
          <button
            class="authButton"
            style="margin-left: 20px"
            (click)="rejectOpenModel()"
          >
            Reject
          </button>
        </div>
        <!-- <button
          *ngIf="applicantData?.isActive && !applicantData?.isReject"
          class="authButton"
          (click)="rejectOpenModel()"
        >
          Reject
        </button> -->
        <button
          *ngIf="!applicantData?.isActive && applicantData?.isReject"
          class="authButton"
          (click)="reApprove()"
        >
          ReApprove
        </button>
      </div>

      <div class="profile-content">
        <div class="ui-g">
          <div class="ui-g-6 ui-lg-6 ui-md-12 ui-sm-12">
            <div class="user-basic-card training-card">
              <div class="flex">
                <img
                  class="education-logo"
                  src="assets/icons/target1.svg"
                  alt=""
                />
                <div style="margin-left: 20px">
                  <h1 class="user-profile-title">Current Training Details</h1>
                  <p class="user-basic-card-text">
                    <span class="lit-head">Location:</span>
                    {{
                      applicantData?.currentTrainingDetails?.trainingPlaceName
                    }}
                  </p>
                  <p class="user-basic-card-text">
                    <span class="lit-head">Coach Name:</span>
                    {{ applicantData?.currentTrainingDetails?.coachName }}
                  </p>
                  <p class="user-basic-card-text">
                    <span class="lit-head">Email:</span>
                    {{ applicantData?.currentTrainingDetails?.email }}
                  </p>
                  <div class="flex">
                    <p class="user-basic-card-text">
                      <span class="lit-head">Start Year:</span>
                      {{
                        applicantData?.currentTrainingDetails?.joinYear
                          | date: "mediumDate"
                      }}
                    </p>
                    <p class="user-basic-card-text" style="margin-left: 15px">
                      <span class="lit-head">Coach Contact:</span>
                      {{ applicantData?.currentTrainingDetails?.coachPhone }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="ui-g-6 ui-lg-6 ui-md-12 ui-sm-12">
            <div class="user-basic-card training-card">
              <div class="flex">
                <img
                  class="education-logo"
                  src="assets/icons/target1.svg"
                  alt=""
                />
                <div style="margin-left: 20px">
                  <h1 class="user-profile-title">Past Training Details</h1>
                  <p class="user-basic-card-text">
                    <span class="lit-head">Location:</span>
                    {{ applicantData?.trainingDetails?.trainingPlaceName }}
                  </p>
                  <p class="user-basic-card-text">
                    <span class="lit-head">Coach Name:</span>
                    {{ applicantData?.trainingDetails?.coachName }}
                  </p>
                  <p class="user-basic-card-text">
                    <span class="lit-head">Coach Contact:</span>
                    {{ applicantData?.trainingDetails?.coachPhone }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="profile-content ui-g">
        <div
          *ngIf="applicantData?.isStudying"
          class="ui-g-6 ui-lg-6 ui-md-12 ui-sm-12"
        >
          <div class="user-basic-card educational-card">
            <div class="flex">
              <img
                class="education-logo"
                src="assets/icons/university.svg"
                alt=""
              />
              <div style="margin-left: 20px">
                <h1 class="user-profile-title">Educational Details</h1>
                <p class="user-basic-card-text">
                  <span class="lit-head">Name:</span>
                  {{ applicantData?.collegeDetails?.collegeName }}
                </p>
                <p class="user-basic-card-text">
                  <span class="lit-head">Street:</span>
                  {{ applicantData?.collegeDetails?.houseNo }}
                </p>
                <div class="flex">
                  <p class="user-basic-card-text">
                    <span class="lit-head">District:</span>
                    {{ applicantData?.collegeDetails?.district }}
                  </p>
                  <p class="user-basic-card-text">
                    <span class="lit-head">City:</span>
                    {{ applicantData?.collegeDetails?.city }}
                  </p>
                </div>
                <div class="flex">
                  <p class="user-basic-card-text">
                    <span class="lit-head">State:</span>
                    {{ applicantData?.collegeDetails?.state }}
                  </p>
                  <p class="user-basic-card-text" style="margin-left: 15px">
                    <span class="lit-head">PinCode:</span>
                    {{ applicantData?.collegeDetails?.pinCode }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          *ngIf="applicantData?.isEmployed"
          class="ui-g-6 ui-lg-6 ui-md-12 ui-sm-12"
        >
          <div class="user-basic-card educational-card">
            <div class="flex">
              <img
                class="education-logo"
                src="assets/icons/worker.svg"
                alt=""
              />
              <div style="margin-left: 20px">
                <h1 class="user-profile-title">Employment Details</h1>
                <p class="user-basic-card-text">
                  <span class="lit-head">Organization:</span>
                  {{ applicantData?.employmentDetails?.organisationName }}
                </p>
                <p class="user-basic-card-text">
                  <span class="lit-head">Designation:</span>
                  {{ applicantData?.employmentDetails?.designation }}
                </p>
                <p class="user-basic-card-text">
                  <span class="lit-head">Place of Posting:</span>
                  {{ applicantData?.employmentDetails?.postingPLace }}
                </p>
                <p class="user-basic-card-text">
                  <span class="lit-head">Street:</span>
                  {{ applicantData?.employmentDetails?.streetAddress }}
                </p>

                <div class="flex">
                  <p class="user-basic-card-text">
                    <span class="lit-head">City:</span>
                    {{ applicantData?.employmentDetails?.city }}
                  </p>
                  <p class="user-basic-card-text" style="margin-left: 15px">
                    <span class="lit-head">District:</span>
                    {{ applicantData?.employmentDetails?.district }}
                  </p>
                </div>
                <div class="flex">
                  <p class="user-basic-card-text">
                    <span class="lit-head">State:</span>
                    {{ applicantData?.employmentDetails?.state }}
                  </p>
                  <p class="user-basic-card-text" style="margin-left: 15px">
                    <span class="lit-head">Pincode:</span>
                    {{ applicantData?.employmentDetails?.pinCode }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="profile-content ui-g">
        <div class="ui-g-6 ui-lg-6 ui-md-12 ui-sm-12">
          <div class="user-basic-card location-card">
            <div class="flex">
              <img
                class="education-logo"
                src="assets/icons/locationp.svg"
                alt=""
              />
              <div style="margin-left: 20px">
                <h1 class="user-profile-title">Present Address</h1>
                <p class="user-basic-card-text">
                  <span class="lit-head">Street:</span>
                  {{ applicantData?.presentAddress?.houseNo }}
                </p>
                <div class="flex">
                  <p class="user-basic-card-text">
                    <span class="lit-head">City:</span>
                    {{ applicantData?.presentAddress?.city }}
                  </p>
                  <p class="user-basic-card-text" style="margin-left: 15px">
                    <span class="lit-head">District:</span>
                    {{ applicantData?.presentAddress?.district }}
                  </p>
                </div>
                <div class="flex">
                  <p class="user-basic-card-text">
                    <span class="lit-head">State:</span>
                    {{ applicantData?.presentAddress?.state }}
                  </p>
                  <p class="user-basic-card-text" style="margin-left: 15px">
                    <span class="lit-head">PinCode:</span>
                    {{ applicantData?.presentAddress?.pinCode }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="ui-g-6 ui-lg-6 ui-md-12 ui-sm-12">
          <div class="user-basic-card location-card">
            <div class="flex">
              <img
                class="education-logo"
                src="assets/icons/locationp.svg"
                alt=""
              />
              <div style="margin-left: 20px">
                <h1 class="user-profile-title">Permanent Address</h1>
                <p class="user-basic-card-text">
                  <span class="lit-head">Street:</span>
                  {{ applicantData?.permanentAddress?.houseNo }}
                </p>
                <div class="flex">
                  <p class="user-basic-card-text">
                    <span class="lit-head">City:</span>
                    {{ applicantData?.permanentAddress?.city }}
                  </p>
                  <p class="user-basic-card-text" style="margin-left: 15px">
                    <span class="lit-head">District:</span>
                    {{ applicantData?.permanentAddress?.district }}
                  </p>
                </div>
                <div class="flex">
                  <p class="user-basic-card-text">
                    <span class="lit-head">State:</span>
                    {{ applicantData?.permanentAddress?.state }}
                  </p>
                  <p class="user-basic-card-text" style="margin-left: 15px">
                    <span class="lit-head">PinCode:</span>
                    {{ applicantData?.permanentAddress?.pinCode }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="profile-content ui-g">
        <div class="ui-g-6 ui-lg-6 ui-md-12 ui-sm-12">
          <div class="user-basic-card array-card">
            <div class="flex">
              <img
                class="education-logo"
                src="assets/icons/university.svg"
                alt=""
              />
              <div style="margin-left: 20px">
                <h1 class="user-profile-title">Educational Qualification</h1>

                <p
                  *ngFor="let data of applicantData?.educationQualification"
                  class="user-basic-card-text"
                >
                  {{ data?.name }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="ui-g-6 ui-lg-6 ui-md-12 ui-sm-12">
          <div class="user-basic-card array-card">
            <div class="flex">
              <img
                class="education-logo"
                src="assets/icons/worker.svg"
                alt=""
              />
              <div style="margin-left: 20px">
                <h1 class="user-profile-title">Professional Qualification</h1>

                <p
                  *ngFor="let data of applicantData?.educationQualification"
                  class="user-basic-card-text"
                >
                  {{ data?.name }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="profile-content ui-g">
        <div class="ui-g-12">
          <div class="user-basic-card">
            <div class="flex">
              <!-- <img class="education-logo" src="assets/icons/locationp.svg" alt=""> -->
              <div style="margin-left: 20px; width: 100%">
                <h1 class="user-profile-title">Uploaded Documents</h1>
                <div class="ui-g">
                  <div
                    *ngIf="applicantData?.residenceProofDocLink !== ''"
                    class="ui-g-4 ui-lg-4 ui-md-6 ui-sm-12"
                  >
                    <p class="upload-Img-link">Residence Doc</p>
                    <img
                      (click)="
                        openImageModel(applicantData?.residenceProofDocLink)
                      "
                      *ngIf="
                        applicantData?.residenceProofDocLink.includes('svg') ||
                        applicantData?.residenceProofDocLink.includes('png') ||
                        applicantData?.residenceProofDocLink.includes('jpg') ||
                        applicantData?.residenceProofDocLink.includes('JPG') ||
                        applicantData?.residenceProofDocLink.includes('jpeg')
                      "
                      class="upload-image-h"
                      [src]="
                        global?.basePathImage +
                        applicantData?.residenceProofDocLink
                      "
                      alt=""
                    />
                    <div
                      *ngIf="
                        applicantData?.residenceProofDocLink.includes('pdf') ||
                        applicantData?.residenceProofDocLink.includes('doc')
                      "
                    >
                      <img
                        class="upload-image-h"
                        src="assets/icons/docIcon.svg"
                        alt=""
                      />
                      <a
                        class="Upload-Links"
                        target="_blank"
                        href="http://api.indianarchery.info/{{
                          applicantData?.residenceProofDocLink
                        }}"
                        download
                      >
                        http://api.indianarchery.info/{{
                          applicantData?.residenceProofDocLink
                        }}
                      </a>
                    </div>
                  </div>

                  <div
                    *ngIf="applicantData?.signatureLink !== ''"
                    class="ui-g-4 ui-lg-4 ui-md-6 ui-sm-12"
                  >
                    <p class="upload-Img-link">Signature Doc</p>
                    <img
                      (click)="openImageModel(applicantData?.signatureLink)"
                      *ngIf="
                        applicantData?.signatureLink.includes('svg') ||
                        applicantData?.signatureLink.includes('png') ||
                        applicantData?.signatureLink.includes('jpg') ||
                        applicantData?.signatureLink.includes('JPG') ||
                        applicantData?.signatureLink.includes('jpeg')
                      "
                      class="upload-image-h"
                      [src]="
                        global?.basePathImage + applicantData?.signatureLink
                      "
                      alt=""
                    />

                    <div
                      *ngIf="
                        applicantData?.signatureLink.includes('pdf') ||
                        applicantData?.signatureLink.includes('doc')
                      "
                    >
                      <img
                        class="upload-image-h"
                        src="assets/icons/docIcon.svg"
                        alt=""
                      />
                      <a
                        class="Upload-Links"
                        target="_blank"
                        href="http://api.indianarchery.info/{{
                          applicantData?.signatureLink
                        }}"
                        download
                      >
                        http://api.indianarchery.info/{{
                          applicantData?.signatureLink
                        }}
                      </a>
                    </div>
                  </div>

                  <div
                    *ngIf="applicantData?.aadharImage !== ''"
                    class="ui-g-4 ui-lg-4 ui-md-6 ui-sm-12"
                  >
                    <p class="upload-Img-link">Aadhar card</p>
                    <img
                      (click)="openImageModel(applicantData?.aadharImage)"
                      *ngIf="
                        applicantData?.aadharImage.includes('svg') ||
                        applicantData?.aadharImage.includes('png') ||
                        applicantData?.aadharImage.includes('jpg') ||
                        applicantData?.aadharImage.includes('JPG') ||
                        applicantData?.aadharImage.includes('jpeg')
                      "
                      class="upload-image-h"
                      [src]="global?.basePathImage + applicantData?.aadharImage"
                      alt=""
                    />
                    <div
                      *ngIf="
                        applicantData?.aadharImage.includes('pdf') ||
                        applicantData?.aadharImage.includes('doc')
                      "
                    >
                      <img
                        class="upload-image-h"
                        src="assets/icons/docIcon.svg"
                        alt=""
                      />
                      <a
                        class="Upload-Links"
                        target="_blank"
                        href="http://api.indianarchery.info/{{
                          applicantData?.aadharImage
                        }}"
                        download
                      >
                        http://api.indianarchery.info/{{ applicantData?.aadharImage }}
                      </a>
                    </div>
                    <p class="card-num">
                      Aadhar card number: {{ applicantData?.aadharCard }}
                    </p>
                  </div>
                </div>

                <div class="ui-g">
                  <div
                    *ngIf="applicantData?.ageProofDocLink !== ''"
                    class="ui-g-4 ui-lg-4 ui-md-6 ui-sm-12"
                  >
                    <p class="upload-Img-link">Age Proof Doc</p>
                    <img
                      (click)="openImageModel(applicantData?.ageProofDocLink)"
                      *ngIf="
                        applicantData?.ageProofDocLink.includes('svg') ||
                        applicantData?.ageProofDocLink.includes('png') ||
                        applicantData?.ageProofDocLink.includes('jpg') ||
                        applicantData?.ageProofDocLink.includes('JPG') ||
                        applicantData?.ageProofDocLink.includes('jpeg')
                      "
                      class="upload-image-h"
                      [src]="
                        global?.basePathImage + applicantData?.ageProofDocLink
                      "
                      alt=""
                    />
                    <div
                      *ngIf="
                        applicantData?.ageProofDocLink.includes('pdf') ||
                        applicantData?.ageProofDocLink.includes('doc')
                      "
                    >
                      <img
                        class="upload-image-h"
                        src="assets/icons/docIcon.svg"
                        alt=""
                      />
                      <a
                        class="Upload-Links"
                        target="_blank"
                        href="http://api.indianarchery.info/{{
                          applicantData?.ageProofDocLink
                        }}"
                        download
                      >
                        http://api.indianarchery.info/{{
                          applicantData?.ageProofDocLink
                        }}
                      </a>
                    </div>
                  </div>
                  <div
                    *ngIf="applicantData?.passportDocLink !== ''"
                    class="ui-g-4 ui-lg-4 ui-md-6 ui-sm-12"
                  >
                    <p class="upload-Img-link">Passport Doc</p>
                    <img
                      (click)="openImageModel(applicantData?.passportDocLink)"
                      *ngIf="
                        applicantData?.passportDocLink.includes('svg') ||
                        applicantData?.passportDocLink.includes('png') ||
                        applicantData?.passportDocLink.includes('jpg') ||
                        applicantData?.passportDocLink.includes('JPG') ||
                        applicantData?.passportDocLink.includes('jpeg')
                      "
                      class="upload-image-h"
                      [src]="
                        global?.basePathImage + applicantData?.passportDocLink
                      "
                      alt=""
                    />
                    <div
                      *ngIf="
                        applicantData?.passportDocLink.includes('pdf') ||
                        applicantData?.passportDocLink.includes('doc')
                      "
                    >
                      <img
                        class="upload-image-h"
                        src="assets/icons/docIcon.svg"
                        alt=""
                      />
                      <a
                        class="Upload-Links"
                        target="_blank"
                        href="http://api.indianarchery.info/{{
                          applicantData?.passportDocLink
                        }}"
                        download
                      >
                        http://api.indianarchery.info/{{
                          applicantData?.passportDocLink
                        }}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<p-dialog
  header="Reject"
  [(visible)]="rejectModel"
  [dismissableMask]="true"
  [modal]="true"
  styleClass="mydialog"
  [responsive]="true"
  [minY]="70"
  [maximizable]="true"
  [baseZIndex]="10000"
>
  <div class="user-login-status">
    <div class="img-wrap">
      <img
        *ngIf="applicantData?.profileImage !== ''"
        class="user-image"
        [src]="global?.basePathImage + applicantData?.profileImage"
        alt=""
      />

      <img
        *ngIf="applicantData?.profileImage == ''"
        class="user-image"
        src="assets/icons/noUser.svg"
        alt=""
      />
    </div>
    <h1 *ngIf="rejectModel">
      Are You sure You want to reject {{ applicantData?.firstName }}
      {{ applicantData?.lastName }}
    </h1>
    <div class="form-group">
      <label class="label">Reason</label>
      <textarea
        [(ngModel)]="reasonText"
        class="text-area"
        autoResize="false"
        [rows]="5"
        [cols]="30"
        pInputTextarea
      ></textarea>

      <div *ngIf="noReasonFound" class="invalid-feedback">
        Reason is Required
      </div>
    </div>
    <p-footer>
      <button class="model-but-save" (click)="reject()">Reject</button>
      <button
        class="model-but-close"
        style="background: gray"
        (click)="rejectCloseModel()"
      >
        Close
      </button>
    </p-footer>
  </div>
</p-dialog>

<p-dialog
  header="User"
  [(visible)]="imageModel"
  [dismissableMask]="true"
  [modal]="true"
  styleClass="mydialog"
  [responsive]="true"
  [minY]="70"
  [maximizable]="true"
  [baseZIndex]="10000"
>
  <div class="custom-popup">
    <div class="image-container">
      <img class="big-show-image" [src]="imageShow" alt="" />
    </div>
  </div>
</p-dialog>
