<div class="Light-nav">
  <div class="logo-wrapper">
    <img
      (click)="toHome()"
      style="cursor: pointer"
      src="assets/icons/Logo.JPG"
      alt=""
    />
  </div>
  <div class="Light-nav-content">
    <div class="Light-support-wrap">
      <h2 class="Light-nav-heading">Archery association of India</h2>
      <img class="Light-head-support" src="assets/icons/target.svg" alt="" />
    </div>
    <p class="Light-nav-text" style="margin-top: 20px">
      Archery Association of India came into existence in 1973, with the primary
      objective to organize, encourage, and promote the game of Archery in the
      country by providing proper training facilities to Indian Archers.
    </p>
  </div>
</div>

<div *ngIf="paymentVerifyLoader" class="loading">
  <img src="assets/icons/loader.gif" alt="" />
</div>

<div class="ui-g">
  <div class="ui-g-5 ui-lg-5 ui-md-12 ui-sm-12 ZeroPad">
    <div class="auth-content-info">
      <h1 class="heading" (click)="toHome()" style="cursor: pointer">
        Archery Association of India
      </h1>
      <p class="text" style="margin-top: 20px">
        Archery Association of India came into existence in 1973, with the
        primary objective to organize, encourage, and promote the game of
        Archery in the country by providing proper training facilities to Indian
        Archers.
      </p>
      <div class="logo-wrapper">
        <img
          (click)="toHome()"
          style="cursor: pointer"
          class="logo"
          src="assets/icons/Logo.JPG"
          alt=""
        />
      </div>
    </div>

    <!-- <div class="mobile" style="text-align:center">
            <img (click)="toHome()" style="cursor: pointer;" class="logo" src="assets/icons/Logo.JPG" alt=''>
        </div> -->
  </div>
  <div class="ui-g-7 ui-lg-7 ui-md-12 ui-sm-12 ZeroPad">
    <div class="auth-content">
      <h2 class="sub-heading">Login To Your Account</h2>
      <p class="text" style="margin-top: 20px">Enter Your Details to Login</p>

      <div class="login-form-wrap">
        <form [formGroup]="loginFormGroup" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <label class="label">Email Address</label>
            <input
              type="text"
              formControlName="email"
              placeholder="Enter Email"
              class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
            />
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
              <div *ngIf="f.email.errors.required">Email is required</div>
            </div>
            <div class="invalid-feedback" *ngIf="f.email?.errors?.email">
              Email must be a valid email address
            </div>
          </div>

          <div class="form-group">
            <label class="label">Password</label>
            <input
              type="password"
              formControlName="password"
              placeholder="Enter Password"
              class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
            />
            <div
              *ngIf="submitted && f.password.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f.password.errors.required">Password is required</div>
            </div>
          </div>
          <div class="form-group">
            <label (click)="showDialog()" class="authLinks"
              >Forgot Password</label
            >
          </div>

          <div class="form-group">
            <!-- <ng-container *ngIf="recaptcha.render">
                            <p-captcha siteKey="6Lf2XQkTAAAAANcvOwYqPxWL4iZDksFqHpS39GDA"
                                (onResponse)="showResponse($event)"></p-captcha>
                        </ng-container> -->

            <button class="authButton">
              <span *ngIf="logSpinner"
                ><i class="pi pi-spin pi-spinner" style="margin-right: 6px"></i
              ></span>
              Login
            </button>
          </div>
          <p *ngIf="errorData" class="err">Invalid Username/Password</p>
          <div class="flex" style="margin-top: 20px">
            <p class="text">Don't have an account?</p>
            <label
              style="margin-left: 10px"
              (click)="toRegister()"
              class="authLinks"
              >Register</label
            >
          </div>
          <div *ngIf="errorData">
            <div class="flex" style="margin-top: 20px">
              <p class="text">If you have any queries, please contact us at</p> 
            </div>
            <div class="flex" style="margin-top: 5px">
              <p class="authLinks">help.indianarchery@gmail.com</p> 
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<p-dialog
  header="Godfather I"
  [(visible)]="display"
  [dismissableMask]="true"
  [modal]="true"
  styleClass="mydialog"
  [responsive]="true"
  [minY]="70"
  [maximizable]="true"
  [baseZIndex]="10000"
>
  <div *ngIf="!otpSend && !changePass">
    <h2 class="model-h2">Forgot your password!</h2>
    <p class="model-p">
      Please enter your email to reset the password, You will Receive an OTP.
    </p>
    <div class="formcard-area">
      <div class="form-group-model">
        <label>Email</label>
        <input type="text" [(ngModel)]="resetEmail" />
      </div>
    </div>
    <p-footer>
      <button class="model-but-save" (click)="sendOtp()">Send</button>
      <button
        class="model-but-close"
        style="background: gray"
        (click)="closeVerify()"
      >
        Close
      </button>
    </p-footer>
  </div>

  <div *ngIf="otpSend">
    <h2 class="model-h2">Forgot your password!</h2>
    <p class="model-p">Enter 4 digit otp sent to your phone number</p>

    <div class="formcard-area">
      <div class="form-group-model">
        <label>OTP</label>
        <input [(ngModel)]="otp" type="number" />
      </div>
      <p class="invalid-feedback" *ngIf="otpError">
        Please Enter the Correct OTP
      </p>
    </div>

    <div class="form-group otp">
      <p class="model-p" (click)="sendOtp()">Resend Otp</p>
      <p-footer>
        <button class="model-but-save" (click)="verifyOtp()">Verify Otp</button>
        <button
          class="model-but-close"
          style="background: gray"
          (click)="closeVerify()"
        >
          Close
        </button>
      </p-footer>
    </div>
  </div>

  <div *ngIf="changePass">
    <h2 class="model-h2">Enter new password!</h2>
    <div class="formcard-area">
      <div class="form-group-model">
        <label>Change password</label>
        <input [(ngModel)]="changedPass" type="text" />
      </div>
    </div>

    <div class="form-group otp">
      <p-footer>
        <button class="model-but-save" (click)="changePassword()">
          Change password
        </button>
        <button
          class="model-but-close"
          style="background: gray"
          (click)="closeVerify()"
        >
          Close
        </button>
      </p-footer>
    </div>
  </div>
</p-dialog>

<p-dialog
  header="User"
  [(visible)]="notActiveModel"
  [dismissableMask]="true"
  [modal]="true"
  styleClass="mydialog"
  [responsive]="true"
  [minY]="70"
  [maximizable]="true"
  [baseZIndex]="10000"
>
  <div class="user-login-status">
    <div class="img-wrap">
      <img
        *ngIf="
          notActiveInfo?.profileImage !== '' ||
          notActiveInfo?.profileImage !== null
        "
        class="user-image"
        [src]="global?.basePathImage + notActiveInfo?.profileImage"
        alt=""
      />

      <img
        *ngIf="
          notActiveInfo?.profileImage === '' ||
          notActiveInfo?.profileImage === null
        "
        class="user-image"
        src="assets/icons/noUser.svg"
        alt=""
      />
    </div>
    <h1 style="text-transform: capitalize; font-weight: bold">
      Hey {{ notActiveInfo?.name }}
    </h1>
    <p>{{ notActiveInfo?.email }}</p>
    <p>
      Your affiliation with the AAI is pending for the validation/ approval with
      your parent State Archery Association/Associate Member. You will get
      intimation to this effect soon.
    </p>
    <button
      class="model-but-save"
      style="background: gray"
      (click)="closeNotActive()"
    >
      Close
    </button>
  </div>
</p-dialog>

<p-dialog
  header="User"
  [(visible)]="rejectedModel"
  [dismissableMask]="true"
  [modal]="true"
  styleClass="mydialog"
  [responsive]="true"
  [minY]="70"
  [maximizable]="true"
  [baseZIndex]="10000"
>
  <div class="user-login-status">
    <div class="img-wrap">
      <img
        *ngIf="
          notActiveInfo?.profileImage !== '' ||
          notActiveInfo?.profileImage !== null
        "
        class="user-image"
        [src]="global?.basePathImage + notActiveInfo?.profileImage"
        alt=""
      />

      <img
        *ngIf="
          notActiveInfo?.profileImage === '' ||
          notActiveInfo?.profileImage === null
        "
        class="user-image"
        src="assets/icons/noUser.svg"
        alt=""
      />
    </div>
    <h1 style="text-transform: capitalize; font-weight: bold">
      Hey {{ notActiveInfo?.name }}
    </h1>
    <p>Your application is rejected!</p>
    <p>Application reject Reason: {{ rejectReason }}</p>
    <div>
      <button class="model-but-save mob-seen" (click)="closeRejected()">
        Close
      </button>
      <button class="model-but-save" (click)="toRegister()">
        Register again
      </button>
    </div>
  </div>
</p-dialog>

<p-dialog
  header="User"
  [(visible)]="noPaymentModel"
  [dismissableMask]="true"
  [modal]="true"
  styleClass="mydialog"
  [responsive]="true"
  [minY]="70"
  [maximizable]="true"
  [baseZIndex]="10000"
>
  <div class="user-login-status">
    <div class="img-wrap">
      <img
        *ngIf="
          userInfoPayment?.profileImage !== '' ||
          userInfoPayment?.profileImage !== null
        "
        class="user-image"
        [src]="global?.basePathImage + userInfoPayment?.profileImage"
        alt=""
      />

      <img
        *ngIf="
          userInfoPayment?.profileImage === '' ||
          userInfoPayment?.profileImage === null
        "
        class="user-image"
        src="assets/icons/noUser.svg"
        alt=""
      />
    </div>
    <h1 style="text-transform: capitalize; font-weight: bold">
      Hey {{ userInfoPayment?.name }}
    </h1>
    <p>{{ userInfoPayment?.email }}</p>
    <p>
      Your Request is Approved, Please make the payment of Rs
      {{ priceData?.price / 100 }} to Login and Download Your Id Card!
    </p>
    <p>
      <!-- Greetings from the Archery Association of India ! The registration/affiliation on the AAI portal will be closed till April 2, 2024.Due to financial year closing. -->

    </p>
    <button class="model-but-save" (click)="getOrderId()">Make Payment</button>
  </div>
</p-dialog>

<p-dialog
  header="User"
  [(visible)]="noPaymentModelFan"
  [dismissableMask]="true"
  [modal]="true"
  styleClass="mydialog"
  [responsive]="true"
  [minY]="70"
  [maximizable]="true"
  [baseZIndex]="10000"
>
  <div class="user-login-status">
    <div class="img-wrap">
      <img
        *ngIf="
          userInfoPayment?.profileImage !== '' ||
          userInfoPayment?.profileImage !== null
        "
        class="user-image"
        [src]="global?.basePathImage + userInfoPayment?.profileImage"
        alt=""
      />

      <img
        *ngIf="
          userInfoPayment?.profileImage === '' ||
          userInfoPayment?.profileImage === null
        "
        class="user-image"
        src="assets/icons/noUser.svg"
        alt=""
      />
    </div>
    <h1 style="text-transform: capitalize; font-weight: bold">
      Hey {{ userInfoPayment?.name }}
    </h1>
    <p>{{ userInfoPayment?.email }}</p>
    <p>
      You have successfully completed your registration, Please make the payment
      of Rs {{ priceData?.price / 100 }} to Login and use platform.
    </p>
    <p>
      <!-- Greetings from the Archery Association of India ! The registration/affiliation on the AAI portal will be closed till April 2, 2024.Due to financial year closing.  -->
    </p>
    <button class="model-but-save" (click)="getOrderId()">Make Payment</button>
  </div>
</p-dialog>

<p-dialog
  header="User"
  [(visible)]="paymentSuccessModel"
  [dismissableMask]="true"
  [modal]="true"
  styleClass="mydialog"
  [responsive]="true"
  [minY]="70"
  [maximizable]="true"
  [baseZIndex]="10000"
>
  <div class="user-login-status">
    <div class="img-wrap">
      <img class="user-image" src="assets/icons/check.svg" alt="" />
    </div>
    <h1 style="text-transform: capitalize; font-weight: bold">
      Payment Successful
    </h1>
    <p>
      Your Payment is successfully Initialized, be patient it may take some time
      to reflect the payment status, please login Again to check the payment
      status and download your Id card.
    </p>
    <p style="font-family: Bold;color:#333333">
       NOTE:  If you wish to edit/change your details, kindly contact us at
       help.indianarchery@gmail.com with supporting document.
      
    </p>
    <button class="model-but-save" (click)="closeSuccessModel()">Done</button>
  </div>
</p-dialog>

<p-dialog
  header="User"
  [(visible)]="passwordSuccessModel"
  [dismissableMask]="true"
  [modal]="true"
  styleClass="mydialog"
  [responsive]="true"
  [minY]="70"
  [maximizable]="true"
  [baseZIndex]="10000"
>
  <div class="user-login-status">
    <div class="img-wrap">
      <img class="user-image" src="assets/icons/check.svg" alt="" />
    </div>
    <h1 style="text-transform: capitalize; font-weight: bold">
      Password changed
    </h1>
    <p>Your Password has been changed successfully!</p>
    <button class="model-but-save" (click)="closePassSuccessModel()">
      Done
    </button>
  </div>
</p-dialog>
