import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";

@Component({
  selector: 'app-fan-status',
  templateUrl: './fan-status.component.html',
  styleUrls: ['./fan-status.component.css']
})
export class FanStatusComponent implements OnInit {

  constructor(private router: Router) {}

  ngOnInit(): void {
  }
  toLogin() {
    this.router.navigate(["/login"]);
  }

}
