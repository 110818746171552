import { Component, OnInit, NgZone } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { GlobalService } from "../global-service";
declare var Razorpay: any;

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  loginFormGroup: FormGroup;
  errorData = false;
  submitted = false;
  display = false;
  mobileNumber: number;
  otpSend: boolean;
  otp: any;
  changePass: boolean;
  changedPass: any;
  rejectReason: any;
  rejectedModel: boolean = false;
  notActiveModel: boolean = false;
  userInfo: any;
  notActiveInfo: any;
  resetEmail: any;
  resetUserType: any;
  otpError: boolean = false;
  orderId: any;

  options: any;
  noPaymentModel: boolean = false;
  noPaymentModelFan: boolean = false;
  logData: any;
  userTypeData: any;

  paymentId: any;

  priceData: any;
  forgetDone: boolean=false;


  userInfoPayment: any;
  paymentSuccessModel: boolean = false;
  passwordSuccessModel:boolean =false;
  logSpinner: boolean = false;
  paymentVerifyLoader: boolean = false;

  constructor(
    private zone: NgZone,
    private formBuilder: FormBuilder,
    private router: Router,
    public global: GlobalService
  ) {}

  ngOnInit() {
    this.loginForm();
    if (localStorage.getItem("token")) {
      this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
      if (this.userInfo.accountType === "Union") {
        this.router.navigate(["/admin-approval"]);
      }
    } else {
      console.log("not there");
    }
  }
  toRegister() {
    this.router.navigate(["/role-select"]);
  }
  loginForm() {
    this.loginFormGroup = this.formBuilder.group({
      email: ["", [Validators.required]],
      password: ["", [Validators.required]],
    });
  }

  get f() {
    return this.loginFormGroup.controls;
  }

  onSubmit() {
    this.logSpinner = true;
    this.submitted = true;
    if (this.loginFormGroup.invalid) {
      return;
    } else {
      const data = this.loginFormGroup.value;
      const url1 = this.global.base_path_api() + "user/login";
      this.global.PostRequestUnauthorised(url1, data).subscribe(
        (res) => {
          this.logSpinner = false;

          const response = res[0].json;
          this.logData = res[0].json;
					console.log("TCL: LoginComponent -> onSubmit -> this.logData", this.logData)

          if (response.isReject) {
            this.rejectedModel = true;
            this.notActiveInfo = res[0].json.data;
            this.rejectReason = res[0].json.msg;
          } else if (!response.isActive) {
            this.notActiveModel = true;
            this.notActiveInfo = res[0].json.data;
          } else if (response.isActive && response.userType === "Union") {
            localStorage.setItem("userInfo", JSON.stringify(response.data));
            localStorage.setItem(
              "token",
              JSON.stringify(response.token.access_token)
            );
            this.router.navigate(["/admin-approval"]);
          } else if (
            response.isActive &&
            !response.isPaymentComplete &&
            response.userType !== "Fan"
          ) {
            this.userInfoPayment = response.data;
            this.getPrice(response.userType);
          }
          
          // else if (
          //   response.isActive &&
          //   response.isPaymentComplete &&
          //   response.userType === "Fan"
          // ) {
          //   this.router.navigateByUrl("fan-detail/" + response.data._id);
          // } 
          
          else if (response.isPaymentComplete) {
            localStorage.setItem("userInfo", JSON.stringify(response.data));
            localStorage.setItem(
              "token",
              JSON.stringify(response.token.access_token)
            );
            switch (response.userType) {
              case "Coach": {
                this.router.navigate(["/id-card/coach"]);
                break;
              }
              case "Player": {
                this.router.navigate(["/id-card/archer"]);
                break;
              }
              case "Judge": {
                this.router.navigate(["/id-card/judges"]);
                break;
              }
              case "Team Officials": {
                this.router.navigate(["/id-card/officials"]);
                break;
              }
              case "Fan": {
                this.router.navigate(["/id-card/fan"]);
                break;
              }
              default: {
                break;
              }
            }
          }
        },
        (err) => {
          if (err) {
            this.logSpinner = false;
            this.errorData = true;
          }
        }
      );
    }
  }

  getPrice(user) {
    const url = this.global.basePath + "common/getprice?type=" + user;
    this.global.GetRequestUnauthorised(url).subscribe((res) => {
      const data = res[0].json.data;
      this.priceData = res[0].json.data;
      this.noPaymentModel = true;
    });
  }

  getPriceFan(user) {
    const url = this.global.basePath + "common/getprice?type=" + user;
    this.global.GetRequestUnauthorised(url).subscribe((res) => {
      const data = res[0].json.data;
      this.priceData = res[0].json.data;
      this.noPaymentModelFan = true;
    });
  }

  showDialog() {
    this.display = true;
  }

  sendOtp() {
    const data = {
      email: this.resetEmail,
    };
    const url = this.global.basePath + "user/forgetpassword";
    this.global.PostRequestUnauthorised(url, data).subscribe(
      (res) => {
        this.otpSend = true;
        this.resetUserType = res[0].json.userType;
      },
      (err) => {}
    );
  }

  verifyOtp() {
    const check = JSON.stringify(this.otp);
    if (check.length === 4) {
      const data = {
        email: this.resetEmail,
        otp: this.otp,
        accType: this.resetUserType,
      };
      const url = this.global.basePath + "user/otpverify";
      this.global.PostRequestUnauthorised(url, data).subscribe(
        (res) => {
          localStorage.setItem(
            "token",
            JSON.stringify(res[0].json.token.access_token)
          );
          this.otpSend = false;
          this.changePass = true;
        },
        (err) => {
          this.otpError = true;
        }
      );
    }
  }
  closeVerify() {
    this.display = false;
  }
  closeNotActive() {
    this.notActiveModel = false;
  }
  closeRejected() {
    this.rejectedModel = false;
  }

  changePassword() {
    const data = {
      password: this.changedPass,
    };
    const url = this.global.basePath + "user/changepassword";
    this.global.PostRequest(url, data).subscribe(
      (res) => {
        this.otpSend = false;
        this.changePass = false;
        this.mobileNumber = null;
        // this.forgetDone=true;
        this.display = false;
        this.otp = null;
        this.passwordSuccessModel=true;
      },
      (err) => {
        this.otpSend = false;
        this.changePass = false;
        this.resetEmail = null;
        this.otp = null;
        this.display = false;
      }
    );
  }
  showResponse(event) {
    console.log("LoginComponent -> showResponse -> event", event);
  }

  getOrderId() {
    // const userData= JSON.parse(localStorage.getItem('user'))
    const url =
      this.global.basePath + "common/getid?amount=" + this.priceData.price + `&id=${this.logData.data.id}&accountType=${this.logData.userType}` ;
    this.global.GetRequestUnauthorised(url).subscribe((res) => {
      const data = res[0].json.orderId;
      this.orderId = data;
      if (this.orderId) {
        this.MakePayment();
      } else {
        this.getOrderId();
      }
    });
  }

  MakePayment() {
    this.noPaymentModel = false;
    this.noPaymentModelFan = false;
    this.options = {
      key: "rzp_live_ppvHgybCkYFxfa",
      // key: "rzp_test_NKz19Nzupa5S8o",

      amount: this.priceData.price,
      currency: "INR",
      name: "Archery Association of India",
      order_id:this.orderId,
      description:
        "Please make the payment to register for Archery association of India",
      image: "",
      handler: (response) => this.callResponse(response),
      prefill: {
        name: this.logData.data.name,
        email: this.logData.data.email,
        contact: this.logData.data.phone,
      },
    };

    const rzp1 = new Razorpay(this.options);
    rzp1.open();
  }

  callResponse(response) {
    this.paymentVerifyLoader = true;
    this.paymentId = response.razorpay_payment_id;
    this.zone.run(() => {
      this.paymentVerifyLoader = true;
    });
    this.paymentVerifyApi();
  }

  paymentVerifyApi() {
    const data = {
      id: this.paymentId,
      email: this.logData.data.email,
      type: this.logData.userType,
    };
    const url = this.global.basePath + "common/getpaymentinfo";
    this.global.PostRequestUnauthorised(url, data).subscribe((res) => {
      this.zone.run(() => {
        this.paymentVerifyLoader = false;
        this.paymentSuccessModel = true;
      });
    });
  }

  closeSuccessModel() {
    this.paymentSuccessModel = false;
  }
  closePassSuccessModel() {
    this.passwordSuccessModel = false;
  }

  toHome(){
    this.router.navigateByUrl("/");
    window.scroll(0,0);
  }
}
