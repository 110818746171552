<div class="Light-nav">
    <div class="logo-wrapper">
        <img src="assets/icons/Logo.JPG" alt="" />
    </div>
    <div class="Light-nav-content">
        <div class="Light-support-wrap">
            <h2 class="Light-nav-heading">Archery association of India</h2>
            <img class="Light-head-support" src="assets/icons/target.svg" alt="" />
        </div>
        <p class="Light-nav-text" style="margin-top: 20px;">
            Archery Association of India came into existence in 1973, with the primary
            objective to organize, encourage, and promote the game of Archery in the
            country by providing proper training facilities to Indian Archers.
        </p>
    </div>
</div>

<router-outlet> </router-outlet>