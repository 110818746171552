<div class="Light-nav">
    <div class="logo-wrapper">
        <img (click)="toHome()" style="cursor: pointer;" src="assets/icons/Logo.JPG" alt=''>
    </div>
    <div class="Light-nav-content">
        <div class="Light-support-wrap">
            <h2 class="Light-nav-heading">Archery association of India</h2>
            <img class="Light-head-support" src="assets/icons/target.svg" alt=''>
        </div>
        <p class="Light-nav-text" style="margin-top: 20px;">Archery Association of India came into existence in 1973,
            with the primary objective to organize, encourage, and promote the game of Archery in the country by
            providing proper training facilities to Indian Archers.
        </p>
    </div>

</div>

<div class="role-wrapper">
    <div class="back-wrap">
        <img class="back-Arrow-Register" (click)="toLogin()" src="assets/icons/backArrow.svg" />
        <h2 class="select-role">Select role to register</h2>
    </div>

    <div class="RoleCardsWrapper">

        <!-- Greetings from the Archery Association of India ! The registration/affiliation on the AAI portal will be closed till April 2, 2024.Due to financial year closing. -->
        <div class="ui-g">
            <div class="ui-g-4">
                <div (click)="selectedRole('archer-register')" class="animatedCard cardTheme1">
                    <h2 class="animatedCard-heading">Archer</h2>
                    <img class="card-logo" src="assets/icons/archerne.svg" alt=''>

                </div>
            </div>
            <div class="ui-g-4">
                <div (click)="selectedRole('coach-register')" class="animatedCard cardTheme2">
                    <h2 class="animatedCard-heading">Coach</h2>
                    <img class="card-logo" src="assets/icons/coach.svg" alt=''>
                </div>
            </div>
            <div class="ui-g-4">
                <div (click)="selectedRole('team-official-register')" class="animatedCard cardTheme3">
                    <h2 class="animatedCard-heading">Team Official</h2>
                    <img class="card-logo" src="assets/icons/meeting.svg" alt=''>
                </div>
            </div>
        </div>
        <div class="ui-g">
            <div class="ui-g-4">
                <div (click)="selectedRole('judges-register')" class="animatedCard cardTheme4">
                    <h2 class="animatedCard-heading">Technical Official</h2>
                    <img class="card-logo" src="assets/icons/judge.svg" alt=''>
                </div>
            </div>
            <div class="ui-g-4">
                <div (click)="selectedRole('fans-register')" class="animatedCard cardTheme5">
                    <h2 class="animatedCard-heading">Archery Fan</h2>
                    <img class="card-logo" src="assets/icons/cheer.svg" alt=''>
                </div>
            </div>
            <div class="ui-g-4">
            </div>
        </div>
    </div>

</div>